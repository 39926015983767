.calendar{
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  &-info{
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 56px;
    border: 1px solid $blight;
    border-top: none;
    p{
      position: relative;
      padding-left: 24px;
      &:before{
        content: '';
        width: 8px;
        height: 8px;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -4px;
        border-radius: 50%;
      }
    }
    &__bkd:before{
      background: #00C987;
    }
    &__wps:before{
      background: #FCC220;
    }
  }
  &-wp{
    flex: 0 0 31.91%;
  }
  &-list{
    flex: 0 0 57.44%;
    position: relative;
    & > div > h3{
      margin-bottom: 24px;
    }
    &__bkg{
      margin-bottom: 40px;
    }
  }
  &-links{
    position: absolute;
    top: 0;
    right: 0;
    a.secondary,
    a.primary{
      width: 104px;
      height: 32px;
      font-size: 12px;
      line-height: 16px;
    }
  }
  .events-wrapper{
    display: none !important;
  }
  .__vev_calendar-wrapper{

  }
  .cal-wrapper{
    border: 1px solid $blight;
  }
  .cal-header{
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    height: 56px;
    border-bottom: 1px solid $blight;
    .title{
      padding-top: 18px;
      color: $black;
      font-weight: 600;
    }
    .l, .r{
      flex: 0 0 56px;
      position: relative;
    }
    .l{
      border-right: 1px solid $blight;
    }
    .r{
      border-left: 1px solid $blight;
    }
    .icon{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: url(../images/arrow-down.svg) 50% 50% no-repeat;
      cursor: pointer;
    }
    .arrow-left{
      transform: rotate(90deg);
    }
    .arrow-right{
      transform: rotate(-90deg);
    }
  }
  .weeks,
  .dates{
    display: flex;
    align-items: center;
    //justify-content: space-between;
    justify-content: space-around;
    flex-wrap: wrap;
    height: 64px;
    .item{
      flex: 0 0 14.28%;
      position: relative;
      //flex: 0 0 34px;
      text-align: center;
      font-size: 12px;
      line-height: 16px;
      color: $silver;
    }
  }
  .dates{
    height: auto;
    .item{
      height: 34px;
      color: $black;
      transition: all 0.3s;
      cursor: default;
      p{
        height: 100%;
        color: inherit !important;
        font-weight: 600;
        line-height: 2.2;
      }
      &.event{
        cursor: pointer;
        &:hover{
          background: $light;
          color: $white !important;
          &.booked-event:before,
          &.workshops-event:after{
            background: #fff;
          }
        }
      }
      &.selected-day{
        background: $light;
        color: $white !important;
        &.booked-event:before,
        &.workshops-event:after{
          background: #fff;
        }
      }
      &.booked-event:before,
      &.workshops-event:after{
        content: '';
        width: 4px;
        height: 4px;
        position: absolute;
        bottom: 1px;
        left: 50%;
        margin-left: -2px;
        border-radius: 50%;
        background: #00C987;
      }
      &.workshops-event:after{
        background: #FCC220;
      }
      &.booked-event.workshops-event:after{
        margin-left: 2px;
      }
      &.booked-event.workshops-event:before{
        margin-left: -4px;
      }
      &.booked-event,
      &.workshops-event{
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 1024px){
  .calendar{
    flex-direction: column-reverse;
    align-items: center;
    flex-wrap: wrap;
    &-list{
      width: 100%;
      max-width: 744px;
      flex-basis: 100%;
    }
    &-wp{
      flex-basis: 100%;
      position: static !important;
      margin-bottom: 24px;
      .theiaStickySidebar{
        position: static !important;
        transform: none !important;
        width: auto !important;
      }
    }
    &-links{
      width: 100%;
      display: flex;
      align-items: center;
      position: static;
      margin-bottom: 40px;
      text-align: center;
      a.secondary,
      a.primary{
        width: 50%;
      }
    }
  }
}

.single-bs-wizard-dot {
  position: absolute;
  width: 25px;
  height: 25px;
  display: block;
  background: $orange;
  border-radius: 50%;
}

.single-bs-wizard-dot:after {
  content: ' ';
  width: 13px;
  height: 13px;
  background: white;
  border-radius: 50px;
  position: absolute;
  top: 6px;
  left: 6px;
}

.event-detail-title {
  margin-left: 34px;
  padding-top: 3px;
}

.event-detail-hr {
  margin-top: 15px;
  margin-bottom: 25px;
}

.text-underline {
  text-decoration: underline;
}

.fc {
  &-day-past, &-day-other {
    .fc-daygrid-day-top {
      opacity: 1 !important;
      .fc-daygrid-day-number {
        color: $light-periwinkle !important;
      }
    }
  }

  &-day-future {
    .fc-daygrid-day-number {
      color: $cloud-burst !important;
    }
  }

  &-h-event {
    background-color: $orange !important;
    border: $orange 1px solid !important;
    margin: 0 0.5rem 0 0.5rem !important;
    padding: 0.5rem;
  }

  &-highlight {
    background-color: initial !important;
    border: $orange 1px solid !important;
  }

  &-header-toolbar {
    margin-bottom: -8px !important;
  }

  &-button-primary {
    background: transparent !important;
    border: none !important;
    color: $cloud-burst !important;

    &:disabled {
      color: $cloud-burst !important;
      opacity: 1 !important;
      border-bottom: 2.5px solid $orange !important;
      border-radius: 0 !important;
    }
  }

  &-icon-chevron-left, &-icon-chevron-right {
    color: $light-periwinkle !important;
  }

  &-button-active {
    border-bottom: 2.5px solid $orange !important;
    border-radius: 0 !important;
  }

  &-button-primary:focus {
    box-shadow: none !important;
  }

  &-daygrid-more-link {
    margin-top: 10px;
  }
}

th, td {
  .fc-daygrid-day {
    border: 1px solid $link-water !important;
  }
}

.fc-col-header-cell {
  border: none !important;
  background-color: $link-water;
  padding: 1rem 0 1rem 0 !important;

  &-cushion {
    color: $bali-hai;
  }
}

.fc-toolbar-chunk {
  .fc-toolbar-title {
    display: initial !important;
    color: $cloud-burst !important;
    font-size: 20px !important;
    font-weight: 600 !important;
  }
  .fc-button {
    margin-bottom: 10px !important;
  }
}

.show-text-event {
  color: $cloud-burst !important;
}

.google-maps-link {
  margin-left: 20px;
  background: url(../images/location-pin.svg) 0 50% no-repeat;
  padding-left: 20px;
  color: $orange !important;
}

@media screen and (max-width:480px) {
  .event-title {
    max-width: 5ch;
  }

  .google-maps-link {
    margin-left: 0;
    color: $orange !important;
    background: url(../images/location-pin.svg) 0 50% no-repeat;
    padding-left: 20px;
  }

  .show-text-event {
    z-index: 5;
    padding: 4px;
    color: white !important;
  }

  .fc-daygrid-more-link {
    margin-top: 0;
    background-color: $salmon;
  }

  a.fc-daygrid-more-link {
    color: $salmon;
    line-height: 13px;
  }

  .fc-toolbar-chunk {
    .fc-toolbar-title {
      font-size: 0.9rem !important;
    }
  }

  .fc {
    &-daygrid-body-balanced {
      .fc-daygrid-day-events {
        top: 0 !important;
      }
    }
    .fc-button {
      padding: 4px !important;
      font-size: 0.75rem !important;
    }
  }
}

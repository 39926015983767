.progress {
  height: 11px;
  border-radius: 5px;
  background-color: #EDEEF3;
  margin: 5px 0;

  .progress-bar {
    background-color: #FF8141;
    border-radius: 5px;
  }
}

.profile-artist-menu {
.progress-circle {
  width: 22px;
  height: 22px;
  line-height: 22px;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
  &:after {
    content: "";
    width: 15px;
    height: 16px;
    border-radius: 50%;
    border: 3px solid #EDEEF3;
    position: absolute;
    top: 0;
    left: 0;
  }
  > span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }
  .progress-left {
    left: 0;
  }
  .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 3px;
    border-style: solid;
    position: absolute;
    top: 0;
    border-color: #FF8141;
  }
  .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: calc(22px / 2);
    border-bottom-right-radius: calc(22px / 2);
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
    //animation: loading-2 1.5s linear forwards 1.8s;
  }
  .progress-right {
    right: 0;
    .progress-bar {
      left: -100%;
      border-top-left-radius: calc(25px / 2);;
      border-bottom-left-radius: calc(25px / 2);;
      border-right: 0;
      -webkit-transform-origin: center right;
      transform-origin: center right;
      //animation: loading-1 1.8s linear forwards;
    }
  }
  .progress-value {
    display: flex;
    border-radius: 50%;
    font-size: 1rem;
    text-align: center;
    line-height: 20px;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-weight: 300;
    div {
      //margin-top: 10px;
    }
    span {
      font-size: 12px;
      text-transform: uppercase;
    }
  }
}
}

@for $i from 1 through 100 {
	$stepName: ($i*calc(100 / 100));

	//animation only the left side if below 50%
	@if $i <= calc(100 / 2) {
		.progress-circle[data-percentage="#{$stepName}"] {
			.progress-right .progress-bar {
				 animation: loading-#{$i} .0s linear forwards;
			}
			.progress-left .progress-bar {animation: 0;}
		}
	}
	//animation only the right side if above 50%
	@if $i > calc(100 / 2)  {
		.progress-circle[data-percentage="#{$stepName}"] {
			.progress-right .progress-bar {
				animation: loading-#{calc(100 / 2)} .0s linear forwards; //set the animation to longest animation
			}
			.progress-left .progress-bar {
      animation: loading-#{$i - calc(100 / 2)} .0s linear forwards .0s;
    }
		}
	}
}

//animation
@for $i from 1 through calc(100 / 2) {
	$degrees: calc(180 / calc(100 / 2));
	$degrees: ($degrees*$i);
	@keyframes loading-#{$i}{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate($degrees);
			transform: rotate(#{$degrees}deg);
    }
	}
}

.ai {
	margin-top: 50px;
	box-shadow: 0 2px 7px 3px #d7dae4;
	border-radius: 20px;

	.input-container {
		position: relative;
		display: flex;
		align-items: center;
	}
	.availability-mobile {
		display: none;
	}
	.availability-web {
		display: flex;
		width: max-content;
	}
	.artist-availability {
		.message-availability {
			display: flex;
			width: 340px;
			align-self: end;
			margin-left: auto;
			.flatpickr-calendar {
				right: 0;
			}
		}
		.next-btn{
			display: none;
		}
		.next_btn {
			display: block;
			position: relative;
			bottom: -338px;
			right: 128px;
			padding: 0px 137px;
		}
	}
	
	.send-icon {
		position: absolute;
		right: 10px;
		cursor: pointer;
		fill: #E58F66; /* Matches the color */
		transition: transform 0.2s ease;
	}
	
	.send-icon:hover {
		transform: scale(1.1);
	}
	
 	&-chat {
		padding: 40px;
		.img-w {
			width: 20px;
		}

		&__input {
			margin: 0;
			display: block;
			position: relative;
			bottom: 60px;
			width: -webkit-fill-available;
			margin: 0px 25px 0px 50px;
			box-shadow: 0 2px 7px 3px #d7dae4;
			padding: 8px 8px 8px 40px;
			font-size: 16px;
			border-radius: 5px;
			line-height: 24px;
			outline: none;
			background: white;
			.inpt {
				width: 97%;
				outline: none;
			}
		}

		.chats {
			margin-top: 40px;
			padding: 30px 40px 50px 20px;
			height: 460px;
			border-radius: 10px;
			overflow: scroll;
			box-shadow: 0px 0px 5px 0px #d7dae4;
			.chat {
				padding-left: 20px;
			}

			.user { 
			  background: rgba(255, 243, 236, 0.5);
				margin-bottom: 10px;
				&-chat {
					padding: 15px;
					width: 100%;
				}
				&-img {
					margin-top: auto;
					margin-bottom: auto;
				}
			}
		}
		.chats::-webkit-scrollbar {
			width: 4px;
		}

		.chats::-webkit-scrollbar-thumb {
			border: 3px solid #ff8141;
			height: 10px;
		}
	}

	&-avatar-container {
		.pro-member-badge-mobile {
			display: none;
		}
	}

	&-item {
		border-radius: 5px;
		box-shadow: 0 2px 7px 3px #d7dae4;
		background-color: #fff;
		margin-top: 15px;
		margin-left: 50px;
		margin-right: 50px;

		.btns{ 
			font-size: 12px;
		 }

    &:first-child {
      margin-top: 15px;
    }

    &:last-child {
			margin-bottom: 15px;
    }

		.pro-member-badge {
			display: block;
			width: 70px;
    }

    &__avatar {
			width: 200px;
			height: 200px;		
      padding: unset;
      margin-right: 12px;
      border-radius: 8px;
      box-shadow: 0 3px 3px hsl(0deg 0% 58% / 10%);
      webkit-box-shadow: 0 3px 3px hsl(0deg 0% 58% / 10%);
    }

    &__info {
      flex-grow: 1;
      padding-top: 8px;
    }

    &__name {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      font-size: 20px;
      line-height: 25px;
      overflow: hidden;
      margin-bottom: 16px;
    }

    &__rating {
      margin-top: 7px;

      .star {
        height: 20px;
        padding-top: 3px;
      }
    }

    &__specialities {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      flex-wrap: wrap;
      font-size: 14px;
      color: $silver;

      li {
        padding-right: 4px;
      }

      li::after {
        content: ",";
      }

      :last-child::after {
        content: "";
      }
    }

    &__price {
      align-self: flex-end;
      padding-bottom: 20px;
      padding-right: 20px;
			width: 255px;

      &_info {
        border-radius: 5px;
				p {
					font-size: 14px;
				}
        h2 {
          font-size: 20px;
          line-height: 32px;
        }
      }

      &_info_mobile {
        display: none;
      }

      p {
        color: $silver;
      }

      h3 {
        font-weight: 600;
      }
    }

    &__more {
      color: white;
      font-size: 15px;
      background: #ff8141;
      text-align: center;
      border-radius: 5px;
      width: 162px;
      padding: 10px 17px 10px 17px;
    }
  }

	&-item__price {
		padding-bottom: 20px;
		padding-right: 20px;

		&_info {
			text-align: start;
			border-radius: 5px;

			h2 {
				font-size: 24px;
				line-height: 32px;
			}
		}

		&_info_mobile {
			display: none;
		}

		p {
			color: #707070;
		}

		h3 {
			font-weight: 600;
		}
	}
}

@media screen and (max-width: 640px) {
	.ai {
		margin-top: 30px;
		padding: 30px;

		&-item__price {
			align-self: center;
		}

		.artist-availability {
			.next-btn{
				display: none;
			}
			.next_btn {
				bottom: -265px;
				right: 0;
				z-index: 100;
			}
		}

		&-chat {
			padding: 10px;
			&__input {
				.inpt {
					width: 88%;
				}
			}
			.chats {
				margin-top: 20px;
				padding: 10px 10px 10px 10px;
				.chat {
					padding-left: unset;
				}
			}
		}

		&-item {
			display: flex;
			height: 175px;
			justify-content: left;
			margin-left: 0;
			margin-left: unset;
			margin-right: unset;

			&:first-child {
				height: auto;
			}

      &__avatar {
        margin-top: auto;
        margin-bottom: auto;
        width: 168px;
        height: 168px;
      }

			.availability-mobile {
				display: flex;
				flex-direction: column;
			}
			.availability-web {
				display: none;
			}

      &__price {
        display: none;

        &_info_mobile {
          display: block;

          p {
            font-size: 12px;
          }

          h2 {
            font-size: 16px;
            font-weight: 600;
            color: #ff8141;
            margin-bottom: 5px;
          }
        }
      }

      &__desc {
        line-height: 18px;
        max-width: none;
        font-size: 12px;
        margin-bottom: 8px;
      }

      &__rating {
        margin-top: 5px;

        .link {
          font-size: 12px;
        }

        .star {
          height: 12px;
          padding-top: 0px;

          img {
            height: inherit;
          }
        }
      }

      &__info {
        padding-top: 0px;
        margin-top: -5px;
      }

      &__name {
        margin-bottom: 5px;
        font-size: 20px;
        margin-top: 15px;
      }

      &__specialities {
        margin: 0;
        font-size: 10px;
      }
    }
	}
}

@media screen and (max-width: 450px) { 
	.ai {
		margin-top: 10px;
		padding: 10px;
		&-chat {
			padding: 5px;
			.chats {
				margin-top: 10px;
			}
		}

		&-avatar-container {
			position: relative;
			width: 100%;
			padding: unset;
			.pro-member-badge-mobile {
				display: block;
        width: 80px;
				position: absolute;
        height: 35px;
        bottom: -5px;
        right: -2px;
      }
		}

		&-item {
			padding: 0px 10px 0px 10px;
      &__avatar {
        flex: 0 0 150px;
        width: 150px;
        height: 150px;
      }

			.pro-member-badge {
				display: none;
			}

      &__price {
        text-align: left;

        h3 {
          color: #ff8141;
          font-size: 16px;
          display: inline-block;
          margin-bottom: 3px;
        }

        p {
          font-size: 16px;
          display: inline-block;
          padding-right: 4px;
        }
      }

      &__desc {
        max-width: none;
        margin-bottom: 0px;
        font-size: 12px;
        color: #9ba5bc;
      }

      &__rating {
        .link {
          font-size: 12px;
        }
      }

      &__info {
        padding-top: 0px;
      }

      &__name {
        margin-bottom: 0;
        font-size: 20px;
      }

      &__specialities {
        margin: 3px 0 3px 0;
      }
    }
	}
}
.box {
  border-radius: 5px;
  box-shadow: 0 5px 15px 0 rgba(147, 147, 147, 0.15);
  background-color: #ffffff;
  width: 100%;

  .box-header {
    display: flex;
    align-items: center;
    margin-bottom: 1em;

    .box-title {
      font-size: 20px;
      font-weight: 600;
      color: #333;
    }
  }
}

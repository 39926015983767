.container--event_bookings {
  max-width: 1300px;
  margin-top: 20px;
}

.bookings-page {
  padding: 0px 0px 0 10px;

  &-applied-artists-tabs {
    display: flex;
    overflow-x: scroll;

    .applied-artist-tab {
      cursor: pointer;
      color: $primary;
      font-size: 20px;
      padding: 10px;
      min-width: 170px;
      text-align: center;
      margin-right: 12px;
      border-bottom: 3px solid rgb(255, 240, 233);

      img {
        width: 25px;
        height: 25px;
      }

      &__active {
        border-bottom: 3px solid rgb(255, 129, 65);

        img {
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  .search-icon-mobile {
    display: none;
  }

  .float-right {
    display: flex;
    justify-content: end;
    padding: 0;
  }

  &__tabs {
    border-bottom: 1px solid #dbe0eb;
    padding-bottom: 35px;

    &__item {
      color: $primary;
      font-size: 16px;
      box-shadow: rgb( 215 218 228) 0px 4px 3px 1px;
      border-radius: 5px;
      padding: 10px;
      width: 190px;
      text-align: center;
      margin-right: 7px;
      transition: all ease 0.3s;

      &:hover {
        border-bottom: 4px solid $primary;
      }

      &--active {
        background: $primary;;
        color: white;
      }
    }
    .search-icon {
      position: relative;
      left: 12%;
      top: 6px;
    }
    .search-card {
      float: right;
      border-radius: 5px;
      padding: 10px;
      margin-left: 9%;
      font-size: 16px;
      box-shadow: rgb( 215 218 228) 0px 4px 3px 1px;
      border: none;
      margin-right: auto;
      width: 230px;
      background: url('../images/search-gray.svg') 8px 50% no-repeat;
    }
  }

  &__title {
    font-size: 20px;
    color: #131313;
    font-weight: 700;
  }

  &__description {
    white-space: pre-line;
    line-height: 1.5;
    font-size: 16px;
    color: #000;
    font-weight: 400;
  }

  .older-bookings {
    border-radius: 5px;
    background-color: #fff3ec;

    .collapsible-older-bookings {
      padding-left: 10px;
      background-color: #fff3ec;
      .booking {
        border-radius: unset;
        box-shadow: unset;
        background-color: #fff3ec;
        border-top: 1px solid #dbe0eb;
        padding-top: 15px;
        padding-bottom: 25px;
      }
    }
  }

  .booking {
    border-radius: 10px;
    box-shadow: rgb(215, 218, 228) 0px 2px 7px 3px;
    background-color: #ffffff;

    &-info {
      display: flex;
      flex-direction: column;

      &__header {
        display: flex;

        h3.title {
          font-weight: 700;
          font-size: 20px;
        }

        .status {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      &-mobile__details {
        display: none;
      }

      &__details {
        .booking-avatars {
          display: flex;
          flex-wrap: wrap;
        }
        .booking-applications-status {
          background: #ff8141;
          &-text{
            color: white;
            font-size: 20px;
          }
        }
        font-size: 14px;

        &__label {
          color: #131313;
          font-size: 12px;
          opacity: 0.7;
          margin-bottom: 5px;
        }

        &__value {
          color: #131313;
          font-size: 16px;
          font-weight: 600;
          text-overflow: ellipsis;
          max-height: 42px;
          overflow: hidden;
          .application-image {
            width: 67px;
            height: 67px;
            border-radius: 5%;
          }
        }
        &__booking_name {
          color: #131313;
          font-weight: 700;
          font-size: 20px;
        }
        .btns {
          display: flex;
          justify-content: end;
          img {
            width: 15px;
            margin-right: 5px;
          }
          button {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 6px 0;
            font-size: 12px;
            height: 27px;
            width: 120px;
            background-color: #fff;
            border: 1px solid #555;
            color: #252c3f;
            border-radius: 5px;
            text-align: center;
            text-decoration: none;
            margin: 4px 2px;
            cursor: pointer;

          }
          .parent {
            position: relative;
            top: 0;
            left: 0;
            .image1 {
              position: relative;
              width: 15px;
              top: 0;
              left: 0;
            }
            .image2 {
              position: absolute;
              width: 10px;
              top: 3px;
              left: 2.5px;

            }
          }
        }
      }
    }

    &-action {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $primary;
    }
    .payout {
      position: relative;
      top: 135px;
      width: fit-content;
      z-index: 10;
      margin-left: auto;
      border-top-left-radius: 5px;
      background: #252c3f;

      .booking-info__details__value {
        color: white;
        font-size: 12px;
      }
    }
    .button {
      &-pending-quatation {
        position: absolute;
        z-index: 10;
        top: 10px;
        left: 10px;
        background: #252c3f;
        color: white;
        font-size: 12px;
        border-radius: 5px;
        padding: 8px;
        height: 35px;
        width: 130px;
        text-align: center;
        transition: all ease 0.3s;
        border: none;
      }
      &-quoted-quatation {
        position: absolute;
        z-index: 10;
        top: 10px;
        left: 10px;
        background: #5096ff;
        color: white;
        font-size: 12px;
        border-radius: 5px;
        height: 35px;
        width: 190px;
        text-align: center;
        transition: all ease 0.3s;
        border: none;
      }
      &-paid-quatation {
        position: absolute;
        z-index: 10;
        top: 10px;
        left: 10px;
        background: #44b15e;
        color: white;
        font-size: 12px;
        border-radius: 5px;
        height: 35px;
        width: 130px;
        text-align: center;
        transition: all ease 0.3s;
        border: none;
      }
      &-approved-quatation{
        position: absolute;
        z-index: 10;
        background: #ff8141;
        color: white;
        font-size: 12px;
        border-radius: 5px;
        height: 35px;
        width: 130px;
        text-align: center;
        transition: all ease 0.3s;
        border: none;
      }
      &-booking-closed {
        position: absolute;
        z-index: 10;
        top: 10px;
        left: 10px;
        background: #c44c49;
        color: white;
        font-size: 12px;
        border-radius: 5px;
        height: 35px;
        width: 130px;
        text-align: center;
        transition: all ease 0.3s;
        border: none;
      }
    }
    .side-border {
      height: 120px;
      width: 0;
      border-right: 1px solid #dbe0eb;
      margin-right: 10px;
    }
    .bottom-border {
      border-bottom: 1px solid #dbe0eb;
      margin: auto;
    }
  }
  .collapsible-older-bookings {
    overflow:hidden;
    transition: max-height 0.5s ease;
    height: auto;
    max-height: 0;
    .arrow-down-orange {
      transition: transform 0.5s;
    }
  }
  .arrow-down-orange {
    transition: transform 0.5s;
  }
  .booking-detail-active {
    transition: max-height 0.8s ease;
    height: auto;
    max-height: 1800px;
  }

  .badge {
    color: #fff;
    background: $primary;
    font-size: 14px;
    line-height: 14px;
    padding: 2px 10px;
    border-radius: 5px;

    &--orange {
      background: $primary;
    }
    &--blue {
      background: #48cbd8;
    }
    &--green {
      background: #3dcea0;
    }
    &--red {
      background: #ef5252;
    }
    &--success {
      background: #3dcea0;
    }
  }
}

// Booking details page
.booking-page {
  .status-bar {
    color: $white;
    text-align: center;
    padding: 5px 0;
    font-size: 16px;
  }

  .booking-section {
    border-radius: 5px;
    box-shadow: 0 10px 30px 0 rgba(147, 147, 147, 0.1);
    background-color: #ffffff;

    .section-title {
      color: #131313;
      font-size: 20px;
      font-weight: 700;
    }
  }

  .info-section {
    &__label {
      font-size: 16px;
      color: #131313;
      font-weight: 700;
    }

    &__value {
      font-size: 16px;
      color: #000;
    }
  }

  .date-section {

  }

  .action-section {
    &__title {
      color: #141821;
      font-size: 20px;
      font-weight: 700;
    }

    &__description {
      font-size: 16px;
      color: #141821;
    }

    &__tos {
      font-size: 16px;
      color: #818181;

      a {
        color: #ff8141;
        text-decoration: underline;
      }
    }
  }
}

.booking-application-info {
  color: #ff8141;
  font-size: 15px;
  line-height: 67px;
  position: absolute;
  margin-left: 20px;
}

@media screen and (max-width: 1450px){
  .bookings-page {
    &__tabs {
      .search-icon {
        position: relative;
        left: 11.5%;
        top: 6px;
      }
      &__item {
        font-size: 14px;
        width: 170px;

      }

      .search-card {
        margin-left: 8%;
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .bookings-page {
    .booking {
      .divider {
        display: none;
      }
      &-info {
        &__details {
          display: none;
          &__booking_name {
            font-size: 17px;
          }
        }
        &-mobile__details {
          .booking-detail-cb-map {
            position: unset !important;
          }
          display: block;
          .VueCarousel {
            position: relative;

            .VueCarousel-slide {
              height: 160px;
              width: 100%;
            }

            .VueCarousel-pagination {
              position: relative;
              height: 0px;
              bottom: 60px;
            }

            .VueCarousel-dot {
              padding: 2px !important;
            }
          }
        }
      }
    }
    .bookings-page {
      .search-icon-mobile {
        display: block;
        float: right;
        margin-right: 10px;
      }
      &__description {
        font-size: 12px;
        color: #000;
        font-weight: 400;
      }
      padding: 0 5px;
      .booking-info__details__label {
        font-size: 11px;
      }
      &__tabs{
        border-bottom: 1px solid #dbe0eb;
        display: flex;
        justify-content: space-around;
        &__item {
          font-size: 10px;
          width: 105px;
          padding: 5px 0;
        }
        .search-card {
          display: none;
        }
        .search-icon {
          display: none;
        }
      }
    }
  }
  .booking-application-info {
    margin-left: 2px;
  }
}

@media screen and (max-width: 640px) {
  .bookings-page {
    &-applied-artists-tabs {
      display: flex;
      overflow-x: scroll;

      .applied-artist-tab {
        background: #FFFFFF;
        color: #222222;
        font-size: 14px;
        box-shadow: 3px 2px 10px 0px rgba(0, 0, 0, 0.16);
        border-radius: 13px;
        padding: 8px 12px;
        text-align: center;
        margin-right: 12px;
        min-width: auto;

        img {
          display: none;
        }

        &__active {
          background: #FF8141;
          color: #FFFFFF;
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .booking-application-info {
    margin-left: 20px;
  }
}

@media screen and (max-width: 360px){
  .container--event_bookings {
    .bookings-page {
      &__tabs{
        &__item {
          font-size: 8px;
        }
      }
    }
  }
}

.search {
  display: flex;
  margin: 0 16px 0 0;
  width: 264px;
  position: relative;
  &__close-search{
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 8px;
  }
  &__input{
    margin: 0;
    display: block;
    border: 1px solid #DADFEA;
    width: 100%;
    padding: 8px 8px 8px 40px;
    font-size: 16px;
    line-height: 24px;
    outline: none;
    background: url(../images/search-gray.svg) 8px 50% no-repeat;
    &::placeholder{
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #9BA4BC;
    }
  }
}

@media screen and (max-width:850px) {
  .search{
    display: none;
  }
}

.modal-vue-wrapper .modal-vue-panel {
  background: none !important;
  opacity: 0;
}

.modal-vue-panel.modal-fade {
  box-shadow: none !important;
}

.modal-vue--content {
  padding: 0 !important;
  .sm-gallery__item {
    height: auto;
    max-height: 620px;
    object-fit: contain;
    margin-right: 0 !important;
    margin-bottom: 0;
    z-index: -1;
  }
}

.modal-vue--content-panel {
  padding: 0 !important;
}

.modal-vue--wrapper-show,
.modal-vue--wrapper-show.modal-fade,
.modal-vue--wrapper-show.modal-scale {
  background: rgba(4, 3, 28, 0.7);
  opacity: 1 !important;
}

.modal-vue--overlay {
  background: none !important;
}

.modal-vue--content-panel {
  overflow: hidden;
}

.modal-vue-overlay {
  background-color: rgba(0, 0, 0, 0.85) !important;
  -webkit-backdrop-filter: blur(20px);
}

.modal-vue--actions {
  display: none;
}

.modal-vue---close-icon {
  display: none;
}

.feedback{
  width: 100%;
  max-width: 552px;
  margin-left: auto;
  margin-right: auto;
  .rating + h3{
    margin-top: 16px;
  }
  h3{
    margin: 0 0 16px;
  }
  &-body{
    padding: 16px;
    margin: 24px 0;
    background-color: #FFF3EC;
    &__user{
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      & > img{
        width: 48px;
        flex: 0 0 48px;
        height: 48px;
        margin-right: 16px;
      }
    }
    &__btn.primary{
      width: 215px;
    }
  }
}
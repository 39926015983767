.billing-portal {
  .input-date {
    margin: 0;
    display: block;
    border: 1px solid #dadfea;
    width: 100%;
    padding: 8px 10px 8px 35px;
    font-size: 16px;
    line-height: 24px;
    outline: none;
    background: url('../images/calendar-original.svg') 8px 50% no-repeat;
    background-size: auto 15px;
    border-radius: 4px;
    color: $silver;
    font-family: "Nunito Sans";
    font-weight: 600;

    &::placeholder {
      color: $silver;
      font-family: "Nunito Sans";
      font-weight: 600;
    }
  }

  .table {
    text-align: left;

    th {
      white-space: nowrap;
      width: 1%;
      color: $cod-gray;
      border-bottom: 1px solid #EDEDED;
      padding: 12px 0;
    }

    td {
      color: $cod-gray;
      border-bottom: 1px solid #EDEDED;
      padding: 12px 0;
    }
  }

  .badge {
    width: 80%;
    padding: 5px;
    object-fit: contain;
    border-radius: 6px;
    color: $white;

    &-success {
      background-color: $shamrock;
    }

    &-pending {
      background-color: $light-salmon;
    }

    &-cancelled {
      background-color: $light-salmon;
    }
  }

  .number {
    color: $orange;
  }

  .subscription-billing {
    width: 45px;
    height: 30px;
    background-image: url(../images/subscription.svg);
    background-position: center;
    background-size: 30px 22px;
    background-repeat: no-repeat;
    margin-right: 0px;
    padding-top: -12px;
    margin-bottom: -10px;
  }

  .download-billing {
    width: 36px;
    height: 24px;
    background-image: url(../images/download-billing.svg);
    background-position: center;
    background-size: 22px 22px;
    background-repeat: no-repeat;
    margin-right: 10px;
  }

  .preview-billing {
    width: 42px;
    height: 30px;
    background-image: url(../images/info-gray.svg);
    background-position: center;
    background-size: 22px 22px;
    background-repeat: no-repeat;
  }

  .search-billing {
    position: absolute;
    top: 10px;
    width: 42px;
    height: 22px;
    background-image: url(../images/search-gray.svg);
    background-position: center;
    background-size: 22px 22px;
    background-repeat: no-repeat;
  }

  .status-billing {
    position: absolute;
    top: 10px;
    width: 32px;
    height: 22px;
    background-position: center;
    background-repeat: no-repeat;

    &-dot {
      background-image: url(../images/dot-status.svg);
      background-size: 8px 22px;
    }

    &-background {
      background-image: url(../images/background-dot-status.svg);
      background-size: 16px 22px;
    }
  }

  .search-transactions {
    padding: 8px 40px;
  }

  .select-status {
    padding-left: 32px;
    color: $silver;
    font-weight: 600;
    font-family: "Nunito Sans";
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .arrow-billing {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 1rem;
    height: 22px;
    background-image: url(../images/arrow.svg);
    background-position: center;
    background-size: 10px 22px;
    background-repeat: no-repeat;
  }

  .arrow-left-billing {
    position: absolute;
    width: 42px;
    top: 5.3rem;
    height: 25px;
    background-image: url(../images/arrow-left-billing.svg);
    background-position: center;
    background-size: 17px 17px;
    background-repeat: no-repeat;
  }

  .dashboard-menu__link {
    color: $orange;
  }

  .title-portal {
    margin-left: 50px;
  }

  .padding-show-portal {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
}

@media screen and (max-width: 480px) {
  .billing-portal {
    .table {
      width: 650px;
    }

    .overflow-x {
      overflow-x: auto !important;
    }

    .padding-show-portal {
      padding-right: 0.25rem !important;
      padding-left: 0.65rem !important;
    }

    .arrow-left-billing {
      top: 3.9rem;
    }
  }
}

.flatpickr-monthSelect-month.selected {
  background-color: $salmon !important;
}

.work-break-all {
  word-break: break-all;
}

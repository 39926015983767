html,
body {
  height: 100%;
}

body {
  overflow-x: hidden !important;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  font-family: "Nunito Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  color: $gray;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  // &::selection {
  //   background: #F9DB02;
  // }
}

img {
  max-width: 100%;
  object-fit: cover;
}

span,
img,
a,
button {
  display: inline-block;
}

a {
  text-decoration: none;
  // &::selection {
  //   background: #F9DB02;
  // }
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

.main-container {
  overflow-x: hidden;
  padding-top: 60px;
}

.vue-map-container {
  width: 100% !important;
}

.align-self-fe {
  align-self: flex-end;
}

.date-list li .message.selected .bg {
  background: $primary !important;
}

.date-list li .message:not(.selected):hover .bg {
  background: $light !important;
}
.year-list li.selected,
.month-list li.selected {
  background: $primary !important;
}

@media screen and (max-width: 1024px) {
  .main-container {
    padding-top: 55px;
  }
}

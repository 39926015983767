.dashboard {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: -24px;

  &-sidebar {
    // max-width: 280px;
    // padding-top: 25px;
    // padding-right: 30px;
    // border-right: 2px solid #ebf2f7;
    h3 {
      margin-bottom: 8px;
    }

    .btn {
      width: auto;
      min-width: 110px;
      padding-left: 0px;
      padding-right: 0px;
      border-radius: 5px;
      font-size: 14px;
    }

    &-client {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: auto;
      height: 116px;

      .btn {
        margin-top: 10px;
        vertical-align: bottom;
      }
    }
  }
  &-mobile-btn {
    display: none;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    border-bottom: 1px solid $blight;
    cursor: pointer;

    img {
      transition: all 0.2s ease;

      &.active {
        transform: rotate(180deg);
      }
    }
  }
  &-user {
    display: flex;
    align-items: center;

    &__avatar {
      width: 80px;
      flex: 0 0 116px;
      height: 80px;
      margin-right: 10px;
      border-radius: 5px;
      align-self: flex-end;
    }
    &__extra-avatar {
      display: flex;
      align-items: center;
      background: #fff3ec;
      border-radius: 5px;
      font-size: 18px;
      color: #ff8141;
      width: 80px;
      height: 80px;
      text-align: center;
    }
    &__single-avatar {
      border-radius: 10px 0px 0px 10px;
      width: inherit;
      height: auto;
    }
    &-single__avatar {
      width: 200px;
      height: 200px;
      flex: 0 0 116px;
      margin-right: 10px;
      border-radius: 5px;
      align-self: flex-end;
    }
    p.cr-primary {
      font-size: 12px;
      margin: 4px 0 8px;
    }
  }
  &-status {
    background-color: #fff0e9;
    padding: 10px;
    p {
      font-size: 14px;
    }
  }
  &-trigger {
    margin-bottom: 24px;
    &.active & {
      &__top {
        background: $primary;
        border-color: $primary;
        p {
          color: $white;
        }
      }
      &__complete {
        display: block;
      }
      &__btn {
        &:after {
          left: 27px;
        }
      }
    }
    &__top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px;
      background: $weak;
      border: 1px solid $weak;
      p {
        color: $primary;
      }
    }
    &__btn {
      width: 48px;
      height: 24px;
      position: relative;
      background: $white;
      cursor: pointer;
      &:after {
        content: "";
        width: 18px;
        height: 18px;
        position: absolute;
        top: 3px;
        left: 3px;
        background: $dark;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.18);
        transition: all 0.3s;
      }
    }
    &__complete {
      padding: 16px 0;
      border: 1px solid $blight;
      border-top: none;
      transition: all 0.3s;
      &.pt0 {
        padding-top: 0;
      }
      h3 {
        padding: 0 16px;
        margin: 8px 0 16px;
        font-size: 20px;
        line-height: 32px;
        color: $primary;
      }
      p {
        padding: 0 16px;
      }
    }
    &__link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 16px 0;
      border-top: 1px solid $blight;
      color: $gray;
      font-weight: normal;
      border-radius: 3px;

      &.router-link-active {
        font-weight: 600;
        color: $primary;
      }
      img {
        transform: rotate(-90deg);
      }
    }
  }
  &-menu {
    // padding: 16px 0;
    // border-top: 1px solid $blight;

    .router-link-active {
      background-color: #fff0e9;
      p {
        color: #ff8141;
      }
    }

    &__title {
      padding-bottom: 8px;
      padding-left: 15px;
    }
    &__item + &__item {
      margin-top: 24px;
    }
    &__item {
      &-booking {
        position: relative;

        &:before {
          content: "";
          width: 16px;
          height: 16px;
          position: absolute;
          top: 12px;
          left: 22px;
          transform: translateX(-50%) translateY(0%);
          background: url(../images/booking.svg) 50% 50% no-repeat;
          background-size: cover;
          z-index: 1;
          cursor: pointer;
        }
      }

      &-event {
        position: relative;

        &:before {
          content: "";
          width: 16px;
          height: 16px;
          position: absolute;
          top: 12px;
          left: 22px;
          transform: translateX(-50%) translateY(0%);
          background: url(../images/event.svg) 50% 50% no-repeat;
          background-size: cover;
          z-index: 1;
          cursor: pointer;
        }
      }

      &-calendar {
        position: relative;

        &:before {
          content: "";
          width: 16px;
          height: 16px;
          position: absolute;
          top: 12px;
          left: 22px;
          transform: translateX(-50%) translateY(0%);
          background: url(../images/calendar.svg) 50% 50% no-repeat;
          background-size: cover;
          z-index: 1;
          cursor: pointer;
        }
      }

      &-review {
        position: relative;

        &:before {
          content: "";
          width: 17px;
          height: 17px;
          position: absolute;
          top: 12px;
          left: 22px;
          transform: translateX(-50%) translateY(0%);
          background: url(../images/rating-star.svg) 50% 50% no-repeat;
          background-size: cover;
          z-index: 1;
          cursor: pointer;
        }
      }

      &-message {
        position: relative;

        &:before {
          content: "";
          width: 16px;
          height: 14px;
          position: absolute;
          top: 14px;
          left: 22px;
          transform: translateX(-50%) translateY(0%);
          background: url(../images/message.svg) 50% 50% no-repeat;
          background-size: cover;
          z-index: 1;
          cursor: pointer;
        }
      }

      &-analytics {
        position: relative;

        &:before {
          content: "";
          width: 16px;
          height: 12px;
          position: absolute;
          top: 14px;
          left: 22px;
          transform: translateX(-50%) translateY(0%);
          background: url(../images/analytics.svg) 50% 50% no-repeat;
          background-size: cover;
          z-index: 1;
          cursor: pointer;
        }
      }

      &-upgrade {
        position: relative;

        &:before {
          content: "";
          width: 16px;
          height: 12px;
          position: absolute;
          top: 14px;
          left: 22px;
          transform: translateX(-50%) translateY(0%);
          background: url(../images/logo.svg) 50% 50% no-repeat;
          background-size: cover;
          z-index: 1;
          cursor: pointer;
        }
      }
      
      &-classes {
        position: relative;
        &:before {
          content: "";
          width: 16px;
          height: 17px;
          position: absolute;
          top: 14px;
          left: 22px;
          transform: translateX(-50%) translateY(0%);
          background: url(./../images/graduation-cap-gray.svg) 50% 50% no-repeat;
          background-size: cover;
          z-index: 1;
          cursor: pointer;
        }
      }

      &-help {
        position: relative;

        &:before {
          content: "";
          width: 16px;
          height: 16px;
          position: absolute;
          top: 12px;
          left: 22px;
          transform: translateX(-50%) translateY(0%);
          background: url(../images/question-mark.svg) 50% 50% no-repeat;
          background-size: cover;
          z-index: 1;
          cursor: pointer;
        }
      }

      &-setting {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        img {
          transition: all 0.2s ease;

          &.active {
            transform: rotate(180deg);
          }
        }
      }

      &-security {
        position: relative;

        &:before {
          content: "";
          width: 13px;
          height: 15px;
          position: absolute;
          top: 12px;
          left: 26px;
          transform: translateX(-50%) translateY(0%);
          background: url(../images/security.svg) 50% 50% no-repeat;
          background-size: cover;
          z-index: 1;
          cursor: pointer;
        }
      }

      &-credit-card {
        position: relative;

        &:before {
          content: "";
          width: 16px;
          height: 12px;
          position: absolute;
          top: 15px;
          left: 26px;
          transform: translateX(-50%) translateY(0%);
          background: url(../images/credit-card.svg) 50% 50% no-repeat;
          background-size: cover;
          z-index: 1;
          cursor: pointer;
        }
      }


      &-lock {
        position: relative;
        margin-left: 20px;

        &:before {
          content: "";
          width: 13px;
          height: 17px;
          position: absolute;
          top: 12px;
          left: 26px;
          transform: translateX(-50%) translateY(0%);
          background: url(../images/lock.svg) 50% 50% no-repeat;
          background-size: cover;
          z-index: 1;
          cursor: pointer;
        }
      }
      &-download {
        position: relative;

        &:before {
          content: "";
          width: 14px;
          height: 14px;
          position: absolute;
          top: 14px;
          left: 22px;
          transform: translateX(-50%) translateY(0%);
          background: url(../images/download-app.svg) 50% 50% no-repeat;
          background-size: cover;
          z-index: 1;
          cursor: pointer;
        }
        &-image {
          padding-left: 15px;
          padding-top: 0;
        }
      }

      &-gigs {
        position: relative;

        &:before {
          content: "";
          width: 14px;
          height: 17px;
          position: absolute;
          top: 12px;
          left: 22px;
          transform: translateX(-50%) translateY(0%);
          background: url(../images/gig.svg) 50% 50% no-repeat;
          background-size: cover;
          z-index: 1;
          cursor: pointer;
        }
      }

      &-download-image {
        a {
          &:last-child {
            img {
              margin-left: 20px;
            }
          }
        }
      }

      img {
        max-width: 105px;
      }
    }
    &__link {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 11px 15px;
      padding-left: 40px;
      font-weight: normal;
      color: $gray;
      &.router-link-active {
        font-weight: 600;
        color: $primary;
      }

      &-notify {
        display: block;
        width: 12px;
        height: 12px;
        margin: auto 8px;
        flex-shrink: 0;
        background: $primary;
        border-radius: 50%;
      }
    }
  }
  &-main {
    flex: 0 0 65.86%;
    padding-top: 25px;
  }
  &-liked {
    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: all 0.3s;
      &:not(:last-child) {
        margin-bottom: 24px;
      }
      &:hover {
        background: rgba(255, 246, 241, 0.6);
        .dashboard-liked__like {
          opacity: 1;
        }
      }
    }
    &__link {
      display: flex;
      align-items: center;
    }
    &__avatar {
      width: 72px;
      flex: 0 0 72px;
      height: 72px;
      margin-right: 24px;
    }
    &__extra-avatar {
      width: 72px;
      height: 72px;
    }
    &__counters {
      display: flex;
      align-items: center;
      p.strong {
        margin: 0 8px;
        color: $black;
      }
    }
    &__stars {
      width: 66px;
      height: 16px;
      background: url('../images/stars.png') 50% 45% no-repeat;
    }
    &__like {
      cursor: pointer;
      margin-right: 26px;
      transition: all 0.3s;
      opacity: 0;
    }
  }
  &-workshops {
    &__item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }
    &__img {
      width: 35.48%;
      flex: 0 0 35.48%;
      height: 190px;
    }
    &__info {
      flex-grow: 1;
      margin: 0 24px;
      h3 {
        margin-bottom: 24px;
      }
    }
    &__state {
      text-align: right;
    }
  }
  &-bookings {
    &__item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 48px;
      }
    }
    &__img {
      width: 168px;
      flex: 0 0 168px;
      height: 168px;
    }
    &__info {
      flex-grow: 1;
      margin: 0 24px;
      h3 {
        margin-bottom: 24px;
      }
    }
    &__list {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      max-width: 364px;
      &-item {
        flex: 0 0 46.7%;
        &:nth-child(odd) {
          margin-right: 6.59%;
        }
      }
    }
    &__state {
      text-align: right;
    }
  }

  .upload-avatar {
    font-size: 14px;
    height: 50px;
    width: 190px;
    margin-left: 10px;
    vertical-align: bottom;
    margin-top: auto;
    margin-bottom: 18px;
    box-shadow: 0px 3px 6px #0000001a;
    border-radius: 5px;
  }
}

.modalIframe {
  width: 100%;
  height: 450px;
  background: #000;
  &-preview {
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    &:before {
      content: "";
      width: 68px;
      height: 68px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(0%);
      background: url(../images/play.svg) 50% 50% no-repeat;
      background-size: cover;
      z-index: 1;
      cursor: pointer;
    }
  }
}

.sm-gallery {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  &-desctop & {
    &__item {
      width: 100%;
      margin-bottom: 0;
    }
    &__item-wp {
      width: 22.58%;
      flex: 0 0 22.58%;
      position: relative;
      margin-bottom: 16px;
      &:not(:nth-child(4n + 4)) {
        margin-right: 3.22%;
      }
    }
  }
  &__avatar-image-size {
    width: 100%;
    height: 175px;
    position: relative;
    margin-bottom: 16px;
    cursor: pointer;
  }

  &__avatar-item {
    width: 100%;
    height: 160px;
    position: relative;
    margin-bottom: 16px;
    cursor: pointer;
    border-radius: 2px;
  }
  &__avatar-item-top {
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;;
    -webkit-box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;;
  }
  &__item {
    width: 100%;
    height: 116px;
    position: relative;
    margin-bottom: 16px;
    cursor: pointer;
    &-remove {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 0;
      right: 0;
      background: $error url(../images/close-white.svg) 50% 50% no-repeat;
      cursor: pointer;
      z-index: 1;
      opacity: 0.75;
      transition: opacity 0.3s;
      &:hover {
        opacity: 1;
      }
    }
    &-video {
      position: relative;
      &:before {
        content: "";
        width: 34px;
        height: 34px;
        position: absolute;
        top: 41px;
        left: 50%;
        transform: translateX(-50%);
        background: url(../images/play.svg) 50% 50% no-repeat;
        z-index: 1;
        cursor: pointer;
      }
    }
  }
}
@media screen and (max-width: 1600px) {
  .dashboard-user__avatar {
    width: 63px;
    height: 67px;
    margin-right: unset;
  }
  .dashboard-user__extra-avatar {
    width: 63px;
    height: 67px;
  }
}

@media screen and (max-width: 1024px) {
  .dashboard {
    display: block;
    margin-top: -40px;
    &-mobile-btn {
      display: flex;
      padding-top: 12px;
    }
    &-user {
      margin-top: 24px;
    }
    &-menu {
      display: none;
      width: 100%;
      height: 100%;
      padding-bottom: 138px;
      padding-left: 0;
      padding-right: 0;
      position: fixed;
      top: 138px;
      left: 0;
      overflow: hidden;
      background: #fff;
      border: none;
      z-index: 10;
      &__content {
        width: 93.54%;
        height: 100%;
        margin: 0 auto;
        padding-bottom: 16px;
        overflow: hidden;
        overflow-y: auto;
      }

      &__title {
        display: none;
      }

      &__mobile {
        display: block;
      }
    }
    &-sidebar {
      margin-bottom: 40px;
    }
    &-liked {
      &__like {
        opacity: 1;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .vue-affix {
    width: 100% !important;
  }
  .dashboard {
    &-user {
      &__avatar {
        width: 70px;
        flex: 0 0 70px;
        height: 70px;
      }
      &__extra-avatar {
        width: 70px;
        height: 70px;
      }
      &__single-avatar {
        border-radius: 10px 10px 0 0;
      }
    }
    &-sidebar {
      display: block;
      max-width: 100%;
      border: none;
      padding-right: 0;

      .dashboard-user img {
        align-self: flex-end;
      }
    }
    &-menu {
      top: 100px;
      &__content {
        width: 84.6%;
      }
    }
    &-liked {
      &__item {
        display: block;
        position: relative;
      }
      &__link {
        width: 100%;
        flex-wrap: wrap;
      }
      &__avatar {
        flex: 0 0 100%;
        height: auto;
        margin-right: 0;
        margin-bottom: 16px;
      }
      &__like {
        position: absolute;
        bottom: 10px;
        right: 18px;
        margin-right: 0;
      }
    }
    &-workshops {
      &__item {
        display: block;
      }
      &__img {
        width: 100%;
        height: auto;
      }
      &__info {
        margin: 16px 0 24px;
        h3 {
          margin-bottom: 24px;
        }
      }
      &__state {
        text-align: left;
      }
    }
    &-bookings {
      &__item {
        display: block;
      }
      &__img {
        width: 100%;
        height: auto;
      }
      &__info {
        margin: 16px 0 24px;
      }
      &__state {
        text-align: left;
      }
    }
    .upload-avatar {
      right: -152px !important;
      width: 155px;
      font-size: 14px !important;
    }
  }
}

@media only screen and (max-width: 1120px) and (min-width: 640px) {
  .dashboard {
    &-sidebar {
      .btn {
        min-width: 100px;
        padding-left: 0;
        padding-right: 0;
        border-radius: 5px;
        font-size: 13px;
      }
    }
  }
}

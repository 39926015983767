.analytics-page {
  &__rise-up {
    background: url('../images/rise-up.svg') 0% 30% no-repeat;
    background-size: 10px;
    font-size: 14px;
    color: #00cd98;
    padding-left: 15px;
  }

  &__rise-down {
    background: url('../images/rise-down.svg') 0% 30% no-repeat;
    background-size: 10px;
    font-size: 14px;
    color: #f64000;
    padding-left: 15px;
  }

  &-modal {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(217, 217, 217, 0.4);
    backdrop-filter: blur(5px);

    &__pro {
      text-align: center;
      max-width: 410px;

      &-content {
        border: 2px solid $primary;
        border-radius: 5px;
        box-shadow: 0 10px 30px 0 rgba(147, 147, 147, 0.2);
      }
    }

    &__description {
      font-size: 14px;
      font-weight: 700;
    }

    .modal-header {
      border-bottom: 1px solid #ccc;
    }

    .modal-dialog {
      border-radius: 5px;
      display: flex;
      margin-top: 10%;
      margin-left: auto;
      margin-right: auto;
      width: fit-content;
      max-width: 500px;
      background-color: white;

      .modal-content {
        border-radius: 5px;
      }
    }

    img {
      max-width: 40px;
    }

    .btn-primary {
      color: $white;
      background: $primary;
      width: 100%;

      &:hover,
      &.hover{
        background: $light;
        box-shadow: 0 4px 10px 0 rgba(254,149,84,0.5);
      }
      &:active,
      &.active{
        background: $dark;
        box-shadow: 0 4px 10px 0 rgba(254,149,84,0.5);
      }
      &:disabled,
      &.disabled{
        color: $silver;
        background: $lighter;
        cursor: default;
        box-shadow: none;
      }
      &:focus,
      &.focus{
        outline: none;
        border-color: $light;
      }
    }
  }

  .analytics {
    &-item-top {
      padding: 15px 10px;
      padding-bottom: 25px;
      box-shadow: 0 3px 8px 0 rgba(155, 164, 188, 0.16);

      label {
        color: $shuttle-gray;
        font-size: 14px;
      }

      h3 {
        color: $pickled-bluewood;
      }

      .analytics-page__rise-up, .analytics-page__rise-down {
        font-weight: 700;
      }
    }

    &-item {
      padding: 15px 20px;
      padding-bottom: 25px;
      box-shadow: 0 3px 8px 0 rgba(155, 164, 188, 0.16);

      label {
        color: $silver;
        font-size: 14px;
      }

      h3 {
        margin-top: 15px;
      }

      li {
        padding-left: 20px;
        position: relative;

        &::before {
          content: "•";
          position: absolute;
          left: 0;
          top: 10px;
        }

        h5 {
          margin-bottom: 0;
        }

        p {
          font-size: 14px;
          color: #858997;
        }
      }

      &__monthly::before {
        color: #5351fb;
      }

      &__weekly::before {
        color: #00cd98;
      }

      &__daily::before {
        color: #f6b900;
      }
    }

    &-item-orange {
      background-color: $primary;
      padding: 15px 20px;
      color: white;
      padding-bottom: 25px;
      box-shadow: 0 3px 8px 0 rgba(155, 164, 188, 0.16);

      p {
        color: white;
      }

      label {
        color: $silver;
        font-size: 14px;
      }
    }

    &-speed {
      .outer {
        position: relative;
        .percent {
          position: absolute;
          bottom: 20px;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          width: fit-content;
          text-align: center;

          #speedChartTitle {
            font-weight: 500;
            font-size: 16px;
          }
        }
      }
    }
  }

  .trigger, .cohort-chart-cell {
    width: 100%;
    height: 100%;
    box-shadow: 0 2px 2px 0 rgb(155 164 188 / 16%);
  }

  .analytic-column {
    height: 3.5rem;
    background: #EEEEEE;
    border-bottom: 1px solid lightgray;
    cursor: pointer;
    font-size: 13px;
  }

  .analytic-row {
    width: 30.5%;
    padding-left: 0px;
    padding-right: 0;
    border-right: 1px solid lightgray;
    border-left: 1px solid lightgray;
  }

  .direct-margin {
    margin-top: 8rem;
    margin-bottom: 1.9rem;
  }

  .organic-search-margin {
    margin-top: 1.5rem;
  }

  .referral-margin {
    margin-top: 1.4rem;
  }

  .social-margin {
    margin-top: 2.1rem;
  }

  .title-analytic {
    background-color: #EEEEEE;
    font-weight: 600;
    padding-left: 12px;
    border-bottom: 1px solid lightgray;
  }

  .analytic-total {
    background: #EEEEEE;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 13px;

    &-current {
      background-color: white;
    }
  }

  .analytic-item {
    margin-top: 10px;
    margin-bottom: 26px;
    font-size: 11px;
  }

  .font-channel {
    font-size: 13px;
  }

  @media screen and (max-width: 1024px) {
    .overflow-x {
      overflow-x: auto !important;
    }

    .analytic-mobile-width {
      width: 50rem;
    }
  }

}

.cohort-tooltip {
  background-color: white;
  border-radius: 5px;
  text-align: left;
  padding: 10px 15px;
  box-shadow: 0 3px 8px 0 rgba(155, 164, 188, 0.5);
}

.modal{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  &__overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(32,37,41,0.5);
    z-index: 1;
  }
  &__content{
    width: 90%;
    max-width: 368px;
    position: relative;
    padding: 24px;
    background: #fff;
    box-shadow: 0 4px 24px 0 rgba(37,44,63,0.16);
    text-align: center;
    z-index: 2;
  }
  &__title{
    margin-bottom: 8px;
  }
  &__text{
    margin-bottom: 24px;
  }
  &__confirm-btn{
    display: flex;
    justify-content: space-between;
    &>a{
      width: 47.43% !important;
    }
  }
  &__alert-btn{
    &>a{
      width: 100% !important;
    }
  }
}

.sweet-modal.is-visible .sweet-buttons, 
.sweet-modal.is-visible .sweet-content{
  padding: 0;
}

.sweet-modal.is-alert .sweet-content{
  padding: 0;
}

.sweet-modal-overlay{
  background-color: rgba(4,3,28,0.7) !important;
}

.sweet-box-actions{
  display: none;
}

.sweet-modal{
  top: 200px !important;
}

.sweet-modal.is-visible{
  transform: translateX(-50%) !important;
}

.lg-thumb-outer.lg-grab,
.lg-toolbar.group > *{
  display: none !important;
}

.lg-toolbar.group .lg-close.lg-icon{
  display: block !important;
}

.lg-toolbar{
  background: none !important;
}

.lg-backdrop.in{
  background-color: rgba(4,3,28,0.7) !important;
}

.lg-sub-html{
  bottom: 0 !important;
}

@media screen and (max-width: 640px){
  .modal-container,
  .blur-bg {
    position: absolute;
    height: 100vh;
  }
  .modal-wrapper{
    height: 100vh;
  }
  .sweet-modal{
    top: 50px !important;
  }
  .sweet-modal.is-mobile-fullscreen{
    width: 96% !important;
    height: auto !important;
    left: 2% !important;
  }
  .sweet-modal.is-visible{
    transform: translateX(0) !important;
  }
}
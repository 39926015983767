.gallery{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  &-item{
    flex: 0 0 23.4%;
    margin-bottom: 48px;
    &:not(:nth-child(4n+4)){
      margin-right: 2.12%;
    }
  }
  &-image{
    width: 100%;
    height: 190px;
    cursor: pointer;
  }
  &-user-name{
    margin-top: 16px;
    margin-bottom: 8px;
    b{
      transition: all 0.3s;
      &:hover{
        color: $primary;
      }
    }
  }
}

@media screen and (max-width: 1024px){
  .gallery{
    &-item{
      flex: 0 0 48.27%;
      &:not(:nth-child(4n+4)){
        margin-right: 0;
      }
      &:nth-child(odd){
        margin-right: 2.12%;
      }
    }
  }
}

@media screen and (max-width: 640px){
  .gallery{
    &-item{
      flex: 0 0 100%;
      &:nth-child(odd){
        margin-right: 0;
      }
    }
  }
}
.new-booking {
  width: 700px;
  margin: auto;
  padding: 56px 73px;

  &__review-content {
    background: rgb(255, 243, 236);
    padding: 15px;
    border-radius: 7px;
    box-shadow: 1px 1px 8px 3px rgba(0, 0, 0, 0.15);

    .order-capture-map {
      .vue-map-container {
        width: 100%;
        height: 200px;

        .vue-map {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &__content {
    width: 570px;
    margin: auto;

    .order-capture-map {
      .vue-map-container {
        width: 100%;
        height: 200px;

        .vue-map {
          width: 100%;
          height: 100%;
        }
      }
    }

    .input-tel__input {
        border: none !important;
      }

    .country-selector {

      &__label {
        display: none;
      }

      &__input {
        border: none !important;
        padding-top: 0px !important;
      }

      &__country-flag {
        top: 15px !important;
      }
    }

    .order-capture-input {
      box-shadow:  1px 1px 8px 3px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
    }

    .flatpickr-wrapper {
      width: 100%;

      .flatpickr-calendar {
        width: 100%;

        &.static {
          top: calc(100% + -15px);
        }

        .flatpickr-innerContainer {
          display: block;
          border-bottom: none;

          .flatpickr-rContainer {
            display: block;

            .flatpickr-days {
              border: none;
              width: 100%;
              display: flex;

              .dayContainer {
                width: 100%;
                flex: 1;
                max-width: none;

                .flatpickr-day {
                  border-radius: 50%;
                  border: none;
                  max-width: none;

                  &.selected {
                    background: #ff8a65;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .complete-signup-header {
    margin-bottom: 30px;
    text-align: left;

    &__title {
      font-weight: 600;
    }
  }


  &__select-art-type {
    border: none;
    color: $primary;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;

    &:focus-visible {
      outline: none;
    }
  }

  .fc {
    color: black;
    border: none;
    box-shadow: 0px 10px 30px #9393931A;

    .fc {
      &-toolbar-chunk {
        &:first-child {
          width: 150px;
        }

        .fc-toolbar-title {
          font-size: 18px;
        }
      }

      &-toolbar-title {
        color: $primary;
      }

      &-scrollgrid {
        border: none;

        td {
          border: none;
        }
      }

      &-button-primary {
        background-color: $primary;
        border-color: $primary;

        &:disabled {
          background-color: $weak;
          border-color: $weak;
          color: $primary;
        }
        &:hover {
          background-color: $dark;
          border-color: $dark;
          color: white;
        }
      }

      &-daygrid-day-events {
        min-height: 0 !important;
        height: 0;
      }

      &-day {
        border: none;
        a {
          color: #04031c;
        }
      }

      &-highlight {
        background-color: $weak;
      }

      &-day-other {
        .fc-daygrid-day-top {
          opacity: 1 !important;
          .fc-daygrid-day-number {
            opacity: 0.3;
          }
        }
      }
    }
  }

  .vue__time-picker .dropdown ul li:not([disabled]).active,
  .vue__time-picker .dropdown ul li:not([disabled]).active:focus,
  .vue__time-picker .dropdown ul li:not([disabled]).active:hover {
    background-color: $primary;
  }
  .search-with-ai {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    height: unset;
    width: -webkit-fill-available;
    margin-bottom: 40px;
    border-radius: 0px 5px 0px 5px;
    background: #fff3ec;
    height: 50px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: -webkit-fill-available;
      width: -webkit-fill-available;
    }
    img {
      width: 30px;
    }
  }
  &__art-type {
    .multiselect {

      &__option {
        &--selected {
          background: #FFFFFF;
          color: inherit;
        }
      }

      &__tags {
        margin: 0;
        display: block;
        box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.31);
        border: none;
        width: 100%;
        font-size: 16px;
        line-height: 24px;
        outline: none;
      }

      &__content-wrapper {
        border: none;
        box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.31);
        overflow-y: scroll;

        &::-webkit-scrollbar {
          display: block;
          width: 2px;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          height: 10px;
          background-color: rgb(255 129 65);
        }

        &::-webkit-scrollbar-track-piece:end {
          background: transparent;
          margin-top: 10px;
        }

        &::-webkit-scrollbar-track-piece:start {
          background: transparent;
          margin-top: 10px;
        }
      }

      &__element {
        border-bottom: solid rgb(112, 112, 112, 0.31);
        border-bottom-width: thin;
        padding: 5px 0;
      }
    }

    .genres-container,
    .specialities-container,
    .durations-container,
    .expand-search-options-container,
    .sound-equipment-options-container {
      border-radius: 5px;
      box-shadow: 1px 5px 12px 3px rgba(0, 0, 0, 0.15);
      margin-bottom: 20px;
      max-height: 350px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: block;
        width: 2px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        height: 10px;
        background-color: rgb(255 129 65);
      }

      &::-webkit-scrollbar-track-piece:end {
        background: transparent;
        margin-top: 10px;
      }

      &::-webkit-scrollbar-track-piece:start {
        background: transparent;
        margin-top: 10px;
      }

      .expand-search-selected-item,
      .sound-equipment-selected-item{
        border-bottom: 1px solid rgba(112, 112, 112, 0.14);
        padding: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;
        background-color: rgb(255, 243, 236);
        color: rgb(255, 129, 65);
      }
      .genre-item,
      .speciality-item,
      .duration-item,
      .expand-search-item,
      .sound-equipment-item {
        border-bottom: 1px solid rgba(112, 112, 112, 0.14);
        padding: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
          opacity: 0.75;
        }

        .art-type-icon {
          height: 25px;
          width: 25px;
          margin-right: 10px;
        }
      }
    }
    
    .event_types-container,
    .art_types-container {
        .btn-group {
          display: ruby;
        }
        .btns {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 40px;
          border: solid;
          box-shadow: 1px 5px 12px 3px rgba(0, 0, 0, .15);
          height: 50px;
          padding: 0px 35px 0px 35px;
          background: none;
          font-family: "Nunito Sans";
          font-size: 18px;
          line-height: 24px;
          text-align: center;
          transition: all 0.3s;
          cursor: pointer;
          position: relative;
          flex: 1 1 auto;
          margin-right: 15px;
          margin-bottom: 15px;
        }
        .selected-event-type-item,
        .selected-art-type-item {
          color: white;
          background: #ff8141;
        }
        .event-type-item,
        .art-type-item {
          color: black;
          background: white;
        }
        .art-type-icon {
          height: 25px;
          width: 25px;
          margin-right: 10px;
        }
      }

    .selected-art_types-container,
    .selected-genres-container,
    .selected-specialities-container,
    .selected-dates-container {
      border-radius: 5px;
      box-shadow: 1px 5px 12px 3px rgba(0, 0, 0, 0.15);
      margin-bottom: 20px;
      overflow-y: scroll;
      max-height: 150px;

      &::-webkit-scrollbar {
        display: block;
        width: 2px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        height: 10px;
        background-color: rgb(255 129 65);
      }

      &::-webkit-scrollbar-track-piece:end {
        background: transparent;
        margin-top: 10px;
      }

      &::-webkit-scrollbar-track-piece:start {
        background: transparent;
        margin-top: 10px;
      }

      .selected-genre-item,
      .selected-speciality-item,
      .selected-date-item {
        border-bottom: 1px solid rgba(112, 112, 112, 0.14);
        padding: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;
        background-color: rgb(255, 243, 236);
        color: rgb(255, 129, 65);

        &:hover {
          opacity: 0.75;
        }

        .art-type-icon {
          height: 25px;
          width: 25px;
          margin-right: 10px;
        }
      }
    }
  }

  &__search-location {
    margin: 0;
    display: block;
    border: 1px solid #dadfea;
    width: 100%;
    padding-left: 40px !important;
    font-size: 16px;
    line-height: 24px;
    outline: none;
    background: url('../images/search-gray.svg') 8px 50% no-repeat;
  }

  &__skip-budget {
    background-color: rgb(37, 44, 63);
    color: white;
    border: none;
    padding: 15px;
    margin: 0;
    font-family: "Nunito Sans";
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    transition: all 0.3s;
    cursor: pointer;
    border-radius: 5px;
    text-align: left;
  }

  &__input-budget {
    display: flex;
    margin-bottom: 30px;

    .img-flg {
      height: 40px;
      padding: 10px;
    }

    input {
      padding: 8px 12px;
      outline: none;
    }
  }

  &__apply-to-all {
    font-size: 12px;
    line-height: 16px;
    color: $primary;
    border: none;
    background: none;
    cursor: pointer;

    &:disabled {
      color: #9BA4BC;
    }
  }

  .vue__time-picker input.display-time {
    width: 100%;
    height: 42px;
    display: inline-block;
    padding: 8px 12px;
    font-size: 16px;
    line-height: 24px;
    transition: all 0.3s;
    border-radius: 4px;

    &:focus-visible {
      border: 1px solid #d2d2d2;
    }
  }

  &__add-date-btn, &__minus-date-btn {
    background-color: rgb(255, 129, 65);
    width: 37px;
    height: 37px;
    padding: 0;
    align-items: center;
  }

  &__add-date-btn img {
    width: 25px;
  }

  &__minus-date-btn img {
    width: 20px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance:textfield;
  }
}
.btn-shadow {
  box-shadow: 0 2px 6px rgba(0,0,0,.15);
}

drop-down-options {
  width: 100%;
  display: none;
}
.br {
  border-radius: 10px;
}
.booking-artist-detail {
  h3 {
    font-size: 21px;
  }

  &__alert {
    border-radius: 2px;
    background-color: #FFE6D9;

    &-image {
      border-radius: 2px;
      background-color: $primary;

      img {
        transform: translate(-54%, -50%);
        margin: 0;
        position: relative;
        top: 45%;
        left: 50%;
      }
    }
  }

  &__final-payout {
    p {
      height: 45px;
      border: #00b7c8;
      margin: 0;
      padding-top: 10px;
      background: #00b7c8;
      font-size: 18px;
      text-align: center;
      border-radius: 5px;
      width: calc(100% - 60px);
      color: white;

      b {
        color: white;
      }
    }

    button {
      height: 45px;
      width: 45px;
      margin-left: 15px;
      border: none;
      padding: 0;
      cursor: pointer;
    }
  }

  &__flag-img {
    position: absolute;
    left: 10px;
    width: 25px;
    display: block;
    height: 25px
  }

  &__accept-btn {
    height: 45px;
    border: $primary;
    padding: 0 16px;
    margin: 0;
    background: $primary;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    transition: all 0.3s;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
    color: white;

    &:disabled {
      border: #efefef;
      background: #efefef;
      color: #9ba4bc;
      cursor: no-drop;
    }
  }

  &__decline-btn {
    height: 45px;
    line-height: 45px !important;
    border: #efefef;
    padding: 0 16px;
    margin: 0;
    background: #efefef;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    transition: all 0.3s;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
    color: #9ba4bc;
  }

  &__book-con{
    height: 52px;
    width: 100%;
    background-color: #44B15E;
  }

  &__btn-small {
    height: 52px;
    line-height: 45px !important;
    border: #efefef;
    padding: 0 16px;
    margin: 0;
    background: #FFF3EC;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    transition: all 0.3s;
    cursor: pointer;
    border-radius: 5px;
    width: 45px;
    color: #9ba4bc;
    text-align: left;
    font-weight: bold;
  }

  &__btn-extra-small {
    height: 35px;
    line-height: 41px !important;
    border: #efefef;
    margin: 0;
    background: #FFF3EC;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    transition: all 0.3s;
    cursor: pointer;
    border-radius: 5px;
    width: 35px;
    color: #9ba4bc;
    text-align: left;
    font-weight: bold;
  }

  &__detail-btn {
    height: auto;
    width: 100%;
    background: #FFF3EC;
    border: #9ba4bc;
    box-shadow: 0px 3px 6px #0000001A;
  }

  &__btn-ban {
    display: flex;
    align-items: center;
    height: 53px;
    border: #efefef;
    padding: 0 16px;
    margin: 0;
    background: #FFF3EC;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    transition: all 0.3s;
    border-radius: 5px;
    width: 100%;
    color: #9ba4bc;
    text-align: left;
    font-weight: bold;
  }
  &-web {
    display: block;
  }
  &-mob {
    display: none;
  }

  &__profile-pic-ico {
    height: 40px;
    width: 40px;
  }

  .table {
    text-align: left;

    th:not(:first-child) {
      white-space: nowrap;
      width: 1%;
      padding-right: 10px;
    }

    td {
      color: #878787;
      border-bottom: 1px solid #EDEDED;
      padding: 8px 0;

      &:not(:first-child) {
        white-space: nowrap;
        width: 1%;
        padding-right: 10px;
      }

      img {
        width: 16px;
      }
    }
  }

  hr {
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);
  }

  .wish_to_quote {
    color: #FF8141;
    font-size: 18px;
  }

  .download_our_app {
    font-size: 32px;
  }

  .download-model {
    padding: 16px;
    border-radius: 10px;
    background-color: #FFF3EC;
    text-align: center;
  }

  &__tos-note {
    font-size: 14px;
    color: #818181;
  }

  &__description {
    line-height: 1.5;
    text-align: justify;
    white-space: break-spaces;
  }
  &__quote-sent {
    background-color: #5096FF;
    color: white;
    font-size: 15px;
    border-radius: 4px;
  }
  &__quote-shortlisted {
    background-color: #FF8141;
    color: white;
    font-size: 15px;
    border-radius: 4px;
  }
  &__booking-confirm {
    background-color: #00c889;
    color: white;
    font-size: 15px;
    border-radius: 4px;
  }
  &__booking-declined {
    display: none;
  }
  &__booking-closed {
    background-color: #E34445;
    color: white;
    font-size: 15px;
    border-radius: 4px;
  }
  &__selected-artist {
    background-color: #00b7c8;
    border-radius: 4px;
    color: white;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;

    span {
      font-weight: 700;
    }
  }
}

.booking-client-detail {
  &__artist-info {
    padding: 15px;
    flex-grow: 1;

    .pro-badge {
      height: 35px;
    }

    .pro-badge-mobile {
      display: none;
    }

    .artist-quoted-price {
      font-size: 17px;
    }

    .artist-quoted-price-mobile {
      display: none;
    }

    .artist-note {
      max-height: 80px;
      overflow: scroll;
      margin-top: 1rem;
    }
  }

  &__artist-specialities {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 13px;
  }

  &__artist-country {
    font-weight: 600;
    color: #9ba4bc;
  }

  &__artist-block {
    min-height: 180px;
    border-radius: 8px;

    .application-actions {
      display: flex;
    }

    .artist-note-mobile {
      display: none;
    }

    .application-actions-mobile {
      display: none;
    }
  }
}

.bb {  // border bottom
  padding-bottom: 10px;
  border-bottom: 1px solid #dbe0eb;
}

.review-artist {
  display: flex;
  align-items: center;
  padding: 0px 5px 0px 10px;
  background: #fff3ec;
  border-radius: 5px;
  box-shadow: rgba(147, 147, 147, 0.2901960784) 0px 5px 15px 0px;
  img {
    margin-top: 8px;
  }
}

.review-system {
  display: inline-block;
  margin-top: 30px;
  .rating-star-box {
    border-width: 2px;
    padding: 12px 17px 12px 17px;
    width: 110px;
    margin-left: 20px;
    border-style: solid;
    align-items: center;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border-color: rgb(255 129 65);;
    .rating-star {
      width: 40px;
    }
  }
  .text-area {
    width: 100%;
    border-color: rgb(218 223 234);
    border-style: solid;
    padding: 20px 0px 0px 15px;
    border-radius: 5px;
  }
}
.fw {
  font-weight: 500;
}
.toggle-review-system {
  display: none;
}

.border-bottom {
  border-bottom: 1px solid #dbe0eb;
  padding-bottom: 40px;

}
.bb:last-child {
  padding-bottom: 50px;
  border-bottom: 0.5px solid #707
}
.mt-ten {
  margin-top: 10px;
}
.side-details-scroll {
  overflow-x: auto;
  min-height: calc(100vh - 500px);
}
.side-details-scroll::-webkit-scrollbar {
  width: 4px;
}
.side-details-scroll::-webkit-scrollbar-thumb {
  border: 3px solid #ff8141;
  height: 200px;
}
.artists {
  &-avatar {
    margin-right: 12px;
    width: 45px;
    border-radius: 3px;
  }
  &-detail-arrow-down {
    transition: transform 0.5s;
  }
  &-detail-background {
    overflow:hidden;
    transition: max-height 0.5s ease;
    height: auto;
    max-height: 0;
    h5 {
      margin-bottom: 4px;
    }
    p {
      font-size: 10px;
    }
    .arrow-down {
      transition: transform 0.5s;
    }
  }
  &-detail-background-color {
    box-shadow: 0.15rem 0.25rem 0.5rem rgba(0, 0, 0, 0.28);
    padding: 3px;
    margin-bottom: 20px;
    border-radius: 6px;
  }
  &-detail-active {
    transition: max-height 0.8s ease;
    height: auto;
    max-height: 350px;
  }
  &-detail-flag {
    width: 20px
  }
}
.show-booking-summary {
  display: block;
}
.booking-detail {

  &__payment-section {
    .show_btns_web {
      display: inline-block;
    }
    .show_btns_mob {
      display: none;
    }
    border-radius: 4px;
    box-shadow: 0 2px 7px 3px #d7dae4;
    padding: 1rem;

    .payment-plans {

      &-options {
        display: flex;
        margin-top: 1.5rem;

        .field-selecttype__label {
          max-width: 259px;
          min-height: 100%;
          padding: 15px;
          position: relative;
          border: 3px solid transparent;
          box-shadow: 0px 10px 30px rgba(147, 147, 147, 0.15);
          cursor: pointer;

          &:not(:first-child) {
            margin-left: 1rem;
          }

          &:hover {
            border: 3px solid#ff8141;
            border-radius: 5px;
            box-shadow: 0px 10px 30px #9393931A;

            .field-selecttype__recommended {
              border-top-right-radius: 0;
              border-bottom-left-radius: 0;
            }
          }

          &.active {
            border: 3px solid#ff8141;
            border-radius: 5px;
            box-shadow: 0px 10px 30px #9393931A;
          }

          img {
            height: 25px;
          }

          .strong {
            font-size: 18px;
            font-weight: 700;
            margin-top: 5px;
          }

          .field-selecttype__recommended {
            position: absolute;
            top: -1px;
            right: -1px;
            color: #ffffff;
            border-radius: 0 5px 0 5px;
            font-size: 12px;
            padding: 0 7px;
            background-color: #FF8141;
          }

          .field-selecttype__description {
            font-size: 12px;
          }

          button {
            background-color: #FF8141;
            box-shadow: 0px 3px 6px #0000001A;
            border-radius: 5px;
            color: #ffffff;
            margin-top: 10px;
            outline: none;
            border: none;
            cursor: pointer;
          }

          .field-selecttype__icon-mobile {
            display: none;
          }
        }
      }

      &-total-section {
        display: block;
      }
    }
  }

  &__extra-information {
    margin-top: 1.5rem;
    padding: 1.5rem;
    border-radius: 4px;
    box-shadow: 0 2px 7px 3px #d7dae4;
  }

  &__btn-remove {
    margin-top: 5px;
    border: #FF837E;
    padding: 10px 30px;
    margin: 0;
    background: rgb(191, 58, 53);
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    transition: all 0.3s;
    cursor: pointer;
    border-radius: 5px;
    color: white;
    width: 100%;
    min-width: 160px;

    img {
      margin-right: 8px;
      vertical-align: middle;
    }
  }

  &__btn-remove_disable {
    border: #eceff5;
    color: #9ba4bc;
    background: #eceff5;

    cursor: not-allowed;
  }

  &__btn-add, &__btn-next {
    margin-top: 5px;
    border: $primary;
    padding: 10px 30px;
    margin: 0;
    background: $primary;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    transition: all 0.3s;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
    color: white;
    min-width: 160px;

    img {
      margin-right: 8px;
      vertical-align: middle;
    }

    &_disable {
      pointer-events: none;
      border: #eceff5;
      color: #9ba4bc;
      background: #eceff5;
      cursor: not-allowed;
      margin-top: 5px;
      margin: 0;
      padding: 10px 30px;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      transition: all 0.3s;
      border-radius: 5px;
      width: 100%;
      min-width: 160px;
    }
  }

  &__btn-back {
    margin-top: 5px;
    border: #FFE6D9;
    padding: 10px 30px;
    margin: 0;
    background: #FFE6D9;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    transition: all 0.3s;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
    color: $primary;
    min-width: 160px;
  }

  &__btn-toggle {
    img {
      transform: rotate(180deg);
      transition: transform 0.4s linear;
    }
  }

  &__paid {
    background-color: #00c889;
    text-align: center;
    color: white;
  }

  &-background {
    overflow:hidden;
    transition: max-height 0.5s ease;
    height: auto;
    max-height: 0;
    h5 {
      margin-bottom: 4px;
    }
    p {
      font-size: 15px;
      padding-bottom: 20px;
    }
    .arrow-down-orange {
      transition: transform 0.5s;
    }
    .artist-map {
      width: 100%;
      height: 400px;
      position: relative;
      overflow: hidden;
    }
  }

  .arrow-down-orange {
    transition: transform 0.5s;
  }

  .arrow-down {
    transition: transform 0.5s;
  }

  &-active {
    transition: max-height 0.8s ease;
    height: auto;
    max-height: none;
  }

  &-description {
    height: auto;
    overflow: hidden;
    line-height: 18px;
    white-space: pre-line;
    line-height: 1.5;
    transition: max-height 0.5s ease;
    max-height: 70px;
    .arrow-down-orange {
      transition: transform 0.5s;
    }
  }

  &-background-color {
    background: #fff3ec;
    padding: 4px;
    border-radius: 8px;
  }

  &-description-active {
    transition: max-height 0.8s ease;
    height: auto;
    max-height: 950px;
  }

  &-bread-crumbs {
    display: flex;
    align-items: center;
    padding-bottom: 12px;
    margin-top: 50px;
    .bookings {
      color: #252c3f;
      margin-bottom: 7px;
    }
    .eventName {
      margin-top: 3px;
      font-size: 16px;
    }
  }

  .field-selecttype__container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
  }

  .field-selecttype__label {
    max-width: 300px;
    min-height: 100%;
    padding: 15px;
    position: relative;
    border: 3px solid transparent;
    box-shadow: 0px 10px 30px rgba(147, 147, 147, 0.15);
    cursor: pointer;

    &:hover {
      border: 3px solid#ff8141;
      border-radius: 5px;
      box-shadow: 0px 10px 30px #9393931A;

      .field-selecttype__recommended {
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &.active {
      border: 3px solid#ff8141;
      border-radius: 5px;
      box-shadow: 0px 10px 30px #9393931A;
    }

    img {
      height: 25px;
    }

    .strong {
      font-size: 18px;
      font-weight: 700;
      margin-top: 5px;
    }

    .field-selecttype__recommended {
      position: absolute;
      top: -1px;
      right: -1px;
      color: #ffffff;
      border-radius: 0 5px 0 5px;
      font-size: 12px;
      padding: 0 7px;
      background-color: #FF8141;
    }

    .field-selecttype__description {
      font-size: 12px;
    }

    button {
      background-color: #FF8141;
      box-shadow: 0px 3px 6px #0000001A;
      border-radius: 5px;
      color: #ffffff;
      margin-top: 10px;
      outline: none;
      border: none;
      cursor: pointer;
    }

    .field-selecttype__icon-mobile {
      display: none;
    }
  }

  &__map {
    width: 100%;
    height: 300px;
    margin-bottom: 20px;
  }

  &__artist-map {
    width: 100%;
    height: 400px;
  }

  &__artist-location {
    text-align: end;
    margin-top: 20px;
    a {
      margin-bottom: 10px;
      height: fit-content;
      padding-bottom: 10px;
    }
  }

  &-map {
    width: 100%;
    height: 100%;
    border-radius: 10px 0px 0px 10px;
  }
}

.gig-flow-control-section {
  display: block;
}

.gig-flow-control-section-mobile {
  display: none;
}

.booking-payment-success {
  &__icon {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background-color: #00c889;
    display: flex;
    margin: auto;
    margin-bottom: 30px;

    img {
      margin: auto;
      width: 42px;
    }
  }

  p {
    font-size: 16px;
  }
}

.event_date_time_form {
  .mob_time_selector {
    display: none !important;
  }
  .web_time_selector {
    display: flex !important;
    justify-content: space-evenly;
    align-items: center;
  }
  .date-time-item {
    display: flex;
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.31);
    border-radius: 7px;
    margin-bottom: 1rem;
    padding: 12px;
    width: 100%;

    .event-date,
    .event-time {
      font-size: 14px;
      font-weight: 400;
      padding: 0;
      margin: 0 auto;
      text-align: left;
      align-self: center;
    }

    .time-input {
      outline: none;

      &::-webkit-calendar-picker-indicator {
        display: none;
      }

      &::-webkit-datetime-edit-ampm-field {
        display: none;
      }
    }
  }

  &__date {
    flex-basis: 50%;
    margin-bottom: 7px;
    text-align: left !important;
  }
  &__time {
    flex-grow: 1 !important;
  }
}
.v-btn-toggle>.v-btn.v-btn {
  opacity: 0.5 !important;
}
.v-btn-toggle>.v-btn.v-btn--active {
  opacity: 1 !important;
}

// .event_date_time_form .v-btn__content {
//   color: rgb(255, 129, 65) !important;
// }
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn:nth-child(1) {
  border-color: rgba(255, 129, 65) !important;
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn:nth-child(2) {
  border-color: rgba(255, 129, 65) !important;
}

// .theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn--active {
//   border-color: rgb(255, 129, 65) !important;
// }
// .theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn--active .v-btn.v-btn:nth-child(2) {
//   background-color: rgba(255, 129, 0.5);
// }

.event_date_time_form .v-btn__content:nth-child(1) { 
  color: rgb(255, 129, 65) !important;
}

.booking_cancel_select {
  height: 42px;
  border: 1px solid #DBE0EB;
  padding: 8px 12px;
  border-radius: 4px;
  font-family: "Nunito Sans";
  font-size: 16px;

  &:focus-visible {
    border: 1px solid $primary;
    outline: none;
  }
}

.bs-wizard {
  border-bottom: solid 1px #e0e0e0;
  padding: 0 0 10px 0;
}

.bs-wizard > .bs-wizard-step {
  width: 25%;
  padding: 0;
  position: relative;
}

.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {
  color: #595959;
  font-size: 16px;
  margin-bottom: 5px;
}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot {
  position: absolute;
  width: 25px;
  height: 25px;
  display: block;
  background: $primary;
  top: 47px;
  left: 50%;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {
  content: ' ';
  width: 13px;
  height: 13px;
  background: white;
  border-radius: 50px;
  position: absolute;
  top: 6px;
  left: 6px;
}

.bs-wizard > .bs-wizard-step > .progress {
  position: relative;
  border-radius: 0px;
  height: 6px;
  box-shadow: none;
  margin: 20px 0;
}

.bs-wizard > .bs-wizard-step > .progress > .progress-bar {
  width:0px;
  box-shadow: none;
  background: #FFE6D9;
}

.bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {
  width:100%;
}

.bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {
  width:50%;
}

.bs-wizard > .bs-wizard-step:first-child.active > .progress > .progress-bar {
  width:0%;
}

.bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {
  width: 100%;
}

.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot {
  background-color: #f5f5f5;
}

.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot:after {
  background-color: #9BA5BC;
}

.bs-wizard > .bs-wizard-step:first-child  > .progress {
  left: 50%;
  width: 50%;
}

.bs-wizard > .bs-wizard-step:last-child  > .progress {
  width: 50%;
}

.bs-wizard > .bs-wizard-step.disabled a.bs-wizard-dot {
  pointer-events: none;
}

.bs-wizard > .bs-wizard-step.disabled .bs-wizard-stepnum {
  color: #9BA5BC;
}


@media screen and (max-width: 1080px) {

  .review-system {
    .rating-star-box {
      padding: 9px 10px 9px 10px;
      width: 80px;
      height: 70px;
      margin-left: 15px;
      p {
        font-size: 12px;
      }
      .rating-star {
        width: 25px;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .user-name {
    font-size: 13px;
    width: min-content;
  }
  .price {
    font-size: 14px;
  }
  .review-artist {
    padding: 0 7px 0 10px;
    p {
      font-size: 13px;
    }
    img {
      margin-top: 6px;
    }
  }
  .review-system {
    padding: 10px;
    border-radius: 10px;
    h3 {
      font-size: 20px;
    }
    p {
      font-size: 13px;
    }
    .star-boxes {
      justify-content: space-around;
    }
    box-shadow: #9393934A 0px 10px 15px 3px;
    .rating-star-box {
      border-color: rgb(255, 243, 236);
      padding: 8px 8px 8px 11px;
      width: 70px;
      height: 60px;
      margin-left: unset;
      line-height: 12px;
      p {
        font-size: 11px;
      }
      .rating-star {
        width: 20px;
      }
    }
  }
  .show-booking-summary {
    display: none;
  }
  .new-booking {

    &__art-type {
      .event_types-container,
      .art_types-container {
        .btns {
          padding: 0px 20px 0px 20px;
        }
      }
    }
    width: 100%;
    margin: auto;
    padding: 56px 0px;

    &__content {
      width: 100%;
      padding: 0px;
    }
    .back-next {
      margin-bottom: 75px;
    }
    .nxt-btn {
      margin-bottom: 75px;
    }
  }
  .event_date_time_form {
    .mob_time_selector {
      display: flex !important;
      justify-content: space-evenly;
      align-items: center;
    }
    .web_time_selector {
      display: none !important;
    }
  }

  .drop-down-options {
    display: none;
  }

  .gig-flow-control-section {
    display: none;
  }

  .gig-flow-control-section-mobile {
    display: block;
    background: rgb(37, 44, 63);
    padding: 20px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;
    color: #FFFFFF;
    border-radius: 14px 14px 0 0;
    width: 100%;
    font-weight: 600;
  }

  .booking-artist-detail {
    .wish_to_quote {
      font-size: 17px;
    }
    .download_our_app {
      font-size: 28px;
    }
    &-web {
      display: none;
    }
    &-mob {
      display: block;
    }
    &__final-payout {
      p {
        font-size: 12px;
      }
    }
    &__phone {
      width: 50%;
    }

    &__booking-declined {
      display: block;
      background-color: #E34445;
      color: white;
      font-size: 15px;
      border-radius: 4px;
    }

    .table {
      margin-left: 3px !important;
      margin-right: 3px !important;
    }
  }

  .booking-client-detail {
    &__artist-block {
      padding: 10px;
      min-height: auto;

      .application-actions {
        display: none;
      }

      .application-actions-mobile {
        display: block;
        align-self: flex-end;

        .msg-btn {
          padding: 5px;
          font-size: 12px;
          color: #FF8141;
          background: #fff0e9;
          border-radius: 5px;
        }

        .add-btn {
          padding: 5px;
          color: #FFFFFF;
          font-size: 12px;
          background: #FF8141;
          border-radius: 5px;
        }

        .remove-btn {
          padding: 5px;
          color: #FFFFFF;
          font-size: 12px;
          background: rgb(191, 58, 53);
        }
      }

      .artist-note-mobile {
        display: block;
        margin-top: 5px;

        a, p {
          font-size: 12px;
          line-height: 15px;
          max-height: 50px;
          overflow: scroll;
        }
      }
    }

    &__artist-info {
      margin: 0 0 0 10px;
      padding: 0;

      h3 {
        font-size: 17px;

      }

      .pro-badge {
        display: none;
      }

      .pro-badge-mobile {
        display: block;
        height: 25px;
        width: 75px;
        margin-left: -5px;
      }

      .artist-quoted-price {
        display: none;
      }

      .artist-quoted-price-mobile {
        display: block;
        font-size: 12px;
        line-height: 13px;
        font-weight: 700;
      }

      .artist-note {
        display: none;
      }
    }

    &__artist-action {
      flex-basis: 100%;
      margin-right: 10px !important;
      margin-left: 10px;

      h3 {
        text-align: left !important;
        padding-left: 10px;
        font-size: 17px;
      }
    }

    &__artist-country {
      font-size: 12px;
      line-height: 13px;
    }

    &__artist-specialities {
      font-size: 12px;
    }
  }

  .booking-detail {

    &__payment-section {
      .show_btns_web {
        display: none;
      }
      .show_btns_mob {
        display: inline-block;
      }
      border-radius: 0;
      box-shadow: none;
      padding: 0;

      .payment-plans {

        &-options {
          display: block;

          .field-selecttype__label {
            width: auto;
            min-width: auto;
            padding: 10px;
            max-width: none;

            &:not(:first-child) {
              margin-left: 0;
              margin-top: 10px;
            }
          }
        }

        &-total-section {
          display: none;
        }
      }
    }

    &__extra-information {
      padding: 0;
      margin-left: -10px;
      margin-right: -10px;
      box-shadow: none;
      border-radius: 0;

      &-header {
        font-size: 20px;
        font-weight: 600;
      }

      &-detail {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 0.5rem;
      }
    }
  }

  .booking-artist-detail__final-payout {
    p {
      width: 100%;
      font-size: 14px;
    }
    button {
      margin-left: 5px;
    }
  }
}

@media screen and (max-width: 768px) {
  .booking-artist-detail {
    &__summary-div {
      display: none;
    }

    &__btn-ban {
      img {
        height: auto;
      }
    }
  }

  .booking-detail {
    &-bread-crumbs {
      display: none;
    }

    &-header {
      display: none;
    }
  }
}

@media screen and (max-width: 450px) { 
  .new-booking {
    &__art-type {
      .event_types-container,
      .art_types-container {
        .btns {
          padding: 0px 17px 0px 17px;
          font-size: 12px;
          margin-right: 8px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .booking-artist-detail {
    .wish_to_quote {
      font-size: 14px;
    }
    .download_our_app {
      font-size: 20px;
      line-height: normal;
    }
  }
  .review-artist {
    p {
      font-size: 9px;
    }
    img {
      width: 12px;
    }
  }
  .review-system {
    h3 {
      font-size: 16px;
    }
    p {
      font-size: 10px;
    }
    .rating-star-box {
      padding: 6px 6px 6px 6px;
      width: 45px;
      height: 45px;
      p {
        font-size: 7px;
      }
      .rating-star {
        width: 12px;
      }
    }
  }
}

.share-modal {
  &__content {
    max-width: 600px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
  }

  &__header {
    background-color: #FFE6D9;
    min-height: 45px;
    h5 {
      text-align: left;
      padding-left: 30px;
      font-weight: bold;
      padding-top: 13px;
    }
    span:hover {
      cursor: pointer;
    }
  }
  &__close {
    color: #FF8141;
    position: absolute;
    right: 25px;
    top: 4px;
    font-size: 24px;
  }
  &__body {
    padding-left: 30px;
    margin-top: 20px;
    text-align: left;
    padding-right: 30px;

    p {
      font-size: 13px;
      color: black;
    }

    .modal-content {
      font-size: 15px;
    }

    .modal-term a {
      font-size: 15px;
    }
  }
  &__button {
    margin-top: 10px !important;
    margin-bottom: 30px !important;
  }
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  -webkit-appearance: none !important;
}

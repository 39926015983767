.mobile-apps-banner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  margin-top: 24px;
  background: rgba(255, 255, 255, 1);
  background: url(../images/mobile-apps-banner-devices@2x.png) 95% bottom no-repeat;
  background-size: 35%;
  border: 1px solid #dadfea;
  border-radius: 4px;
  overflow: hidden;
  padding: 62px;
  &__logo {
    width: 64px;
  }
  & h3 {
    margin: 32px 0 0 0;
    font-size: 42px;
    color: #252C3F;
    line-height: 54px;
  }
  & h3 span {
    color: #FF8141;
  }
  & p {
    margin: 8px 0 0 0;
    font-size: 16px;
    color: #666666;
    line-height: 24px;
  }
  &__links {
    margin: 32px 0 0 0;
  }
  &__links a {
    margin: 0 16px 0 0;
  }
}

@media screen and (max-width:900px) {
  .mobile-apps-banner{
    background-image: none;
    align-items: center;
    text-align: center;
  }
}

@media screen and (max-width:700px) {
  .mobile-apps-banner{
    & h3 {
      font-size: 24px;
      line-height: 32px;
    }
    &__links a {
      margin: 0 8px 0 0;
    }
  }
}

@media screen and (max-width:360px) {
  .mobile-apps-banner{
    padding: 32px;
  }
}

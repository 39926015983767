$primary: #FF8141;
$light: #FE9554;
$dark: #FA732E;
$darkest: #8f3a10;
$weak: #FFF3EC;
$selection: #FFD1BB;

$black: #252C3F;
$gray: #454F69;
$silver: #9BA4BC;
$blight: #DBE0EB;
$lighter: #ECEFF5;

$success: #00C889;
$info: #00B7C8;
$warning: #FBC138;
$error: #EF5151;

$white: #ffffff;
$light-periwinkle: #c5cee0;
$cloud-burst: #222b45;
$link-water: #EFF4FA;
$bali-hai: #8f9bb3;
$salmon: #FF8A65;
$cod-gray: #131313;
$shamrock: #46D7A9;
$light-salmon: #FFA375;
$pickled-bluewood: #344563;
$shuttle-gray: #5E6C84;

.cr-primary{
  color: $primary;
  &-bg{
    background-color: $primary;
  }
}
.cr-light{
  color: $light;
  &-bg{
    background-color: $light;
  }
}
.cr-dark{
  color: $dark;
  &-bg{
    background-color: $dark;
  }
}
.cr-weak{
  color: $weak;
  &-bg{
    background-color: $weak;
  }
}
.cr-black{
  color: $black;
  &-bg{
    background-color: $black;
  }
}
.cr-gray{
  color: $gray;
  &-bg{
    background-color: $gray;
  }
}
.cr-silver{
  color: $silver;
  &-bg{
    background-color: $silver;
  }
}
.cr-blight{
  color: $blight;
  &-bg{
    background-color: $blight;
  }
}
.cr-lighter{
  color: $lighter;
  &-bg{
    background-color: $lighter;
  }
}
.cr-success{
  color: $success;
  &-bg{
    background-color: $success;
  }
}
.cr-info{
  color: $info;
  &-bg{
    background-color: $info;
  }
}
.cr-warning{
  color: $warning;
  &-bg{
    background-color: $warning;
  }
}
.cr-error{
  color: $error;
  &-bg{
    background-color: #c44c49;
    border-radius: 3px;
    color: white;
  }
}

$dark-blue: #222b45;
$light-gray: #dbe0eb;
$dark-gray: #333;
$black: #252c3f;

.pro-page {
  .page-title {
    text-align: center;
    color: $dark-blue;
    font-family: Nunito Sans, sans-serif;

    h1.title {
      font-size: 32px;
      line-height: 32px;
      font-weight: 800;
    }

    p.subtitle {
      font-size: 16px;
      font-weight: 400;
    }
  }

  .switch {
    display: flex;
    justify-content: space-between;
    max-width: 200px;
    margin: 0 auto;
    border-radius: 32px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    position: relative;
    cursor: pointer;

    &-item {
      width: 50%;
      text-align: center;
      padding: 5px 20px;
      margin: 5px;
      z-index: 1;
      transition: all ease 0.5s;

      &--active {
        color: $white;
      }
    }

    &-highlight {
      width: 50%;
      height: calc(100% - 10px);
      background: $primary;
      border-radius: 32px;
      position: absolute;
      top: 5px;
      left: 5px;
      transition: all ease-in-out 0.5s;

      &--left {
        // left: 5px;
      }

      &--right {
        transform: translateX(100%) translateX(-10px);
      }
    }

    .save {
      position: absolute;
      top: 40px;
      left: 180px;
      font-size: 14px;
      font-weight: 700;
      display: flex;
      min-width: 160px;
      align-items: center;
    }
  }

  .pricing {
    .top-placement {
      height: 40px;
      background: $primary;
      color: $white;
      text-transform: uppercase;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border: solid 1px $primary;
      font-weight: 700;
    }

    .transparent-placement {
      height: 40px;
    }

    .price-box {
      border-radius: 5px;
      box-shadow: 0 10px 30px 0 rgba(147, 147, 147, 0.1);
      background-color: #ffffff;
      height: 100%;

      &--focus {
        border: solid 1px $primary;
      }

      &--pro {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      .header {
        text-align: center;
        border-bottom: 1px solid $light-gray;
        display: flex;
        flex-direction: column;

        .plan-name {
          font-weight: 800;
          color: $primary;
          font-size: 32px;
        }

        .price {
          font-size: 24px;
          font-weight: 700;
          color: $black;
        }

        .hint {
          font-size: 14px;
          color: $dark-gray;

          span.highlight {
            color: $primary;
          }
        }
      }

      .description {
        text-align: center;
        font-weight: 700;
        font-size: 14px;
        color: $black;
        min-height: 60px;
      }

      ul.features {
        list-style: none;

        li.feature {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          font-size: 14px;
          color: $black;

          &--highlight {
            color: $primary;
          }

          &--yes {
            &:before {
              content: '';
              display: inline-block;
              height: 16px;
              width: 22px;
              background-image: url('../images/pricing/yes.svg');
              background-size: cover;
              margin-right: 10px;
            }
          }

          &--no {
            &:before {
              content: '';
              display: inline-block;
              height: 16px;
              width: 16px;
              background-image: url('../images/pricing/no.svg');
              background-size: cover;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  h4.section-title {
    font-weight: 800;
    text-align: center;
  }
}
@media screen and (max-width: 640px) {
  .pro-page {
    .save {
      left: 130px !important;
    }
  }
}

/*
* Third Party
*/
//@import "../../bower_components/normalize.css/normalize.css";

/*
* Custom
*/
@import "./bootstrap5_grid.scss";
@import "bootstrap/scss/transitions";
@import "./partials/_colors.scss";

@import "./partials/_vue-multiselect.min.scss";

@import "./partials/_reset.scss";
@import "./partials/_layout.scss";
@import "./partials/_box.scss";
@import "./partials/_typography.scss";
@import "./partials/_forms.scss";
@import "./partials/_modal.scss";

@import "./partials/_appbar.scss";
@import "./partials/_footer.scss";
@import "./partials/_buttons.scss";
@import "./partials/_menu.scss";
@import "./partials/_home.scss";
@import "./partials/_case.scss";
@import "./partials/_workshop.scss";
@import "./partials/_gallery.scss";
@import "./partials/_services_page.scss";
@import "./partials/_discover.scss";
@import "./partials/_artist-info.scss";
@import "./partials/_settings.scss";
@import "./partials/_workshop-info.scss";
@import "./partials/_profile.scss";
@import "./partials/_calendar.scss";
@import "./partials/_back-next.scss";
@import "./partials/_socials.scss";
@import "./partials/_account.scss";
@import "./partials/_location-search.scss";
@import "./partials/_requests.scss";
@import "./partials/_preloader.scss";
@import "./partials/_rating.scss";
@import "./partials/_feedback.scss";
@import "./partials/_contact-us.scss";
@import "./partials/_upcoming.scss";
@import "./partials/_recommended_banner.scss";
@import "./partials/_art_category.scss";
@import "./partials/_featured.scss";
@import "./partials/_logos.scss";
@import "./partials/_info-message.scss";
@import "./partials/_mobile-apps-banner.scss";
@import "./partials/_search.scss";
@import "./partials/_image-swiper.scss";
@import "./partials/_modal-vue.scss";
@import "./partials/_gig-banner.scss";
@import "./partials/_currency-search.scss";
@import "./partials/_progress-bar.scss";
@import "./partials/smart-banner.scss";
@import "./partials/dashboard.scss";
@import "./partials/blog.scss";
@import "./partials/dashboard_bookings.scss";
@import "./partials/_pro.scss";
@import "./partials/_badges.scss";
@import "./partials/_new-booking.scss";
@import "./partials/_analytics.scss";
@import "./partials/__class_packages.scss";
@import "./partials/_billing-portal.scss";
@import "./partials/messenger.scss";
@import "./partials/ai.scss";
@import "./partials/client-page.scss";

// Helper classes
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
           -khtml-user-select: none; /* Konqueror HTML */
             -moz-user-select: none; /* Old versions of Firefox */
              -ms-user-select: none; /* Internet Explorer/Edge */
                  user-select: none; /* Non-prefixed version, currently
                                        supported by Chrome, Edge, Opera and Firefox */
}

.noselect:before {
  position: absolute;
  top: -14px;
  left: 90%;
  transform: rotate(180deg);
  height: 0;
  width: 0;
  border: 7px solid transparent;
  z-index: 2;
  content: "";
  border-top-color: #ffffff;
}

.popup-modal {
  display: flex;
  justify-content: center;
  height: 98vh !important;
  .user-kind {
    margin-top: 30px;
    &-option {
      cursor: pointer;
      text-align: center;
      position: relative;
      padding: 11px 15px;
      height: 130px;
      width: -webkit-fill-available;
      box-shadow: 0 3px 10px 0 rgba(147, 147, 147, 0.2);
      border-radius: 5px;
      &-header {
        text-align: center;
        &__title {
          font-size: 15px;
          font-weight: 700;
          line-height: 32px;
          color: #04031c;
          margin-bottom: unset;
        }
        &__description {
          margin: auto;
          color: #9ba4bc;
          font-size: 10px;
          line-height: 18px;
        }
      }
      &-header__logo {
        height: 18px;
        margin: auto;
      }
    }
  }
  .arrow-right {
    height: 15px;
  }
  .inputs {
    padding: 20px 30px;
  }
  .soulartists {
    height: 200px;
    background: #FF8141;
    text-align: center;
    img {
      height: 90px;
      width: 430px;
      margin-top: 30px;
    }
    h3 {
      text-align: center;
      color: white;
      font-size: 22px;
    }
  }
  &__header {
    display: flex;
    justify-content: end;
    min-height: 20px;
    padding-right: 15px;
    background: #FF8141;
  }
  &__content {
    max-width: 550px;
    position: fixed;
    overflow-y: auto !important;
    right: 0;
    left: 0;
    top: 3%;
    bottom: 5%;
    border-radius: 10px;
    min-height: 96vh;
  }
}

// Overrid vue-js-modal
.vm--overlay {
  background: rgba(4,3,28,.7) !important;
}

.vm--container {
  overflow-y: scroll;
  height: 100dvh;
}

.bg-blue {
  background-color: #00b7c8;
}

.hidden {
  display: none !important;
}

.pagination {
  justify-content: center;

  .page, .first, .prev, .next, .last {
    padding: 5px;
    margin-left: 5px;
    font-size: 18px;

    a {
      color: $light;
      font-size: 18px;
    }
  }
}

.noty_theme__mint.noty_type__info, .noty_theme__mint.noty_type__information {
  background-color: #FF8141 !important;
  border-bottom: 1px solid #FF8141 !important;
}

.not-found{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.not-found__icon{
  margin-bottom: 28px
}
.not-found__title{
  color: #04031C;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 8px
}
.not-found__description{
	color: #9BA4BC;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
  text-align:center;
}
.unexpected_error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
  .background-img {
    top: 100px;
    width: 352px;
    position: absolute;
  }
  .unexpected_error__title { 
    color: rgb(255, 129, 65);
    font-weight: 700;
    font-size: 100px;
    line-height: 120px;
    margin-bottom: 8px;
    z-index: 10;
  }
  .unexpected_error__description {
    font-size: 20px;
    margin-top: 80px;
    color: #04031C;
    text-align:center;
  }
  .unexpected_error_buttons {
    font-size: 20px;
    margin-top: 30px;
    .btn {
      width: 150px;
      font-size: 12px;
    }
    .home-page {
      background: rgb(255 129 65);
      color: white
    }
    .help-center {
      margin-left: 20px;
      background: #FFF3EC;
      color: rgb(255 129 65)
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

@media screen and (max-width: 640px) { 
  .popup-modal {
    .user-kind {
      &-option {
        margin-right: unset;
        height: 110px;
        margin-top: 10px;
        &-header {
          &__title {
            font-size: 15px !important;
          }
          &__description {
            width: auto !important;
            font-size: 10px !important;
          }
        }
      }
    }
    .soulartists {
      height: 140px;
      img {
        height: 45px;
        width: 300px;
      }
      h3 {
        font-size: 17px;
      }
    }
    &__content {
      height: fit-content;
      padding-bottom: 110px;
      width: -webkit-fill-available;
      top: 0;
      bottom: 0;
    }
  }
  .unexpected_error { 
    .background-img { 
      top: 105px;
      width: 250px;
    }    
    .unexpected_error__title { 
      font-size: 70px;
      line-height: 80px;
    }
    .unexpected_error_buttons {
      display: flex;
      width: 240px;
      align-items: center;
      flex-direction: column;
      margin-top: 130px;
      .btn {
        width: 240px;
        height: 50px;
        font-size: 20px;
        padding-top: 12px;
      }
      .help-center {
        margin-left: unset;
        margin-top: 20px;
        background: #FFF3EC;
        color: rgb(255 129 65)
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .unexpected_error { 
    .background-img { 
      top: 160px;
      width: 180px;
    }    
    .unexpected_error__title { 
      font-size: 50px;
      line-height: 60px;
      margin-top: 40px;
    }
    .unexpected_error__description {
      font-size: 13px;
    }
    .message {
      font-size: 11px;
    }
    .unexpected_error_buttons {
      width: 240px;
      margin-top: 120px;
      .btn {
        width: 180px;
        height: 45px;
        font-size: 15px;
      }
      .help-center {
        margin-left: unset;
        margin-top: 20px;
        background: #FFF3EC;
        color: rgb(255 129 65)
      }
    }
  }

}

.banners-container {
  display: flex;
  gap: 15px;

  .banner-container {
    flex: 1 1 0;
    .banner-img-container {
      .banner-img {
        height: 250px;
        min-width: 350px;
        border-radius: 10px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        -webkit-box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
      }
    }

    .banner-header {
      font-size: 16px;
      font-weight: 700;
    }

    .banner-sub-header {
      color: #9ba4bc;
      font-size: 12px;
      line-height: 16px;
    }
  }
}

@media screen and (max-width: 1068px){
  .banners-container {
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.gig-banner {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  position: relative;
  background: rgba(255, 255, 255, 1);
  // background: url(../images/gig-banner-background@2x.png) center center
  //   no-repeat;
  background-size: cover;
  border-radius: 4px;
  overflow: hidden;
  padding: 62px;
  text-align: left;
  &__icon {
    width: 68px;
    height: 68px;
  }
  &__header {
    color: #fff;
    font-size: 42px;
    line-height: 50px;
  }
  &__paragraph {
    color: #fff;
    max-width: 380px;
  }
}

@media screen and (max-width: 900px) {
  .gig-banner {
    align-items: center;
    text-align: center;
  }
}

@media screen and (max-width: 700px) {
  .gig-banner {
    &__header {
      color: #fff;
      font-size: 24px;
      line-height: 32px;
    }
  }
}

@media screen and (max-width: 640px) {
  .gig-banner {
    padding: 62px 30px;

    .gig-btn {
      height: auto;
      padding-bottom: 8px;
    }
  }
}

.gig-btn {
  width: fit-content;
  padding-left: 20px;
  padding-right: 20px;
  min-width: 112px;
}

.messenger {
  .messengerBreadCrumbs {
    display: flex;
    align-items: center;
    padding-bottom: 12px;
    border-bottom: 1px solid #dbe0eb;
    a {
      font-size: 20px;
    }
    .eventName {
      margin-top: 3px;
    }
    .Bookings {
      color: $black;
      margin-bottom: 7px;
    }
  }

  &__list {
    .detail {
      a {
        font-weight: 600;
        color: $black;
      }
    }
    display: flex;
    flex-direction: column;

    &-header {
      flex: 0 1 auto;
    }

    &-channels {
      flex: 1 1 auto;
      overflow: auto;
    }

    &-avatar {
      position: relative;
      height: 60px;
      width: 84px;
      border-radius: 5px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &-avatar-1 {
      height: 60px;
      width: 60px;
    }

    &-avatar-2 {
      img {
        height: 35px;
        width: 35px;
        border-radius: 5px;

        &:first-child {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 1;
        }

        &:last-child {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }

    &-avatar-3 {
      img {
        height: 28px;
        width: 28px;

        &:first-child {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 1;
          height: 56px;
          width: 28px;
        }

        &:nth-child(2) {
          position: absolute;
          top: 0;
          right: 0;
        }

        &:last-child {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
    }

    &-avatar-4 {
      img {
        height: 28px;
        width: 28px;

        &:first-child {
          position: absolute;
          top: 0;
          left: 0;
        }

        &:nth-child(2) {
          position: absolute;
          top: 0;
          right: 0;
        }

        &:nth-child(3) {
          position: absolute;
          bottom: 0;
          left: 0;
        }

        &:nth-child(4) {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }

      p {
        height: 28px;
        width: 28px;
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: $primary;
        text-align: center;

        span {
          color: #ffffff;
          text-align: center;
          vertical-align: middle;
          font-size: 14px;
        }
      }
    }

    &-description {
      font-weight: 300;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      font-size: 12px;
      margin-top: 7px;
      width: 230px !important;
      .description {
        overflow: hidden;
        font-size: 14px;
        max-height: 29px;
        width: 165px;
        text-overflow: ellipsis;
      }
      .lastMessageSeen {
        color: #9db1bc;
        font-size: 8px;
      }
    }
    .channelName {
      width: 100%;
        h5 {
          font-size: 14px;
          margin: unset;
        }
      .lastMessageSent {
        float: right;
        margin-top: 8px;
        font-size: 8px;
      }
    }

    &-notification {
      p {
        font-size: 12px;
        line-height: 16px;
      }
    }

    .badge {
      display: inline;
      background-color: $primary;
      color: $lighter;
      font-size: 8px;
      font-weight: 700;
      border-radius: 3px;
      padding: 1px 4px;
    }
    .editQuote {
      background: #fff3ec;
      padding: 4px;
      border-radius: 8px;
    }
    .FAQs {
      display: none;
    }
    .faqs-for-client {
      p {
        font-size: 12px;
      }
      a {
        font-size: 12px;
      }
    }

    .client-bottom-border {
      margin: auto;
      width: 235px;
      margin-bottom: 10px;
      border-bottom: 1px solid rgb(219, 224, 235);
      text-align: center;
    }
  }

  &__detail {
    display: flex;
    flex-direction: column;
    height: 100%;

    &-header {
      flex: 0 1 auto;
      width: calc(100% - 4px);
      position: sticky;
      top: 0;
      z-index: 99;
      background: white;
      button {
        background-color: $white; /* Green */
        border: 1px solid #555555;
        color: $black;
        padding: 6px 8px;
        border-radius: 5px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 10px;
        margin: 4px 2px;
        cursor: pointer;
      }
    }

    &-content {
      .message:last-child {
        margin-bottom: 65px;
        margin-top: unset !important;
      }
      flex: 1 1 auto;
      overflow-y: auto;
      overflow-x: hidden; 
      .notif-error {
        background-color: #FF837E;
        padding: 10px 0 10px 30px;

        b {
          color: $white;
        }
      }
      .border-right {
        border-right: 1px solid rgb(219, 224, 235);
      }
    }
    &-content::-webkit-scrollbar {
      width: 4px;
    }

    &-content::-webkit-scrollbar-thumb {
      border: 3px solid #ff8141;
      height: 200px;
    }

    &-footer {
      flex: 0 1 50px;
    }

    &-footer-mobile {
      width: 100%;
      position: fixed;
      left: 0;
      bottom: 0;
      background: white;
      padding: 0px 0 15px 0px;
      z-index: 99;
    }

    .mins-ago {
      color: $black;
      font-size: 10px;
      line-height: 1.8;
    }
  }
  .system_message{
    background: #f4f5f8;
  }
  .eventDate {
    
    letter-spacing: normal;
    text-align: left;
    font-size: 12px;
    font-weight: 300;
  }

  .delete_copy_Icons {
    height: 50px;
    background: #ffdccb;
    position: relative;
    right: 0;
    left: 0;
    .icons {
      float: right;
      padding-top: 15px;
      padding-right: 35px;
    }
  }

  .term-message {
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    font-size: 14px;
    color: $black;
  }

  .information-circle {
    width: 60px;
    height: 40px;
    background: url(../images/ionic-ios-information-circle-outline.svg) 50% 50%;
    background-position: center;
    background-size: 25px 25px;
    background-repeat: no-repeat;
    display: inline-block;
    color: $black;
  }

  @mixin icon-file {
    width: 25px;
    height: 25px;
    background: url(../images/icon-file.svg) 50% 50%;
    background-position: center;
    background-size: 25px 25px;
    background-repeat: no-repeat;
    display: inline-block;
    margin-top: 4.5px;
  }

  .sender-icon-file {
    @include icon-file;
    filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(321deg) brightness(106%) contrast(101%);
  }

  .receiver-icon-file {
    @include icon-file;
    filter: invert(63%) sepia(44%) saturate(2037%) hue-rotate(330deg) brightness(100%) contrast(102%);
  }

  .close-input-file {
    @include icon-file;
    width: 20px;
    height: 20px;
    background-size: 20px 20px !important;
    margin-top: 0;
    top: -10px;
    right: -14px;
    position: absolute !important;
    background: url(../images/close-red.svg) 50% 50%;
  }

  @mixin messeage-setting-attributes {
    width: 14px;
    height: 14px;
    background-position: center;
    background-size: 14px 14px;
    background-repeat: no-repeat;
    display: inline-block;
    cursor: pointer;
  }

  .message-setting {
    background: url(../images/message-setting.svg) 50% 50%;
    @include messeage-setting-attributes;
  }

  .message-setting-gray {
    background: url(../images/message-setting-gray.svg) 50% 50%;
    @include messeage-setting-attributes;
  }

  @mixin chat-icon-attributes {
    width: 30px;
    height: 21px;
    background-position: center;
    background-size: 21px 21px;
    background-repeat: no-repeat;
    display: inline-block;
  }

  .attachment-icon {
    background: url(../images/attachment-icon.svg) 50% 50%;
    @include chat-icon-attributes;
  }

  .emoticon-icon {
    background: url(../images/emoticon-icon.svg) 50% 50%;
    @include chat-icon-attributes;
  }

  .send-message-icon {
    background: url(../images/send-message.svg) 50% 50%;
    @include chat-icon-attributes;
  }

  .send-message {
    border-top: 1px solid #dbe0eb;

    &-textarea {
      display: inline-block;
      width: 100%;
      border: none;
      padding: 8px 12px;
      border-radius: 10px;
      background-color: #f7f7f7;
      font-family: "Nunito Sans";
      font-weight: 600;
      color: #252c3f;
      font-size: 16px;
      line-height: 24px;
      transition: all 0.3s;
      box-sizing: border-box;
      resize: none;

      &:focus, &:active {
        border-color: $primary;
        outline: none;
      }
    }
  }

  .receiver-background {
    display: flex;
    color: $black;
    line-height: 1.41;
    border-radius: 15px;

    .message-content {
      flex-direction: row-reverse;
      justify-content: start;
      b {
        color: $black;
      }

      .message-avatar {
        margin-right: 15px;
        margin-left: 5px;
      }
    }
    .sideOfSystemMessage {
      padding: 0px 15px 0px 8px;
      width: 70px;
      .verticalOrangeLine {
        height: calc(100% - 25px);
        margin-left: 11px;
        border-left: 2px solid #ff8141;
      }
      .logoImg {
        width: 23px;
        height: auto;
      }
    }
  }
  
  .systemBackground{
    width: 97%;
    background: #f4f5f8;
  }

  .message-avatar {
    width: 40px;
    height: 40px;
    border-radius: 2px;
    object-fit: contain;
  }

  .color-white {
    color: $white !important;
  }

  .color-black {
    color: $black !important;
  }

  .text-align-right {
    text-align: right;
  }

  .float-right {
    float: right;
  }

  .messages {
    border-left: 1px solid #dbe0eb;
    border-right: 1px solid #dbe0eb;
  }

  .edit-quotation {
    border-radius: 5px;
    color: $white;
    background-color: #ff8141;
  }

  .edit-quotation:before {
    content: "";
    width: 10px;
    height: 10px;
    background-color: #ff8141;
    position: absolute;
    top: 48px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
  .quotation-arrow-down-orange {
    transition: transform 0.5s;
  }
  .arrow-down-orange {
    transition: transform 0.5s;
  }
  .arrow-down {
    transition: transform 0.5s;
  }
  .quotation-background {
    overflow:hidden;
    transition: max-height 0.5s ease;
    height: auto;
    max-height: 0;
    border-radius: 5px;
    background-color: #fff3ec;
    .current-price {
      opacity: 0.52;
      font-size: 13px;
    }
    h5 {
      padding: 5px 0px 5px 0px;
    }
    p {
      font-size: 14px;
    }
    .new-quotation-background {
      border-radius: 5px;
      border: solid 1px #ff8141;
      background-color: rgba(255, 255, 255, 0);
    }

    .tooltip-event-price::before {
      content: "";
      width: 10px;
      height: 10px;
      background-color: #ff8141;
      position: absolute;
      top: -5px;
      left: 7%;
      transform: translateX(-50%) rotate(45deg);
    }

    .tooltip-event-price__fee-detail {
      padding-left: 5px;
    }

    .tooltip-event-price__fee-total {
      padding-left: 5px;
    }

    @mixin button-booking-attributes {
      border-radius: 5px;
      box-shadow: 0 5px 8px 0 rgba(255, 129, 65, 0.3);
      color: $white;
    }

    .confirm-booking {
      background-color: #ff8141;
      @include button-booking-attributes;
    }

    .cancel-booking {
      background-color: #eb7979;
      @include button-booking-attributes;
    }

    .border-radius-5px {
      border-radius: 5px;
    }
  }
  .event-detail-background {
    overflow:hidden;
    transition: max-height 0.5s ease;
    height: auto;
    max-height: 0;
    h5 {
      margin-bottom: 4px;
    }
    p {
      font-size: 15px;
      padding-bottom: 20px;
    }
    .arrow-down-orange {
      transition: transform 0.5s;
    }
    .artist-map {
      width: 100%;
      height: 400px;
      position: relative;
      overflow: hidden;
    }
  }

  .booking-description {
    white-space: pre-line;
    line-height: 1.5;
    height: auto;
    overflow: hidden;
    transition: max-height 0.5s ease;
    max-height: 70px;
    .arrow-down-orange {
      transition: transform 0.5s;
    }
  }

  .active{
    transition: max-height 0.8s ease;
    height: auto;
    max-height: 950px;
  }
  .booking-detail-active {
    transition: max-height 0.8s ease;
    height: auto;
    max-height: 1800px;
  }
  .description-active {
    transition: max-height 0.8s ease;
    height: auto;
    max-height: 950px;
  }

  .ml-10vw {
    margin-left: 10vw !important;
  }

  .quote-input {
    border: none;
    background: transparent;
    width: calc(100% - 70px);;
  }

  .search {
    margin: 0;
    width: 100%;

    .search__input {
      border: none;
      border-bottom: 1px solid #dadfea;
    }
  }
  .detail-edit-btn {
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px 0;
      font-size: 12px;
      height: 30px;
      width: 140px;
    }
    .btn-image {
      width: 15px;
      margin-right: 3px;
    }
    .parent {
      position: relative;
      top: 0;
      left: 0;
      .image1 {
        position: relative;
        width: 15px;
        top: 0;
        left: 0;
      }
      .image2 {
        position: absolute;
        width: 10px;
        top: 3px;
        left: 2px;
    
      }
    }
  }
  .flag-img {
    border-radius: unset;
    width: 22px;
    height: 13px;
  }
  .pro-member {
    width: 50px;
    height: 21px;
  }
  .top-border {
    border-top: 1px solid rgba(218, 223, 234, 0.6);
  }

  .no-message {
    height: 60px;
    width: 60px;
    border-radius: 5px;
    background: #ff8141;
    overflow: hidden;
    img {
      width: 50%;
      height: 50%;
      margin: 25%;
    }
  }
  .card {
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0px 0px 15px 10px hsl(0deg 0% 58% / 10%)
  }
  .EventDetailsBold {
    font-weight: 800;
  }
  .bt {
    h5 {
      border-top: 1px solid rgba(218, 223, 234, 0.6);
      padding-top: 10px;
    }
    p {
      margin-bottom: 10px;
    }
  }
}
.channel-details-scroll::-webkit-scrollbar {
  width: 4px;
}
.channel-details-scroll::-webkit-scrollbar-thumb {
  border: 3px solid #ff8141;
  height: 200px;
}
.header-for-mobile {
  display: none;
}
.transform {
  transition: transform 0.5s;
  transform:rotate(180deg);
  -ms-transform:rotate(180deg);
  -webkit-transform:rotate(180deg);
  
}

.mobileQuoteArrowTransform {
  transition: transform 0.5s;
  transform:rotate(90deg);
  -ms-transform:rotate(90deg);
  -webkit-transform:rotate(90deg);
  
}

.message-action {
  padding-right: 15px;
  margin-top: 15px;

  button {
    min-width: 130px;
  }

  .mw-50 {
    max-width: 50%;
    height: 35px;
  }

  .mw-100 {
    max-width: 100%;
  }

  .w-70 {
    width: 80%;
    max-width: 80%;
  }

  .btn-action-decline {
    background-color: #fff3ec;
    height: 35px;
    border: #FF837E;
    color: #FF837E;
  }

  a {
    text-decoration: underline;
  }
}

.container--messenger {
  max-width: 95vw;
  height: calc(100vh - 40px);
  padding: 20px 15px;
  padding-top: 80px;
}

.vld-overlay {
  outline: none;

  .vld-background {
    background-color: transparent !important;
  }

  .vld-icon {
    text-align: center;
  }
}

@mixin attachment-background {
  border-top-left-radius: 0px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 0px;
  font-size: 10px;
  line-height: 1.8;
  padding: 10px;
}

.sender-attachment-background {
  color: $white;
  background-color: #ff8141;
  @include attachment-background;
}

.receiver-attachment-background {
  color: #ff8141;
  background-color: #f4f5f8;
  position: relative;
  @include attachment-background;
}

.cursor-pointer {
  cursor: pointer;
}

.emoji-picker {
  bottom: 4vh;
  position: absolute;
  z-index: 1;
  font-family: Montserrat;
  border: 1px solid #ccc;
  width: 15rem;
  height: 20rem;
  overflow: scroll;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 1px 1px 8px #c7dbe6;
}

.emoji-picker h5 {
  margin-bottom: 0;
  color: #b1b1b1;
  font-size: 0.8rem;
  cursor: default;
}

.emoji-picker .emojis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.emoji-picker .emojis:after {
  content: "";
  flex: auto;
}

.emoji-picker .emojis span {
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 5px;
}

.emoji-picker .emojis span:hover {
  background: #ececec;
  cursor: pointer;
}

.close-report {
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 2%;
  height: 30px;
}

.report-message-header {
  background-color: #FFF1E9;
}

.message-icon {
  background-size: 20px 20px;
  background-position: left center;
  background-repeat: no-repeat;
  padding-left: 30px;
  background-image: url(../images/message-orange.svg);
  background-size: 18px 18px;
}
.return-to-messenger-booking-detail {
  margin-top: 15px;
  height: 45px;
  border: none;
  background: none;
  font-family: "Nunito Sans";
  text-align: start;
  color: #FA732E;
  background: #FFF3EC;
  cursor: pointer;
  border-radius: 5px;
  a {
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
    font-family: "Nunito Sans";
    .message-icon {
      background-size: 18px;
      padding-left: 20px;
    }
  }

}
.return-to-messenger {
  margin: 20px 30px 0 0;
  height: 45px;
  border: none;
  background: none;
  font-family: "Nunito Sans";
  text-align: center;
  transition: all 0.3s;
  color: #FA732E;
  box-shadow: 0 3px 3px 0 rgb(255 129 65 / 30%);
  background: #FFF3EC;
  cursor: pointer;
  border-radius: 5px;
  a {
    padding: 10px 0px;
    font-family: "Nunito Sans";
    .message-icon {
      background-size: 20px 25px;
      padding-left: 26px;
    }
  }
}
.mobile-return-to-messenger {
  display: none;
}
@media screen and (max-width: 1200px) {
  .messenger {
    &__list {
      .channelName {
        h5 {
          font-size: 11px;
        }
        .lastMessageSent {
          font-size: 6px;
          margin-top: -11px;
        }
      }
    }
  }
  .messenger__list-description {
    .description {
      font-size: 12px;
    }
  }
}
@media screen and (max-width: 820px) {
  .messenger {
    &__list {
      .channelName {
        p {
          font-size: 6px;
        }
      }
    }
  }
  .return-to-messenger {
    display: none !important;
  }
  .mobile-return-to-messenger {
    display: block;
    height: 40px;
    width: 80%;
    margin: auto;
    text-align: center;
    transition: all 0.3s;
    color: #FA732E;
    box-shadow: 0 3px 3px 0 rgb(255 129 65 / 30%);
    background: #FFF3EC;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    a {
      line-height: 1;
      font-size: 16px;
      .message-icon {
        background-size: 22px 27px;
        padding-left: 28px;
      }
    }
  }
}
@media screen and (max-width: 640px){
  .messenger {
    padding: 0;
    .messages {
      border-left: unset;
      border-right: unset;
    }
    .messengerBreadCrumbs {
      display: none;
    }
    .receiver-background {
      .sideOfSystemMessage {
        padding: 0px 10px 0px 8px;
        width: 110px;
        .verticalOrangeLine {
          margin-left: 11px;
        }
      }
    }
    &__detail {
      &-header {
        border-right: unset
      }
      &-content {
        .border-right {
          border-right: unset
        }
      }
    }
    .participants_eventDetails{
      border-bottom: 1.5px solid rgb(255, 129, 65);
      width: fit-content;
      padding-bottom: 8px;
    }
    .FAQs {
      display: block;
      padding-top: 20px;
      padding-bottom: 50px;
      p {
        font-size: 12px;
      }
    }
    .detail-edit-btn {
      button {
        font-size: 10px;
        height: 27px;
        width: 115px;
        border-radius: 5px;
      }
      .btn-image {
        margin-right: 2px
      }
      .parent {
        .image2 {
          width: 10px;
          top: 3px;
          left: 2.5px;
        }
      }
    }
  }
  .messenger__list-description {
    .description {
      max-height: 25px;
    }
  }
  .container--messenger {
    padding-top: 65px;

    .btn-action-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .message-action {
      button {
        min-width: 80px;
      }
    }

    .appbar-list__message__setting__dropdown.sender {
      left: -110px;
      &::before {
        left: 45%
      }
    }
  }
  .messenger__detail-header {
    padding: 0 0 5px 0;
    .eventDetails{
      border-bottom: 1px solid rgb(255, 129, 65);
    }
    .FAQs {
      display: block;
      padding-top: 25px;
      padding-bottom: 25px;
      p {
        font-size: 12px;
      }
    }
  }
  .header-for-mobile {
    display: flex;
    flex-direction: column;
    z-index: 10;
    button {
      background-color: $white; /* Green */
      border: 1px solid #555555;
      color: $black;
      padding: 0 0;
      border-radius: 10px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 10px;
      margin: 4px 2px;
      cursor: pointer;
    }
  }
}

.break-word {
  white-space: pre-line;
  word-break: break-word;
}

@mixin search-caret {
  background-size: 20px 20px;
  background-position: left center;
  background-repeat: no-repeat;
  padding-left: 18px;
  background-size: 18px 18px;
  cursor: pointer;
}

.search-match-label {
  background-color: #f7b928;
}

.search-caret-up {
  @include search-caret();
  background-image: url(../images/search-caret-up.svg);

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
    cursor: default;
  }
}

.search-caret-down {
  @include search-caret();
  background-image: url(../images/search-caret-down.svg);

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
    cursor: default;
  }
}

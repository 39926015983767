$preloaderDotSize: 8px;   // size of each dot
$preloaderDotColor: $primary; // color at full opacity
$preloaderDotTimer: 0.75s;    // time for full animation
$preloaderDotCount: 3;     // amount of dots (spans)
$preloaderDotFader: 0.4;   // opacity to fade to

#app-preloader {
	background: #111;
	height: 100%;
	left: 0;
	position: absolute;
	text-align: center;
	top: 0;
	width: 100%;
	span {
		background: $preloaderDotColor;
		display: inline-block;
		height: $preloaderDotSize;
		margin: 0 calc($preloaderDotSize / 2);
		position: relative;
		top: 50%;
		transform: translateY(-50%);
		vertical-align: top;
		width: $preloaderDotSize;
		@for $i from 1 through $preloaderDotCount {
			&:nth-child(#{$i}) {
				animation: pulse $preloaderDotTimer infinite (calc($preloaderDotTimer / $preloaderDotCount) * $i);
			}
		}
	}
	.no-cssanimations & {
    // Modernizr fallback for browsers without CSS animation
		//background: #000 url('preloader-fallback.gif') no-repeat 50% 50%;
		span {
			display: none;
		}
	}
}

@keyframes pulse {
	0%, 100% { opacity: 1; }
	50% { opacity: $preloaderDotFader; }
}
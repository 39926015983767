p{
  margin:0px;
  color: $gray;
  font-family: "Nunito Sans";
  font-size: 16px;
  line-height: 24px;
}

.highlighted {
  color: $primary;
}

.medium {
	color: #5F5B5A;
	font-family: "Nunito Sans";
	font-size: 14px;
	line-height: 22px;
}

strong , b, .strong{
	color: $black;
	font-weight: 600;
}

small , .small{
	color: $silver;
	font-family: "Nunito Sans";
	font-size: 12px;
	line-height: 16px;
}

a , .link{
  text-decoration: none;
  color:$primary;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  transition: 0.5s;

  &:hover{
    color: $light;
  }
  // &:visited{
  //   color:$light;
  // }
  &:active{
    color: $light;
  }

}

h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
  font-family: "Nunito Sans";
  color: $black;
  font-weight: 700;
}

h1,.h1{
  font-size: 48px;
  line-height: 64px;
}
h2,.h2{
  font-size: 32px;
  line-height: 48px;
}
h3,.h3{
	font-size: 24px;
  line-height: 32px;
}
h4,.h4{
  font-size: 18px;
  line-height: 22px;

}
h5,.h5{
  margin:0px 0px 8px 0px;
	font-size: 16px;
	line-height: 20px;
}
h6,.h5{

}

.underline-dasher{
  border-bottom: 1px dashed;

}

.clip{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.left {
  text-align: left;
}
.center {
  text-align: center;
}
.right {
  text-align: right;
}
.justify {
  text-align: justify;
}

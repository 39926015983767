.blogs {
  p {
    font-size: 1.125rem;
    font-style: italic;
    margin-top: 0;
    color: #6c757d;
  }
  a {
    line-height: 40px;
  }
}
.blog { 
  p {
    font-size: 1rem;
    color: black;
    line-height: 28px;
    font-weight: initial;
  }
  .image-block {
    img {
      max-height: 400px;
      width: 100%;
    }
  }
}
.blog-container { 
  .blog-main-img:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #212529;
    opacity: 0.5;
  }
  .blogs-title {
    width: 100%;
    font-size: 25px;
    text-align: center;
    color: black;
  }
  .blog-main-img {
    padding-top: 17rem;
    padding-bottom: 17rem;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    background-size: cover;
    position: relative;
    margin-bottom: 3rem;
    background-color: #6c757d;
    background-size: cover;
    background-attachment: scroll;
  }
  .blogs-img {
    padding-top: 17rem;
    padding-bottom: 17rem;
    background-size: cover;
    position: relative;
    margin-bottom: 3rem;
    background-color: #6c757d;
    background-size: cover;
    background-attachment: scroll;
  }
  .blogs-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .site-heading {
    position: relative;
    font-size: 2.25rem;
    font-weight: 800;
    color: #fff;
    display: grid;
    justify-items: center;
  }
  a {
    font-size: 2.25rem;
    font-weight: 800;
    color: black;
  }
  .links {
    font-size: 1rem;
    color: #FF8141;
  }
  .my {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
    opacity: 1;
    color: #dee2e6;
    border-top: 0.5px solid;
  }
}
@media screen and (max-width: 1024px) {
  .blog-container { 
    .site-heading {
      font-size: 3rem;
    }
  }
}
@media screen and (max-width: 600px) {
  .blogs {
    a {
      font-size: 1.75rem;
    }
  }
  .blog { 
    .image-block {
      img {
        max-height: 350px;
      }
    }
  }
  .blog-container {
    .site-heading {
      font-size: 2.25rem;
    }
  }
}
@media screen and (max-width: 380px) {
  .blog { 
    .image-block {
      img {
        max-height: 220px;
      }
    }
  }
}
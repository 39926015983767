
.currency-search{
  &__btn{
    min-width: 16px;
    margin-right: 24px;
    color: $primary;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    padding: 5px 0;
    border-bottom: 1px dashed $primary;
  }

  .multiselect{
    margin:0px;
    &__option:after{
      display: none;
    }
    &__single{
      margin: 0;
    }
    &__select{
      display: none;
    }
    &__input{
     resize: horizontal;
      vertical-align: middle;
      width: 100%;
      margin: 0;
      padding-left: 34px;
      background: url(../images/currency-primary.svg) 0 50% no-repeat;
      font-family: "Nunito Sans";
      font-weight: 600;
      color: #252C3F;
      width: auto;
      font-size: 16px;
      line-height: 24px;
      border-radius: 0px;
      transition:0.8s;
      &:active {
        width: auto;
      }
      &::placeholder {
        color:#9BA4BC;
      }
    }
    &__tag{
      margin-bottom: 8px;
      padding: 4px 28px 4px 8px;
      border-radius: 3px;
      background-color: inherit;
      &>span{
        color: #909CA6;
        font-size: 14px;
        line-height: 16px;
      }
    }
    &__tag-icon{
      transition: all 0.3s;
      &:after{
        color: #C7CDD2;
        &:hover{
          color: #909CA6;
        }
      }
      &:hover{
        background: none;
      }
    }
    &__tags{
      min-height: 34px;
      padding: 8px 0 0 0;
      border-radius: 0;
      border: none;
      border-bottom:none;
      background: transparent;
      display: inline-block;
      width: 100%;
      margin-bottom: 0px;
      padding: 8px 0 0 0;
      border-bottom: 1px solid $blight;
      padding: 16px;
      transition: all 0.3s;
      box-sizing: border-box;
    }
    &__tags-wrap{
      display: inline-block;


    }
    &__content-wrapper{
      border:none;
      font-size: 0;
      margin-top: 0 !important;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius:0px;
      margin-top: 25px;
      //box-shadow: rgba(155, 164, 188, 0.2) 0px 5px 20px 0px;
      position: static;
      padding: 8px 16px;

    }
    &__content{
      background: transparent;
    }
    &__element{
      font-size: 14px;
    }
    &__option{
      min-height: 34px;
      padding: 8px;
      font-family: "Nunito Sans";
      font-weight: 600;
      color: #252C3F;
      font-size: 16px;
      line-height: 24px;

      color: #202529;
      &:hover{
        background-color: rgba(218,223,234,0.29);
      }
      &--highlight{
        background-color: rgba(218,223,234,0.29);
        color: #202529;
      }
    }
  }
}
@media screen and (max-width: 420px) {
  .currency-search__btn{
    max-width: 75px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

::selection {
  background: $selection;
}

// .container {
//   width: 92.15%;
//   max-width: 1128px;
//   margin-left: auto;
//   margin-right: auto;
//   min-width: 320px;
// }

.divider {
  width: 100%;
  height: 1px;
  margin: 24px 0;
  background: $blight;

  &--light {
    margin: 16px 0;
    background: rgb(236, 239, 244);
  }

}

.margin32 {
  margin-bottom: 32px;
}

.margin24 {
  margin-bottom: 24px;
}

.margin16 {
  margin-bottom: 16px;
}

.margin8 {
  margin-bottom: 8px;
}

.margin4 {
  margin-bottom: 4px;
}

@media screen and (max-width: 1024px) {
  .border-bottom {
    position: relative;
  }

  .border-bottom:before,
  .border-bottom:after {
    content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: -1px;
    left: -100%;
    background: $blight;
  }

  .border-bottom:after {
    left: auto;
  }
}

.container--sa {
  max-width: 1140px !important;
}

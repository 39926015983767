.account {
  &-nav {
    position: relative;
    &__overlay {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1;
    }
    &__avatar {
      height: 40px;
      width: 40px;
      cursor: pointer;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        user-select: none;
        object-fit: cover;
        border-radius: 2px;
      }
      .profile-menu__link-notify {
        width: 14px;
        height: 14px;
        margin-left: 0;
        position: absolute;
        top: -6px;
        right: -14px;
        border: 3px solid #ffffff;
      }
    }
    &__menu {
      &-container {
        position: absolute;
        z-index: 10;
        right: 0px;
        width: 264px;
        border: 1px solid #dadfea;
        background-color: #ffffff;
        box-shadow: 0 3px 8px 0 rgba(155, 164, 188, 0.16);
        padding: 4px 16px 4px 16px;
      }
      a {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 13px 8px 13px;
        color: #454f69;
        font-family: "Nunito Sans";
        font-size: 16px;
        line-height: 24px;
        font-weight: normal;
        border-bottom: 1px solid #dadfea;
        &:last-child {
          border-bottom: none;
        }
        &-logout {
          color: #ef5151;
        }
        &.featured_packs {
          color: $primary;
          padding-left: 24px;
          background: url(../images/featured.svg) 0 50% no-repeat;
        }
        .profile-menu__link-notify {
          align-self: flex-start;
        }
      }
    }
  }
}

.forgot-password {
  width: 100%;
  height: 50vh;
  margin: 0 auto;
  text-align: center;
  margin-top: 20px;

  &-info {
    width: 380px;
    border-radius: 5px;
    box-shadow: 0 3px 10px 0 rgba(147, 147, 147, 0.2);
    margin-left: auto;
    margin-right: auto;
    padding: 32px 60px;

    img {
      width: 57px;
      height: auto;
    }

    &__title {
      font-size: 17px;
      font-weight: bold;
      margin: 10px auto;
    }

    &__description {
      font-size: 13px;
    }

    a {
      width: 100%;
      border-radius: 5px;
      margin-top: 15px;
    }
  }
}

@media screen and (max-width: 640px) {
  .forgot-password {
    &-info {
      width: 100%;
      max-width: 100%;
      padding: 32px 50px;
    }
  }
}

$black: #707070;
$orange: #FF8141;
$orange-light: #FE9554;
$white: #fff;
$dark-blue: #454f69;

// mobile link with icon on the left
@mixin icon-left {
  background-size: 20px 20px;
  background-position: left center;
  background-repeat: no-repeat;
  padding-left: 30px;
}

.appbar {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding: 8px 0;
  background: rgba(255, 255, 255, 0.97);
  border-bottom: 1px solid rgba(218,223,234,.6);
  z-index: 999;
  webkit-box-shadow: 0 3px 3px #9393931A;
  -moz-box-shadow: 0 3px 3px #9393931A;
  box-shadow: 0 3px 3px #9393931a;
  &-badge {
    height: 8px;
    width: 8px;
    background-color: $primary;
    border-radius: 8px;
    margin: 0 0 1px 2px;
  }
  &-wrapper {
    display: flex;
  }
  &-container {
    display: flex;
    justify-content: space-between;
  }
  &-list {
    display: flex;
    &__logo {
      margin-top: 6px;
      height: 28px;
      width: 44px;
      min-width: 32px;
      padding-right: 18px;
      box-sizing: content-box;
    }
    &__menu-btn {
      width: 24px;
      height: 24px;
      margin-left: 16px;
      margin-top: 8px;
    }
    &__link {
      color: $black;
      padding: 9px 13px 9px 12px;
      font-size: 16px;
      line-height: 24px;
      font-weight: normal;
      font-family: 'Nunito Sans', sans-serif;
      &:hover {
        color: $orange;
      }
      &-special {
        font-size: 16px;
        line-height: 24px;
        font-weight: normal;
        color: $primary;
        margin-right: 25px;
        padding: 9px 0px 1px 0px;
        border-bottom: 1px dashed #ff814140;
        &:hover {
          color: $light;
        }
      }
    }
    &__signin {
      font-size: 18px;
      font-weight: 600;
    }

    &__signup {
      font-size: 18px;
      font-weight: 600;
    }

    &__search {
      cursor: pointer;
      position: relative;
      height: 42px;
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #9ba4bc;

      @include media-breakpoint-down(lg) {
        &__text {
          display: none;
        }
      }

      @include media-breakpoint-up(lg) {
        border: 1px solid #dadfea;
        border-radius: 5px;
        padding-left: 20px;
        padding-right: 60px;

        &__text {
          margin-left: 20px;
        }
      }
    }

    &__global {
      margin-left: 20px;
      cursor: pointer;
      position: relative;

      &-icon {
        width: 42px;
        height: 42px;
        background-image: url(../images/feather-globe.svg);
        background-position: center;
        background-size: 22px 22px;
        background-repeat: no-repeat;
      }

      &-dropdown {
        display: flex;
        flex-direction: column;
        background: $white;
        position: absolute;
        min-width: 270px;
        right: 0;
        top: 56px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);

        &__item {
          padding: 15px 15px 15px 15px;
          background:$white;
          display: flex;
          flex-direction: column;
          cursor: pointer;

          &:hover {
            background: rgba(254, 149, 84, 0.2);
          }

          .title {
            font-size: 16px;
            color: $dark-blue;
            font-weight: 600;
            margin-bottom: 8px;
          }

          .value {
            font-size: 16px;
            color: #9ba4bc;
            padding-left: 20px;
            background-repeat: no-repeat;
            background-position: left center;

            &--country {
              background-image: url(../images/nav/location.svg);
            }

            &--language {
              background-image: url(../images/nav/globe.svg);
              background-size: 15px 15px;
            }

            &--currency {
              background-image: url(../images/nav/dollar.svg);
            }
          }
        }
      }
    }

    &__notifications {
      cursor: pointer;
      position: relative;

      &-icon {
        width: 42px;
        height: 42px;
        background-image: url(../images/nav/bell.svg);
        background-position: center;
        background-size: 22px 22px;
        background-repeat: no-repeat;
        cursor: pointer;
      }

      &-see_all {
        line-height: 1.13;
        text-align: center;
        color: #ff8141;
        margin-bottom: 15px;
        margin-top: 15px;
        font-size: 16px;
        font-weight: bold;
      }

      &-title {
        margin: 15px 20px;
        margin-bottom: 0px;
        font-size: 16px;
        font-weight: 600;
      }

      &-unread {
        font-family: "Barlow", sans-serif;
        font-size: 12px;
        min-width: 20px;
        height: 20px;
        font-weight: 600;
        text-align: center;
        color: #fff;
        background: #ff6b00;
        padding: 2px 3px;
        border: 1px solid #fff;
        border-radius: 50%;
        position: absolute;
        top: 1px;
        left: 42%;
      }
      &__list {
        list-style: none;
        margin-top: 10px;
        overflow: hidden;
        margin-left: 10px;

        &-link {
          width: 100%;
        }

        &-item {
          display: flex;
          align-items: center;
          border-bottom: solid 1px #eceff4;
          padding-bottom: 15px;
          padding-top: 15px;
          &:not(:last-child) {
            margin-bottom: 20px;
          }
          img {
            height: 60px;
            width: 60px;
            object-fit: cover;
            border-radius: 5px;
          }
          &__info {
            margin-left: 20px;
            margin-right: 10px;
            overflow: hidden;
            span {
              font-size: 15px;
              display: inline;
              text-overflow: ellipsis;
              overflow: hidden;
              color: #222b45;

              &.artist-name {
                font-weight: bold;
                font-size: 15px;
              }
              &::before {
                font-size: 18px;
                margin-right: 5px;
              }
            }
            p {
              font-size: 15px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;

              &.notification-time {
                font-size: 14px;
                line-height: 1.29;
                letter-spacing: normal;
                text-align: left;
                color: #9ba4bc;
              }
            }
          }
          &__dot {
            height: 7px;
            width: 7px;
            background-color: #ff7929;
            border-radius: 50%;
            display: inline-block;
            margin-left: auto;
            margin-right: 10px;
            padding-left: 7px;
          }
        }
      }
    }

    &__message__setting {
      position: relative;

      &__dropdown {
        display: flex;
        flex-direction: column;
        background: $white;
        position: absolute;
        min-width: 220px;

        top: 30px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
        z-index: 100;

        

        &.receiver {
          right: -110px;
          &::before {
            left: 40%;
          }
        }

        li.message-setting-item {
          padding: 0 20px;
          font-size: 18px;
          a {
            color: $black;
          }

          &:hover {
            background: rgba(254, 149, 84, 0.2);
          }

          .message-setting-item-inner {
            display: block;
            padding: 15px 0 15px 32px;
            border-top: solid 1px #eceff4;
            background-position: left center;
            background-repeat: no-repeat;
            background-size: 20px 20px;
          }
        }
      }

    }

    &__profile {
      margin-left: 24px;
      width: 42px;
      height: 42px;
      cursor: pointer;
      position: relative;

      &__profile-image {
        img {
          width: 42px;
          height: 42px;
          border-radius: 4px;
        }
      }

      &__dropdown {
        display: flex;
        flex-direction: column;
        background: $white;
        position: absolute;
        min-width: 240px;
        right: 0;
        top: 56px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);

        .profile-item-dashboard {
          margin: 15px 20px;
          font-size: 16px;
          font-weight: 600;
        }

        li.profile-item {
          padding: 0 20px;
          font-size: 18px;
          a {
            color: $black;
          }

          &:hover {
            background: rgba(254, 149, 84, 0.2);
          }

          .profile-item-inner {
            display: block;
            padding: 15px 0 15px 32px;
            border-top: solid 1px #eceff4;
            background-position: left center;
            background-repeat: no-repeat;
            background-size: 20px 20px;

            &--manage-profile {
              background-image: url(../images/nav/user.svg);
            }

            &--booking {
              background-image: url(../images/nav/booking.svg);
            }

            &--premium {
              background-image: url(../images/nav/premium.svg);
              color: $orange;
            }

            &--signout {
              background-image: url(../images/nav/signout.svg);
            }
          }

        }
      }

    }

    &__notification {
      margin-left: 24px;
      width: 42px;
      height: 42px;
      cursor: pointer;
      position: relative;

      &__dropdown {
        display: flex;
        flex-direction: column;
        background: $white;
        position: absolute;
        min-width: 350px;
        right: 0;
        top: 56px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);

        .profile-item-dashboard {
          margin: 15px 20px;
          font-size: 16px;
          font-weight: 600;
        }

        li.profile-item {
          padding: 0 20px;
          font-size: 18px;
          a {
            color: $black;
          }

          &:hover {
            background: rgba(254, 149, 84, 0.2);
          }

          .profile-item-inner {
            display: block;
            padding: 15px 0 15px 32px;
            border-top: solid 1px #eceff4;
            background-position: left center;
            background-repeat: no-repeat;
            background-size: 20px 20px;

            &--manage-profile {
              background-image: url(../images/nav/user.svg);
            }
          }
        }
      }
    }

    &__vertical {
      text-align: center;
      height: 70%;
      border-left: 2px solid #eceff5;
      justify-content: center;
      margin-left: 7px;
      margin-right: 7px;
      margin-top: 7px;
    }

    &__mobile {
      padding-right: 20px;

      .mobile-nav-icon {
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      .mobile-nav {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(4,3,28,.7);

        &-wrapper {
          width: 260px;
          position: fixed;
          top: 0;
          left: 0;
          bottom: 0;
          background: $white;
          overflow-y: scroll;
        }

        &-top {
          display: flex;
          padding-top: 10px;
          padding-bottom: 16px;

          &__logo {
            height: 36px;
            display: flex;
            align-items: center;
            margin-left: 10px;
            border-left: 1px solid rgb(236, 239, 244);
            padding-left: 10px;
            padding-top: 6px;
            img, svg { max-height: 23px; }
          }

          &__hamburger {
            display: flex;
            align-items: center;
            img, svg {
              max-height: 16px;
            }
            cursor: pointer;
          }
        }

        &-main {

        }

        &-button {
          width: 100%;
          display: flex;
          padding: 0;
          margin: 0;
          align-items: center;
          justify-content: center;

          img {
            margin-right: 16px;
          }
        }

        &-section {
          color: rgb(69, 79, 105);
          font-weight: 600;
          display: block;
          font-size: 16px;
          text-transform: uppercase;
          margin-bottom: 16px;
        }

        &-link {
          color: rgb(69, 79, 105);
          font-weight: 600;
          display: block;
          cursor: pointer;
          transition: all ease 0.3s;

          &:hover {
            color: $orange-light;

            a {
              color: $orange-light;
            }
          }

          &--margin-bottom {
            margin-bottom: 20px;
          }

          &--help {
            @include icon-left;
            background-image: url(../images/question-mark.svg);
          }

          &--booking {
            @include icon-left;
            background-image: url(../images/booking.svg);
            background-size: 18px 18px;
          }

          &--calendar {
            @include icon-left;
            background-image: url(../images/calendar.svg);
          }

          &--review {
            @include icon-left;
            background-image: url(../images/star.svg);
          }

          &--message {
            @include icon-left;
            background-image: url(../images/message.svg);
            background-size: 18px 18px;
          }

          &--premium {
            @include icon-left;
            background-image: url(../images/nav/premium.svg);
            color: $orange;
          }

          &--classes {
            @include icon-left;
            background: url(./../images/graduation-cap-gray.svg) no-repeat;
            background-size: 18px 18px;
          }

          &--localization {
            @include icon-left;
            background-image: url(../images/nav/globe.svg);
          }

          &--security {
            @include icon-left;
            background-image: url(../images/nav/security.svg);
          }

          &--billing {
            @include icon-left;
            background-image: url(../images/credit-card.svg);
          }

          &--notification {
            @include icon-left;
            background-image: url(../images/nav/bell-noti.svg);
          }


          &--analytics {
            @include icon-left;
            background-image: url(../images/analytics.svg);
          }

          &--more {
            background-image: url(../images/nav/chevron-right.svg);
            background-size: 16px 16px;
            background-position: right center;
            background-repeat: no-repeat;
          }

          &--back {
            @include icon-left;
            background-image: url(../images/nav/chevron-left.svg);
            background-size: 16px 16px;
          }

          &--logout {
            @include icon-left;
            background-image: url(../images/nav/signout.svg);
            background-size: 16px 16px;
          }

        }
      }

    }
  }
}

@mixin check-box-notification {
  input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: 1px solid #efefef;
    border-radius: 4px;
    outline: none;
    transition-duration: 0.3s;
    background-color: #efefef;
    cursor: pointer;
    height: 13px;
    width: 13px;
  }

  input[type="checkbox"]:checked {
    background-color: #ff7929;
  }
}

.notifications {
  text-align: left;
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
  min-height: 10em;

  &-mark_read_all {
    &-left {
      float: left;
    }

    &-right {
      float: right;
      padding-top: 10px;
      display: flex;
      align-items: center;
      span {
        font-size: 13px;
        margin-left: 5px;
      }

      @include check-box-notification();
    }
  }
}

.mark-as-read {
  @include check-box-notification();
}

@media (max-width: 767px) {
  .mobile-nav {
    &-main {
      padding-bottom: 20px;
    }
  }
}

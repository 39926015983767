.covid-badge {
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #00b7c8;
  padding: 3px 12px;
  padding-left: 32px;
  font-size: 14px;
  background-image: url('../images/shield.svg');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 14px 18px;
  color: white;
}

.avatar-container {
  position: relative;
  width: 100%;

  &-event-booking {
    flex: 0 0 180px;
    width: 180px;
    height: 180px;
    border-radius: 4px;
    &-img {
      width: 180px;
      height: 180px
    }
  }
}

.pro-featured-badge {
  padding: 3px 10px;
  font-size: 0.75rem;
  text-align: center;
  background-color: #FF8141;
  color: #fff;
  border-radius: 4px;

  img {
    height: 12px;
    margin-right: 2px;
  }
}

.profile-covid-badge {
  padding: 3px 10px;
  font-size: 0.75rem;
  text-align: center;
  background-color: #00b7c8;
  color: #fff;
  border-radius: 4px;

  img {
    height: 12px;
    margin-right: 2px;
  }

  &__top {
    bottom: 20px;
    font-size: 14px;
    padding: 3px 0px;
    img {
      height: 15px !important;
    }
  }
  &__discover {
    bottom: 5px;
    font-size: 14px;
    padding: 3px 0px;

    img {
      height: 15px !important;
    }
  }

  &__event-booking {
    font-size: 14px;
    padding: 3px 0px;

    img {
      height: 15px !important;
    }
  }
}

@media screen and (max-width: 480px){
  .profile-covid-badge {
    &__discover {
      padding: 0px 16px;
      font-size: 14px;
      img {
        height: 14px !important;
      }
    }
  }
}
@media screen and (max-width: 768px){
  .pro-featured-badge, .profile-covid-badge {
    padding: 2px 0;
  }
  .pro_member_vaccinated {
    margin-left: 25px;
    .profile-covid-badge {
      padding: 4px 7px;
      margin-left: 25px;
      img {
        height: 14px !important;
        margin-right: 4px;
      }
    }
  }
}

@media screen and (max-width: 640px){
  .avatar-container {
    &-event-booking {
      flex: 0 0 130px;
      width: 90px;
      height: 90px;
      border-radius: 4px;
      &-img {
        width: 90px;
        height: 90px;
      }
    }
  }
}
@media screen and (max-width: 390px) {
  .pro-featured-badge {
    font-size: 0.55rem;

  }

  .profile-covid-badge {
    font-size: 0.55rem;
    }
}

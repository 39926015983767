.workshopi{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  &-img{
    width: 100%;
    flex: 0 0 100%;
    height: 368px;
    margin-bottom: 48px;
  }
  &-sidebar{
    flex: 0 0 23.4%;
  }
  &-user{
    display: flex;
    align-items: center;
    margin-bottom: 48px;
    &__avatar{
      width: 72px;
      flex: 0 0 72px;
      height: 72px;
      margin-right: 24px;
      box-shadow: 0 4px 16px 0 rgba(2,0,12,0.16);
    }
    &__name{
      margin-bottom: 8px;
    }
  }
  &-btns{
    &__buy.primary{
      width: 100%;
      margin-bottom: 24px;
      box-shadow: 0 8px 21px 0 rgba(255,129,65,0.44);
    }
    &__share.secondary{
      width: 100%;
    }    
  }
  &-main{
    flex: 0 0 65.86%;
  }
  .evemt-map{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    & > p{
      width: 100%;
      padding: 16px;
      text-align: center;
      border: 1px solid #F2F1F1;
      border-top: none;
    }
  }
}

.details{
  margin-bottom: 48px;
  h3{
    margin-bottom: 24px;
  }
  &-item{
    display: flex;
    align-items: center;
    justify-content: flex-start;  
    &:not(:last-child){
      margin-bottom: 16px;
    }  
    &__name{
      flex: 0 0 86px;
      margin-right: 16px;
    }
    &__value{
      flex-grow: 1;
    }
  }
}

@media screen and (max-width: 1024px){
  .workshopi{
    display: block;
    &-sidebar{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      position: relative;
    }
    &-user{
      flex: 0 0 37.93%;
      margin-right: 13.79%;
    }
    &-btns{
      display: flex;
      flex-direction: column-reverse;
      flex-wrap: wrap;
      width: 37.93%;
      position: absolute;
      top: 96px;
      left: 0;
      &__buy.primary{
        height: 48px;
        padding-top: 12px;
        margin-bottom: 0;
      }
      &__share.secondary{
        margin-bottom: 16px;
      }
    }
    &-details{
      flex-grow: 1;
    }
    &-divider{
      display: none;
    }
  }
}

@media screen and (max-width: 640px){
  .workshopi{
    &-img{
      height: 200px;
    }
    &-sidebar{
      display: block;
      margin-bottom: 40px;
    }
    &-user{
      margin-right: 0;
      margin-bottom: 48px;
    }
    &-btns{
      width: 100%;
      position: static;
      &__buy.primary{
        height: 48px;
        padding-top: 12px;
        margin-bottom: 0;
      }
      &__share.secondary{
        margin-bottom: 16px;
      }
    }
    &-details{
      margin-bottom: 40px;
    }
    &-divider{
      display: block;
    }
  }
}
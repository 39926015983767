.workshop{
  display: flex;
  justify-content: space-between;
  &-package-list{
    width: 100%;
    max-width: 744px;
    margin: 0 auto;
  }
  &:not(:last-child){
    margin-bottom: 40px;
  }
  &-img{
    width: 360px;
    flex: 0 0 360px;
    min-height: 264px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &-info{
    flex-grow: 1;
    margin: 0 24px;
    & > p{
      margin: 16px 0;
    }
  }
  &-data{
    &__item{
      display: flex;
      align-items: flex-start;
      &:not(:last-child){
        margin-bottom: 12px;
      }
    }
    &__name{
      flex: 0 0 88px;
      margin-right: 16px;
    }
    &__value{
      flex-grow: 1;
    }
  }
  &-price{
    align-self: flex-end;
    text-align: right;
    h3{
      margin-bottom: 16px;
    }
  }
}

@media screen and (max-width: 1024px){
  .workshop{
    display: block;
    &-img{
      width: 100%;
      margin-bottom: 24px;
    }
    &-info{
      margin: 0;
    }
    &-price{
      transform: translateY(-100%);
    }
  }
}

@media screen and (max-width: 640px){
  .workshop{
    &-price{
      text-align: left;
      margin-top: 24px;
      transform: translateY(0);
      &__more.primary{
        width: 100%;
      }
    }
  }
}
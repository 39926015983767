.info-message {
  width: 100%;
  display: flex;
  align-items: stretch;
  position: relative;
  background: rgba(0, 183, 200, 0.2);
  margin-top: 24px;
  border-radius: 5px;
  &-icon {
    flex: 0 0 42px;
    background: #00b7c8 url(../images/Information-circle.png) 50% 50%
      no-repeat;
    border-radius: 5px;
  }
  &-text {
    flex-grow: 1;
    padding: 10px;
    p {
      text-align: left;
    }
  }
}

label.label {
  display: inline-block;
  width: 100%;
  margin-bottom: 8px;
  font-family: "Nunito Sans";
  color: $silver;
  font-size: 12px;
  line-height: 16px;
}

// Rails error fields
.field_with_errors {
  input.input {
    border-color: $error;
  }
}

input.input {
  display: inline-block;
  width: 100%;
  margin-bottom: 24px;
  padding: 8px 12px;
  border: 1px solid $blight;
  font-family: "Nunito Sans";
  font-weight: 600;
  color: $black;
  font-size: 16px;
  line-height: 24px;
  transition: all 0.3s;
  border-radius: 4px;
  box-sizing: border-box;
  &::placeholder {
    color: $silver;
  }
  &:hover,
  &.hover {
    border-color: $silver;
  }
  &:focus,
  &.focus {
    outline: none;
    border-color: $light;
  }
  &.error {
    margin-bottom: 4px;
    border-color: $error;
  }
  &:disabled,
  &.disabled {
    border-color: $lighter;
    background-color: $lighter;
    color: $silver;
    cursor: default;
  }
}

.error-message {
  margin-bottom: 24px;
  font-family: "Nunito Sans";
  color: $error;
  font-size: 12px;
  line-height: 16px;
}

span.error {
  font-family: "Nunito Sans";
  color: $error;
  font-size: 12px;
  line-height: 16px;
}

select.select {
  @extend input, input;
  height: 54px;
  background: $white url(../images/select-arrows.svg) no-repeat right 16px center;
  border-radius: 0;
  appearance: none;
  cursor: pointer;
}

textarea.textarea {
  @extend input, .input;
  width: 100% !important;
  max-width: 100% !important;
  min-width: 100% !important;
  min-height: 100px !important;
  transition: border 0.3s;
}

.forms {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  &__item {
    flex-shrink: 0;
    flex-grow: 0;
    &-50 {
      flex-basis: 47.82%;
    }
    &-100 {
      flex-basis: 100%;
    }
    &-wp {
      width: 100%;
    }
  }
  h3 {
    flex: 0 0 100%;
    margin-top: 16px;
    margin-bottom: 24px;
  }
  &__btn.btn {
    width: auto;
    min-width: 112px;
    padding: 0 16px;
  }

  &__icon {
    position: relative;

    img {
      position: absolute;
      height: 12px;
      top: 13px;
      right: -25px;
    }
  }
}

.user-kind,
.all-signed-up {
  display: flex;
  justify-content: center;
}

.social-forms {
  h3 {
    margin-bottom: 24px;
  }
  &__item {
    width: 100%;
    max-width: 360px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > div {
      display: flex;
      align-items: center;
    }
    img {
      width: 24px;
      flex: 0 0 24px;
      height: 24px;
      margin-right: 16px;
    }
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
}

.password-watch-icon{
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 38px;
}

@media screen and (max-width: 640px) {
  .forms {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .complete-signup {
    padding: 20px 15px !important;

    &-header {
      margin-bottom: 35px !important;
    }
  }
  .complete-signup-header__logo {
    display: none;
  }

  .user-kind,
  .all-signed-up {
    flex-direction: column;

    &-option {
      max-width: 100%;
      padding: 15px 10px !important;

      &-header {
        margin-bottom: 10px !important;

        &__title {
          font-size: 18px !important;
        }

        &__description {
          width: auto !important;
          font-size: 14px !important;
        }
      }

      &:last-child {
        margin-top: 30px;
      }

      img {
        height: 30px;
        width: auto;
        margin-top: 15px;
        margin-bottom: 10px;
      }

      button {
        width: 80% !important;
        border-radius: 5px !important;
      }
    }
  }
}

.password-field {
  .password-watch-icon {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 38px;

    &.show-password {
      fill: #FF8141;
    }
  }
}

// .test {
//   background-color: black;
//   display: none;
// }




.profile {
  &-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &-sidebar {
    flex: 0 0 23.4%;
    h3 {
      margin-bottom: 8px;
    }
  }
  &-mobile-btn {
    display: none;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    border-bottom: 1px solid $blight;
    cursor: pointer;
  }
  &-user {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    &__avatar {
      width: 80px;
      flex: 0 0 80px;
      height: 80px;
      margin-right: 16px;
    }
    p.small {
      margin: 4px 0 8px;
    }
  }
  &-trigger {
    margin-bottom: 24px;
    &.active & {
      &__top {
        background: $primary;
        border-color: $primary;
        p {
          color: $white;
        }
      }
      &__complete {
        display: block;
      }
      &__btn {
        &:after {
          left: 27px;
        }
      }
    }
    &__top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px;
      background: $weak;
      border: 1px solid $weak;
      p {
        color: $primary;
      }
    }
    &__btn {
      width: 48px;
      height: 24px;
      position: relative;
      background: $white;
      cursor: pointer;
      &:after {
        content: "";
        width: 18px;
        height: 18px;
        position: absolute;
        top: 3px;
        left: 3px;
        background: $dark;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.18);
        transition: all 0.3s;
      }
    }
    &__complete {
      padding: 16px 0;
      border: 1px solid $blight;
      border-top: none;
      transition: all 0.3s;
      &.pt0 {
        padding-top: 0;
      }
      h3 {
        padding: 0 16px;
        margin: 8px 0 16px;
        font-size: 20px;
        line-height: 32px;
        color: $primary;
      }
      p {
        padding: 0 16px;
      }
    }
    &__link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 16px 0;
      border-top: 1px solid $blight;
      color: $gray;
      font-weight: normal;
      &.router-link-active {
        font-weight: 600;
        color: $primary;
      }
      img {
        transform: rotate(-90deg);
      }
    }
  }
  &-menu {
    padding: 16px;
    border: 1px solid $blight;
    &__title {
      margin-bottom: 16px;
      padding-bottom: 8px;
      border-bottom: 1px solid $blight;
    }
    &__item + &__item {
      margin-top: 24px;
    }
    &__link {
      display: flex;
      align-items: center;
      width: 100%;
      padding-left: 8px;
      font-weight: normal;
      color: $gray;
      &.router-link-active {
        font-weight: 600;
        color: $primary;
      }
      &:not(:last-child) {
        margin-bottom: 16px;
      }
      &-notify {
        display: block;
        width: 12px;
        height: 12px;
        margin: auto 8px;
        flex-shrink: 0;
        background: $primary;
        border-radius: 50%;
      }
    }
  }
  &-main {
    flex: 0 0 73%;
  }
  &-liked {
    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: all 0.3s;
      &:not(:last-child) {
        margin-bottom: 24px;
      }
      &:hover {
        background: rgba(255, 246, 241, 0.6);
        .profile-liked__like {
          opacity: 1;
        }
      }
    }
    &__link {
      display: flex;
      align-items: center;
    }
    &__avatar {
      width: 72px;
      flex: 0 0 72px;
      height: 72px;
      margin-right: 24px;
    }
    &__counters {
      display: flex;
      align-items: center;
      p.strong {
        margin: 0 8px;
        color: $black;
      }
    }
    &__stars {
      width: 66px;
      height: 16px;
      background: url('../images/stars.png') 50% 45% no-repeat;
    }
    &__like {
      cursor: pointer;
      margin-right: 26px;
      transition: all 0.3s;
      opacity: 0;
    }
  }
  &-workshops {
    &__item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }
    &__img {
      width: 35.48%;
      flex: 0 0 35.48%;
      height: 190px;
    }
    &__info {
      flex-grow: 1;
      margin: 0 24px;
      h3 {
        margin-bottom: 24px;
      }
    }
    &__state {
      text-align: right;
    }
  }
  &-bookings {
    &__item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 48px;
      }
    }
    &__img {
      width: 168px;
      flex: 0 0 168px;
      height: 168px;
    }
    &__info {
      flex-grow: 1;
      margin: 0 24px;
      h3 {
        margin-bottom: 24px;
      }
    }
    &__list {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      max-width: 364px;
      &-item {
        flex: 0 0 46.7%;
        &:nth-child(odd) {
          margin-right: 6.59%;
        }
      }
    }
    &__state {
      text-align: right;
    }
  }

  &-header {
    display: flex;
    align-items: center;
    font-size: 18px;
    padding-bottom: 15px;
    border-bottom: solid 2px #ebf2f7;

    &__progress {
      display: flex;
    }

    &__avatar {
      width: 80px;
      flex: 0 0 80px;
      height: 80px;
      margin-right: 16px;
      border-radius: 5px;
    }

    &__progressbar {
      width: 490px;

      p {
        font-size: 17px;
        color: #141821;

        &.strong {
          font-weight: 700;
        }
      }

      .vue-simple-progress {
        margin-top: 5px;
        margin-bottom: 5px;
      }

      span {
        float: right;

        a {
          font-size: 12px;
          text-decoration: underline;
        }
      }
    }

    button {
      background-color: #fff3ec;
      color: #ff8141;
      padding: 15px 35px;
      border: none;
      border-radius: 5px;
      margin-left: auto;
    }
  }

  &-artist-menu {
    align-self: stretch;
    padding: 16px;
    padding-top: 30px;
    width: 100%;

    &__title {
      font-size: 20px;
      font-weight: 800;
      margin-bottom: 20px;
    }
    .router-link-active {
      background-color: #fff0e9;
      p {
        color: #ff8141;
      }
    }
    &__link {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 8px 15px;
      font-weight: normal;
      color: $gray;
      &.router-link-active {
        font-weight: 600;
        color: $primary;
      }
      &-notify {
        display: block;
        width: 12px;
        height: 12px;
        margin: auto 8px;
        flex-shrink: 0;
        background: $primary;
        border-radius: 50%;
      }

      p {
        margin-left: 10px;
        font-size: 14px;
      }
    }

    &__dashboard-btn {
      display: none;
    }
  }

  &-artist__form {
    width: 70%;
  }

  &-song {
    &__list {
      box-shadow: 0px 10px 30px #9393931a;
      max-height: 330px;
      overflow-y: auto;
      width: 100%;
      padding: 10px 15px;

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px #fff0e9;
        background-color: #fff0e9;
      }

      &::-webkit-scrollbar {
        width: 5px;
        background-color: #fff0e9;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #ff8141;
      }

      li {
        padding: 8px;
        border-bottom: 1px solid #ebf2f7;

        &.title {
          font-weight: 700;
        }
      }

      .list-item__remove {
        float: right;

        img {
          height: 25px;
          margin-bottom: 2px;
        }
      }
    }
  }

  &-video {
    &__supported {
      height: 45px;
      margin-bottom: 30px;
    }

    .pop-up-forms {
      background: white;
      width: 70%;
      min-height: 38%;
      margin-left: auto;
      margin-right: auto;
      padding-top: 20px;
      padding-bottom: 20px;
      .forms__item {
        width: 85%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
      }
      button{
        min-width: 120px;
        height: 35px;
        margin-left: 40%;
      }
      .close-sign{
        float: right;
        position: relative;
        margin: 15px;
        background: #FF8141;
      }
    }
  
    .pop-up-add-video {
      display: flex;
      align-items: center;
      justify-content: center;
      aspect-ratio: 3/3;
      margin-right: 3.22%;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      background-color: #FF8141;
      z-index: 1;
      img {
        width: 40px;
        height: 40px;
        cursor: pointer;
      }
    }

    .sm-gallery__item-container {
      &:before {
        content: "";
        width: 58px;
        height: 58px;
        position: absolute;
        top: 35%;
        left: 50%;
        transform: translateX(-50%) translateY(0%);
        background: url(../images/play.svg) 50% 50% no-repeat;
        background-size: cover;
        z-index: 1;
        cursor: pointer;
      }
    }

    .list-item {
      border-bottom: none;
      padding-bottom: 0;
    }

    .loading-footer {
      height: 5px !important;
    }

    .forms {
      width: 70%;
    }
  }

  &-audio {
    &__supported {
      max-height: 36px;
      max-width: 100px;
    }

    table {
      td {
        padding-right: 20px;
      }
    }

    .list-item__info {
      width: 100%;
      margin-right: 20px;
    }

    .list-item {
      border-bottom: none;
      padding-bottom: 0;
    }

    .loading-footer {
      height: 5px !important;
    }

    .forms {
      width: 70%;
    }
  }

  &-award {
    .list-item__remove {
      min-width: 61px;
    }
  }

  &-member {
    &__list-member {
      display: flex;
      flex-wrap: wrap;
      width: 560px;
      max-width: 100%;
    }

    .list-item {
      width: 280px;
      padding-bottom: 15px;
      padding-top: 10px;
      padding-left: 20px;
      border-bottom: none;

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }

    .list-item__remove {
      display: flex;
      flex-direction: column;
      height: 100%;
      img {
        max-width: none !important;
        object-fit: cover;
      }
    }
  }

  &-question {
    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .list-item {
        position: relative;
        // width: 48%;
        padding-bottom: 15px;
        padding-top: 10px;
        padding-right: 5px;
        border-bottom: none;
        align-items: flex-start;

        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }

    &__answer {
      font-size: 14px;
    }

    .list-item__remove {
      display: flex;
      flex-direction: column;
      height: 100%;
      min-width: 25px;
      margin-left: 8px;
    }
  }

  &-package {
    width: 85%;

    &__form {
      padding: 0 24px 40px;
      margin-top: 24px;
      margin-bottom: 30px;
      border: none;
      border-left: 4px solid $primary;
      border-radius: 5px;
      box-shadow: 0px 10px 30px #9393931a;

      .time-picker{
        width: 100% !important;
      }
      .time-picker.error >  input.display-time {
        margin-bottom: 4px !important;
        border-color: #EF5151 !important;
      }
      .time-picker.timepicker-custom > input.display-time {
        background-image: none;
        border: none !important;
        padding: unset !important;
        width: 230px !important;
        text-align: center;
        margin-bottom: 5px;
      }
      .time-picker.timepicker-custom > input.display-time::placeholder {
        font-size: 15px;
      }
      .time-picker > input.display-time{
        height:inherit !important;
        margin-bottom: 24px;
        padding: 8px 12px!important;
        border: 1px solid #DADFEA !important;
        border-radius: 4px;
        width: 100% !important;

        font-family: "Nunito Sans" !important;
        font-weight: 600 !important;
        color: #252C3F !important;
        font-size: 16px !important;
        line-height: 24px !important;
        -webkit-transition: all 0.3s !important;
        transition: all 0.3s !important;
      }
      .time-picker >  .dropdown  {
        width: 100% !important;
        height: 10em;
        top: 55px!important;
      }
      .time-picker >  .dropdown >.select-list {
        width: 100% !important;
          height: 10em;
      }
      .time-picker >.dropdown >.select-list>ul > li.active{
        background: #FF8141
      }
      .time-picker >.dropdown >.select-list>ul > li.active:hover{
        background: #FF8141
      }
      .time-picker>.clear-btn{
            margin-top: -1.15em !important;
      }


      .time-picker > input.display-time {
        padding-left: 30px !important;
        padding-left: 30px;
        background-image: url(../images/clock-orange.svg);
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-size: 16px 16px;
        background-position: 8px 11px;

        &::placeholder {
          color: #9ba4bc;
        }

        &:focus,
        &:active {
          outline: none;
          border-color: #fe9554 !important;
        }
      }
      .time-picker > .controls {
        height: 42px;
      }
    }
  }

  &-gigs_setings {
    .case-title {
      margin-bottom: 5px;
    }

    .forms {
      margin-top: 10px;
      max-width: 395px;

      &__item {
        margin-top: 20px;
      }

      .info-message {
        margin-top: 0px;
      }

      input {
        margin-bottom: 5px;
      }
    }

    .back-next {
      margin-top: 30px;
    }
  }

  &-experience {
    &__form-title {
      width: 65%;
    }

    &__form-time {
      width: 30%;
    }

    .list-item {
      p {
        padding-left: 48px;
      }
    }
  }

  &-award {
    &__form-title {
      width: 65%;
    }

    &__form-time {
      width: 30%;
    }

    .list-item {
      p {
        padding-left: 48px;
      }
    }
  }

  &-payment {
    .forms {
      display: block;
      width: 70%;
    }
  }

  .back-next {
    width: 380px;
  }

  .save-button {
    width: 150px;
  }

  .case-title {
    margin-top: 30px;
    font-size: 20px;
    margin-bottom: 20px;
    line-height: 24px;
  }

  .upload-avatar {
    margin-left: 10px;
    vertical-align: bottom;
    margin-top: auto;
    margin-bottom: 18px;
    border-radius: 5px;
  }
  .profile_cover_image{
    display: flex;
    width: 88%;
  }
  .cover-pic {
    margin-left: 20px;
    flex-basis: 76.82%;
  }
  
  .profile-song__list {
    li {
      word-break: break-all;
    }
  }
}

.modalIframe {
  width: 100%;
  height: 450px;
  background: #000;
  &-preview {
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    &:before {
      content: "";
      width: 68px;
      height: 68px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(0%);
      background: url(../images/play.svg) 50% 50% no-repeat;
      background-size: cover;
      z-index: 1;
      cursor: pointer;
    }
  }
}

.sm-gallery {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  &-desctop & {
    &__item {
      width: 100%;
      margin-bottom: 0;
    }
    &__item-wp {
      width: 22.58%;
      flex: 0 0 22.58%;
      position: relative;
      margin-bottom: 16px;
      &:not(:nth-child(4n + 4)) {
        margin-right: 3.22%;
      }
    }
  }

  &-mobile {
    &__item {
      width: 100%;
      margin-bottom: 25px;
      position: relative;

      &-container {
        .sm-gallery__item {
          max-height: 150px;
        }
      }
    }
  }
  &__item {
    width: 100%;
    height: 116px;
    position: relative;
    margin-bottom: 16px;

    cursor: pointer;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    &-container {
      padding-top: 100%;
      position: relative;
      margin-bottom: 10px;

      .sm-gallery__item {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
      }
    }
    &-remove {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 0;
      right: 0;
      background: #fff3ec url(../images/close-orange.svg) 50% 50%
        no-repeat;
      cursor: pointer;
      z-index: 1;
      opacity: 0.75;
      transition: opacity 0.3s;
      border-radius: 2px;
      &:hover {
        opacity: 1;
      }
    }
    &-video {
      position: relative;
      &:before {
        content: "";
        width: 34px;
        height: 34px;
        position: absolute;
        top: 41px;
        left: 50%;
        transform: translateX(-50%);
        background: url(../images/play.svg) 50% 50% no-repeat;
        z-index: 1;
        cursor: pointer;
      }
    }

    &-description {
      width: 100%;
      padding: 5px 10px;
      border: 1px solid #dadfea;
      border-radius: 3px;
      height: 33px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      outline: none;

      &:focus,
      &:active {
        border-color: #ff8141;
        color: #000000;
      }
    }
    &-cover-description{
      text-align: center;
      padding: 5px 10px;
    }
  }
}

.forms-photo__description {
  position: absolute;
  left: 220px;
  top: 50%;
  transform: translateY(-50%);
}

.multiselect__select::before {
  content: url(../images/arrow-down-orange.svg);
  top: 4px;
  border: none;
}

@media screen and (max-width: 1024px) {
  .profile {
    display: block;
    margin-top: 20px;
    &-video {
      .show-pop-up-forms{ 
        .center_form_container{
          .center_form {
            width: 70%;
          }
        }
      }
    }
    &-mobile-btn {
      display: flex;
    }
    &-user {
      margin-top: 24px;
    }
    &-menu {
      width: 100%;
      height: 100%;
      padding-bottom: 138px;
      padding-left: 0;
      padding-right: 0;
      position: fixed;
      top: 138px;
      left: 0;
      overflow: hidden;
      background: #fff;
      border: none;
      z-index: 10;
      &__content {
        width: 93.54%;
        height: 100%;
        margin: 0 auto;
        padding-bottom: 16px;
        overflow: hidden;
        overflow-y: auto;
      }
    }
    &-sidebar {
      margin-bottom: 40px;
    }
    &-liked {
      &__like {
        opacity: 1;
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .profile {
    &-video {
      .show-pop-up-forms{ 
        .center_form_container{
          .center_form {
            width: 90%;
          }
        }
      }
    }
    &-artist-menu {
      display: none;
    }
  }
  .sticky-check-availability {
    display: block;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 10px 0 15px 20px;
    box-shadow: 5px 5px 20px;
    background: #fff;;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    z-index: 99;

    .artist-title {
      font-weight: bold;
      font-size: 20px;
      text-transform: capitalize;
    }

    .mobile-rating-availability {
      display: flex;
      justify-content: space-between;
      .message_icon {
        height: 60px;
        margin-top: 10px;
        padding: 0 12px;
      }
      .message-icon {
        padding: 18px 27px;
        background-size: 50px 50px;
      }
    }
    .check-availability {
      display: flex;
      width: 100%;

      .message-btn {
        padding: 8px;
        height: 40px;

        img {
          height: 30px;
          width: 30px;
          vertical-align: middle;
        }
      }
      .rating {
        margin-top: 13px;
        margin-left: 65%;
      }
    }
    .flatpickr-wrapper {
      width: 80%;
    }
  }
}

@media screen and (max-width: 640px) {
  .profile {
    margin-top: 20px;
    &-header {
      flex-direction: column;

      &__progressbar {
        width: auto;
      }

      &__dashboard-btn {
        display: none;
      }
    }
    &-sidebar {
      display: block;
    }
    &-menu {
      &__content {
        width: 84.6%;
      }
    }
    &-artist-menu {
      &-mobile {
        display: block;
        padding-top: 16px;

        .profile-artist-menu__title {
          display: none;
        }
      }
      &__dashboard-btn {
        width: 100%;
        padding: 0 15px;
        display: block;
        button {
          background-color: #fff3ec;
          color: #ff8141;
          padding: 15px 35px;
          border: none;
          border-radius: 5px;
          width: 100%;
        }
      }
    }
    &-main {
      flex: 0 0 100%;
    }
    &-liked {
      &__item {
        display: block;
        position: relative;
      }
      &__link {
        width: 100%;
        flex-wrap: wrap;
      }
      &__avatar {
        flex: 0 0 100%;
        height: auto;
        margin-right: 0;
        margin-bottom: 16px;
      }
      &__like {
        position: absolute;
        bottom: 10px;
        right: 18px;
        margin-right: 0;
      }
    }
    &-workshops {
      &__item {
        display: block;
      }
      &__img {
        width: 100%;
        height: auto;
      }
      &__info {
        margin: 16px 0 24px;
        h3 {
          margin-bottom: 24px;
        }
      }
      &__state {
        text-align: left;
      }
    }
    &-bookings {
      &__item {
        display: block;
      }
      &__img {
        width: 100%;
        height: auto;
      }
      &__info {
        margin: 16px 0 24px;
      }
      &__state {
        text-align: left;
      }
    }

    &-package {
      width: 100%;

      &__form {
        padding: 0 0 40px;
        border-left: none;
      }
    }

    .packages-price__btns {
      padding-bottom: 10px;
    }

    &-artist__form {
      width: 100%;
    }

    &-experience {
      &__form-title {
        width: 100%;
      }

      &__form-time {
        width: 100%;
      }
    }

    &-award {
      &__form-title {
        width: 100%;
      }

      &__form-time {
        width: 100%;
      }
    }

    &-member {
      &__list-member {
        display: block;
        width: 100%;
      }
    }

    &-question {
      &__list {
        display: block;
        width: 100%;

        .list-item {
          width: 100%;
        }
      }
    }

    &-gigs_setings {
      .forms {
        width: 100%;
      }
    }

    &-payment {
      .forms {
        width: 100%;
      }
    }

    &-video {
      .forms {
        width: 100%;
      }
    }

    &-audio {
      .forms {
        width: 100%;
      }

      .list-item {
        position: relative;

        .list-item__info {
          margin-right: 0;
        }
      }

      .list-item__remove {
        position: absolute;
        top: 2px;
        right: 2px;
        width: 30px !important;

        img {
          height: auto !important;
          width: 30px;

          &:first-child {
            margin-bottom: 8px !important;
          }
        }
      }
    }

    .back-next {
      width: 100%;
      button {
        min-width: 100px;
      }
    }
    
    .cover-pic {
      margin-left: 20px;
      flex-basis: 55%;
    }

    .profile-mobile-container {
      width: 100%;
    }

    .dashboard-mobile-btn {
      padding-top: 0;
      width: 100%;
    }
  }
}

@media screen and (max-width: 540px) {
  .sticky-check-availability {
    .artist-title {
      font-size: 13px;
    }
  }
}


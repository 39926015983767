.featured{
  text-align: center;
  &-logo{
    width: 80px;
    height: 80px;
    margin: 0 auto;
    border-radius: 50%;
    background: $primary url(../images/flogo.svg) 10px 21px no-repeat;
  }
  &>h3{
    margin: 32px 0 8px;
  }
  &>p{
    margin-bottom: 40px;
  }
  &-row{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    max-width: 936px;
    margin: 0 auto;
  }
  &-column{
    flex: 0 0 48.71%;
    padding: 24px;
    border: 1px solid #DADFEA;
    &>h3{
      text-align: left;
      margin-bottom: 44px;
    }
    .settings-forms__btn.primary,
    a.primary{
      width: 100%;
    }
  }
  &-item{
    margin-bottom: 24px;
    padding: 0 16px 16px;
    border-bottom: 1px solid #DADFEA;
    &.active{
      border-color: $primary;
    }
    &.active &{
      &-check{
        border-color: $primary;
        background: $primary url(../images/check-ic.svg) 50% 50% no-repeat;
      }
    }
    h3{
      flex-shrink: 0;
      margin-left: 12px;
    }
    &-check{
      flex: 0 0 24px;
      height: 24px;
      margin-right: 16px;
      border: 1px solid #DADFEA;
      border-radius: 50%;
    }
    input{
      height: 0;
      width: 0;
      overflow: hidden;
      visibility: hidden;
    }
    label{
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      p{
        flex-shrink: 0;
      }
      &> div{
        display: flex;
        align-items: center;
      }
    }
  }
  &-redirect{
    padding: 24px;
    background-color: #FFF3EC;
  }
}

@media screen and (max-width: 768px){
  .featured{
    &-row{
      display: block;
    }
    &-column{
      margin-bottom: 24px;
    }
    &-item{
      label{
        display: block;
        h3{
          text-align: right;
        }
      }
    }
  }
}

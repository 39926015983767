.swiper-container-horizontal > .swiper-pagination-bullets{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  bottom: 0;
}

.swiper-wrapper {
  display: flex;
}

.swiper-pagination-bullet{
  width: 6px;
  height: 6px;
  border-radius: 0;
  background: $light;
  opacity: 0.4;
  font-size: 0;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet{
  margin: 0 5px;
}

.swiper-slide {
  display: flex;
  flex-direction: column;
  padding: 48px;
  background: rgba(0, 0, 0, .95);
  z-index: -2;
}

.swiper-pagination-bullet-active{
  width: 10px;
  height: 10px;
  background: $primary;
  opacity: 1;
}

.swiper-button-prev{
  width: 16px !important;
  height: 28px !important;
  background: 16px 28px url(../images/slider-arrow-left.svg) no-repeat !important;
  background-position: center !important;
  margin: 0 4px !important;
  position: absolute;
  align-items: center;
  justify-content: center;
  right: auto;
  left: 0px;
  top: 35%;
  z-index: 10;
  cursor: pointer;
}

.swiper-button-next{
  width: 16px !important;
  height: 28px !important;
  background: 16px 28px url(../images/slider-arrow-right.svg) no-repeat !important;
  background-position: center !important;
  margin: 0 4px !important;
  position: absolute;
  align-items: center;
  justify-content: center;
  left: auto;
  right: 0px;
  top: 35%;
  z-index: 10;
  cursor: pointer;
}

.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled{
  opacity: 0.41;
  cursor: auto;
}

.swiper-arrows-button-prev {
  background-image: url(../images/slider-arrow-backward-short.svg);
  border-radius: 2px 0 0 2px;
  margin-right: 1px;
  width: 32px;
  height: 32px;
  border: 0;
  outline: none;
  cursor: pointer;
  background-color: #eceff5;
  background-position: 50%;
  background-repeat: no-repeat;
  -webkit-transition: all .3s;
  transition: all 0.3s;
}

.swiper-arrows-button-next {
  background: url(../images/slider-arrow-forward-short.svg);
  width: 32px;
  height: 32px;
  border: 0;
  outline: none;
  cursor: pointer;
  background-color: #eceff5;
  background-position: 50%;
  background-repeat: no-repeat;
  -webkit-transition: all .3s;
  transition: all .3s;
  border-radius: 0 2px 2px 0;
}

.swiper-arrows-button-next:hover, .swiper-arrows-button-prev:hover {
  background-color: #e2e7f0;
}

.swiper-group-button {
  align-items: center;
  justify-content: center;
  display: flex;
}

.slide-user-wp{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 24px 0 -16px 0;
  img{
    width: 44px;
    height: 44px;
    margin-right: 16px;
  }
  .slider-name {
    margin: 0;
    color: rgba(255, 255, 255, 1);
  }
  .slider-descr {
    color:rgba(255, 255, 255, 0.7);
  }
}
.swiper_arrows_button {
  width: 17px !important;
  height: 18px !important;
  background: 16px 28px url(../images/slider-arrow-right.svg) no-repeat !important;
  background-position: center !important;
  margin: 3px 4px;
  position: absolute;
  opacity: 0.7;
  z-index: 9;
  transition: transform 0.5s;
  transform: rotate(90deg);
  cursor: pointer;
}
.review-arrows-button {
  background: url(../images/slider-arrow-forward-short.svg);
  width: 32px;
  height: 32px;
  border: 0;
  outline: none;
  cursor: pointer;
  background-position: 50%;
  background-repeat: no-repeat;
  -webkit-transition: all .3s;
  transition: all .3s;
}
.transform {
  transition: transform 0.5s;
  transform:rotate(270deg);
  -ms-transform:rotate(270deg);
  -webkit-transform:rotate(270deg);
  
}

@media screen and (max-width:660px) {
  .swiper-slide, .swiper-container.swiper-container-horizontal{
    background: none;
  }
}

@media screen and (max-width: 390px) {
  .review-arrows-button {
    width: 10px;
    height: 10px;
  }
}



.categories-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  .category-container {
    flex: 1 1 0;
    display: flex;
    align-items: end;
    height: 100px;
    min-width: 370px;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    -webkit-box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

    .category-icon {
      width: 30px;
      height: 30px;
    }

    .category-name {
      color: #FFFFFF;
      font-weight: 600;
    }
  }
}

@media screen and (max-width: 600px) {
  .categories-container {
    .category-container {
      min-width: 125px;
    }
  }
}
.upcoming{
  max-width: 744px;
  margin: 0 auto;
  position: relative;
  &-header{
    position: relative;
  }
  &-links{
    position: absolute;
    top: 0;
    right: 0;
    &__link.secondary,
    &__link.primary{
      width: 104px;
      height: 32px;
      font-size: 12px;
      line-height: 16px;
    }
  }
}
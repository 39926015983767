<script>
import Divider from '../sign_up/Divider.vue'
import whiteLogo from "../../images/logoAllWhtie.png";
import bookingImage from "../../images/bookingImage.jpg";
import arrowRight from "../../images/slider-arrow-right.svg";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name:'registration',
  components:{
    Divider,
    Loading
  },
  props: {
    kind: String,
    email: String,
		full_name: String,
		phone: String,
  },
  data(){
    return {
      whiteLogo: whiteLogo,
			bookingImage: bookingImage,
			arrowRight: arrowRight,
      isLoading: false,
			password: '',
      error: '',
      isValid: false,
			showPassword: false,
      domain: window.location.origin
    }
  },
  created() {
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    if(params.get("jwt")) {
      localStorage.setItem('token', params.get("jwt"))
      this.$axios.defaults.headers.common['Authorization'] = params.get("jwt")
      this.$emit('updateStep', 'complete_information')
    } else {
      this.$axios.post('/users/tokens/guest').then(res => {
        localStorage.setItem('token', res.data.jwt)
        this.$axios.defaults.headers.common['Authorization'] = res.data.jwt
      })
    }
  },
  methods: {
    otherInputs(){
			let isArtist = true 
			if (this.kind === 'artist') {
				isArtist = true
			} else {
				isArtist = false
			}
      let profileParams = {
        user: {
          full_name: this.full_name,
          phone: this.phone,
					artist: isArtist,
					registration_completion: true,
					role: 'regular'
        }
      }
      this.$axios.put('/users/profile', profileParams)
      .then(response => {
				this.sign_in()
      })
      .catch(error => {
        console.log(error, "profile api error")
      })
      if (this.full_name === '') {
        this.error = "can't be blank"
      }
    },
		sign_in() {
			this.$axios({url: '/users/sign_in.json', baseURL: '', method: 'post', data: {
				user: { email: this.email, password: this.password }
			}})
			.then(response => {
				localStorage.setItem('token', response.data.jwt)
				this.$axios.defaults.headers.common['Authorization'] = response.data.jwt
				this.isLoading = false
				window.location.href = '/'
			})
			.catch(error => {
				this.isLoading = false
				console.log(error, "pwd error")
			})
		},
    nextStep() {
      let params = {
        sign_up: {
          email: this.email,
          password: this.password
        }
      }
      this.isLoading = true
      this.$axios.post('/users/registrations.json', params)
        .then(response => {
          localStorage.setItem('token', response.data.jwt)
          this.$axios.defaults.headers.common['Authorization'] = response.data.jwt
          this.otherInputs()
        })
        .catch(error => {
          console.log(error, "registrations error")
        })
    },
    disableNext() {
      let password = !this.password || this.password === ''
      if(password) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
<template>
  <section>
    <div class="vld-parent">
      <loading :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        color="#FF8141"
      ></loading>
    </div>
    <div class="soulartists">
      <div>
        <img :src="whiteLogo" class="white-log" />
      </div>
			<h3 v-if="kind=='artist'">Follow your passion, unleash your talent</h3>
			<h3 v-else>Sign up for unlimited usage, it's free!</h3>
    </div>
    <div class="forms inputs">
      <div class="forms__item forms__item-100">
				<div v-if="kind=='artist'">
					<div>
						<img :src="bookingImage" class="white-log" />
					</div>
					<p class="d-flex align-items-center mb-2" style="color: black;">
						<img :src="arrowRight" class="arrow-right me-1" /> Get gigs from clients around the world
					</p>
					<p class="d-flex align-items-center mb-2" style="color: black;">
						<img :src="arrowRight" class="arrow-right me-1" /> Apply to inquires with just a tap
					</p>
					<p class="d-flex align-items-center mb-2" style="color: black;">
						<img :src="arrowRight" class="arrow-right me-1" /> Customize your profile and manage your bookings
					</p>
					<p class="d-flex align-items-center mb-2" style="color: black;">
						<img :src="arrowRight" class="arrow-right me-1" /> Negotiate with clients, find the perfect deal
					</p>
					<p class="d-flex align-items-center mb-2" style="color: black;">
						<img :src="arrowRight" class="arrow-right me-1" /> Experience a safe and transparent marketplace
					</p>
				</div>
				<div v-else>
					<div>
						<img :src="bookingImage" class="white-log" />
					</div>
					<p class="d-flex align-items-center mb-2" style="color: black;">
						<img :src="arrowRight" class="arrow-right me-1" /> Unlimited access to top verified entertainers worldwide
					</p>
					<p class="d-flex align-items-center mb-2" style="color: black;">
						<img :src="arrowRight" class="arrow-right me-1" /> Find the perfect entertainer at light speed
					</p>
					<p class="d-flex align-items-center mb-2" style="color: black;">
						<img :src="arrowRight" class="arrow-right me-1" /> Add your event details and watch artists apply
					</p>
					<p class="d-flex align-items-center mb-2" style="color: black;">
						<img :src="arrowRight" class="arrow-right me-1" /> Negotiate with artists, find the perfect deal
					</p>
					<p class="d-flex align-items-center mb-2" style="color: black;">
						<img :src="arrowRight" class="arrow-right me-1" /> Experience a safe and transparent marketplace
					</p>
				</div>
        <div class="forms__item-wp" style="position:relative">
          <label class="label" >Password</label>
          <input
            v-model="password"
            :type="showPassword ? 'text' : 'password'"
            :required="true"
            class="input"
            v-bind:class="{ 'error': error && error.password }"
            placeholder="*******"
          >
					<svg
					class="password-watch-icon"
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					fill="#DADFEA"
					viewBox="0 0 24 24"
					v-on:click="()=>{showPassword = !showPassword}"
						>
					<g  fill-rule="evenodd">
						<path  d="M16.6902054,7.25771868 C18.6867614,8.09104409 21.1233596,9.3384712 24,11 L24,13 C20,17 16,19 12,19 C8,19 4,17 8.8817842e-16,13 L8.8817842e-16,11 C2.87615842,9.34200151 5.31134218,8.09634687 7.30555128,7.26303607 C6.48838509,8.2882196 6,9.58707557 6,11 C6,14.3137085 8.6862915,17 12,17 C15.3137085,17 18,14.3137085 18,11 C18,9.58463161 17.5099239,8.28372662 16.6902054,7.25771868 Z"/>
						<path  d="M13.1778781,7.1762437 C12.4833529,7.48981554 12,8.18846121 12,9 C12,10.1045695 12.8954305,11 14,11 C14.8115388,11 15.5101845,10.5166471 15.8237563,9.8221219 C15.9383283,10.194496 16,10.5900459 16,11 C16,13.209139 14.209139,15 12,15 C9.790861,15 8,13.209139 8,11 C8,8.790861 9.790861,7 12,7 C12.4099541,7 12.805504,7.06167174 13.1778781,7.1762437 Z"/>
					</g>
				</svg>
        <span v-if="error" class="error-message">{{ error && error.password? error.password.toString() : null }}</span>
        </div>
      </div>
      <button
        class="settings-forms__btn btn primary fluid"
        :disabled="disableNext()"
        @click="nextStep()">
        Next
      </button>
    </div>

    <divider/>

    <div class="auth-form-content">
      Already member? <a href="users/sign_in">Log in</a>
    </div>
  </section>
</template>
<style scoped>
  .password-watch-icon{
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 38px;
  }
</style>

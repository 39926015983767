footer.footer-container {
  background: #f6f7f8;
  padding-top: 26px;
  margin-top: 40px;

  .footer-content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    .chevron-up {
      transition: all ease 0.5s;
    }

    .chevron-down {
      -webkit-transform: rotate(180deg);     /* Chrome and other webkit browsers */
      -moz-transform: rotate(180deg);        /* FF */
      -o-transform: rotate(180deg);          /* Opera */
      -ms-transform: rotate(180deg);         /* IE9 */
      transform: rotate(180deg);             /* W3C compliant browsers */
      transition: all ease 0.5s;
    }

    &-upper {
      padding-bottom: 30px;
      border-bottom: 1px solid #9ba4bc;

      &-column,
      &-mobile {
        margin-top: 30px;

        h3 {
          font-family: "Nunito Sans", sans-serif;
          font-weight: 700;
          color: #9ba4bc;
          font-size: 18px;
          list-style: 1.5;
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            margin-top: 20px;
            a {
              color: #9ba4bc;
              font-size: 16px;
              transition: all ease 0.3s;

              &:hover {
                color: #FF8141;
              }
            }
          }
        }
      }

      &-mobile {
        margin-top: 30px;
        padding-left: 20px;
        padding-right: 20px;

        h3 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
           -khtml-user-select: none; /* Konqueror HTML */
             -moz-user-select: none; /* Old versions of Firefox */
              -ms-user-select: none; /* Internet Explorer/Edge */
                  user-select: none; /* Non-prefixed version, currently
                                        supported by Chrome, Edge, Opera and Firefox */
        }
      }
    }

    &-lower {
      margin-top: 30px;
      margin-bottom: 50px;

      @include media-breakpoint-down(md) {
        text-align: center;
      }

      &_logo {
      }

      &_copyright {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #9ba4bc;

        @include media-breakpoint-down(md) {
          display: block;
          text-align: center;
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }

      &_social-links {
        display: flex;
        align-items: center;

        @include media-breakpoint-down(md) {
          display: block;
          text-align: center;
        }
        a {
          margin-right: 10px;
        }
      }
    }
  }
}

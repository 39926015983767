.services-page {
	text-align: center;

	&-image {
		width: 100%;
		height: 350px;
		border-radius: 15px;
		box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.15);
		margin-bottom: 10px;
	}

	&-section {
		position: relative;
    text-align: end;
		margin-top: 50px;
    margin-bottom: 10px;
		img {
			width: 867px;
    	height: 430px;
			background: white;
			border-radius: 20px;
			box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.15);
		}
	}

	.services-card {
    position: absolute;
    width: 390px;
		background: white;
    border-radius: 20px;
    margin-top: 40px;
		padding: 30px 25px;
		box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.15);
	
		h1 {
			font-size: 35px;
			color: #333;
			line-height: 40px;
			text-align: left;
			line-height: 40px;
			span {
				color: #ff7a33;
			}
		}
	
		p {
			margin: 10px 0;
			color: #666;
			font-size: 14px;
			line-height: 1.4;
			text-align: left;
		}
	
		.services-icons {
			display: flex;
			justify-content: space-between;
			margin-bottom: 20px;
	
			.service-item {
				display: flex;
				align-items: center;
				font-size: 14px;
				color: #333;
				text-align: left;
				.icon {
					font-size: 1.2rem;
					margin-right: 8px;
          height: 15px;
          width: 15px;
          border-radius: unset;
          box-shadow: unset;
          background: unset;
				}
			}
		}
	
		.get-started {
			background-color: #ff7a33;
			color: #fff;
			border: none;
			padding: 10px 20px;
			font-size: 20px;
			border-radius: 5px;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: bold;
			width: 90%;
			margin-left: auto;
			margin-right: auto;
			.icon {
				width: 15px;
        box-shadow: unset;
        background: unset;
        height: unset;
        margin-right: 10px;
			}
			.get-started-link {
				color: #fff;
				text-decoration: none;
				font-size: 1rem;
			}
			&:hover {
				background-color: #ff9457;
			}
		}
	}
	
	.image-section { 
		img {
			width: 717px;
			height: 100%;
			object-fit: cover;
		}
	}
}

@media screen and (max-width: 1000px) {
	.services-page {
		&-section {
			margin-top: 150px;
		}
		.services-card {
			width: 315px;
			margin-top: -100px;
			h1 {
				font-size: 30px;
				line-height: 35px;
				span {
					color: #ff7a33;
				}
			}
      .get-started {
        .icon {
          width: 13px;
        }
      }
		
			p {
				font-size: 11px;
				line-height: 1.3;
			}
			.services-icons {
				.service-item {
					font-size: 11px;
          .icon {
            height: 13px;
            width: 13px;
          }
				}
			}
		}
	}
}

@media screen and (max-width: 580px) { 
	.services-page {
		&-image {
			object-fit: fill;
		}
	}

}

@media screen and (max-width: 678px) {
	.services-page {
		&-section {
			margin-top: 150px;
		}
		.services-card {
			width: 261px;
			padding: 15px 10px;
			h1 {
				font-size: 25px;
        line-height: 30px;
				span {
					color: #ff7a33;
				}
			}
		
			p {
				font-size: 10px;
				line-height: 1.2;
			}
			.get-started {
				padding: 5px 7px;
				font-size: 15px;
			}
			.services-icons {
				.service-item {
					font-size: 10px;
				}
			}
		}
	}
}

@media screen and (max-width: 380px) {
	.services-page {
		.services-card {
			width: 237px;
			padding: 15px 10px;
			h1 {
				font-size: 22px;
        line-height: 30px;
			}
		
			p {
				font-size: 9px;
				line-height: 1.2;
			}
			.get-started {
				padding: 7px 7px;
				font-size: 13px;
        .icon {
          width: 11px;
        }
			}
			.services-icons {
				.service-item {
					font-size: 8px;
          .icon {
            height: 11px;
            width: 11px;
          }
				}
			}
		}
	}
}

.contact-us{
  &__sent{
    display: flex;
    align-items: center;
    &-icon{
      flex: 0 0 45px;
      height: 45px;
      margin-right: 16px;
      border-radius: 50%;
      background: $primary url(../images/request-icon.svg) 50% 50% no-repeat;
    }
  }
}

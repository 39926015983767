<script>
import Divider from '../sign_up/Divider.vue'
import whiteLogo from "../../images/logoAllWhtie.png";
import bookTalentImage from '../../images/book-talent.svg'
import artistImage from '../../images/artist.svg'

import Loading from 'vue-loading-overlay';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-loading-overlay/dist/vue-loading.css';
import Password from './Password.vue';

export default {
  name:'registration',
  components:{
    VuePhoneNumberInput,
    Password,
    Divider,
    Loading
  },
  data(){
    return {
      whiteLogo: whiteLogo,
      bookTalentImage: bookTalentImage,
      artistImage: artistImage,
      isLoading: false,
      step: 'email',
      email: '',
      full_name: '',
      phone: '',
      kind: 'artist',
      formattedNumber: '',
      countryCode: '',
      phoneError: '',
      emailError: '',
      error: '',
      isValid: false,
      domain: window.location.origin
    }
  },
  created() {
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    if(params.get("jwt")) {
      localStorage.setItem('token', params.get("jwt"))
      this.$axios.defaults.headers.common['Authorization'] = params.get("jwt")
      this.$emit('updateStep', 'complete_information')
    } else {
      this.$axios.post('/users/tokens/guest').then(res => {
        localStorage.setItem('token', res.data.jwt)
        this.$axios.defaults.headers.common['Authorization'] = res.data.jwt
      })
    }
  },
  mounted() {
  },
  methods: {
    nextStep() {
      this.isLoading = true
      this.$axios.get(`/users/registrations/check_email`, {
        params: { email: this.email }
      })
      .then(response => {
        this.isLoading = false
        this.step = 'password'
      })
      .catch(error => {
        this.isLoading = false
        this.emailError = error.response.data.errors
      })
    },
    validatePhone(data) {
      this.isValid = data.isValid
      if (!this.isValid) {
        this.phoneError = "Phone number is not valid.";
      } else {
        this.formattedNumber = data.formattedNumber
        this.phoneError = null;
      }
    },
    disableNext() {
      let fullName = !this.full_name || this.full_name === ''
      let phone = !this.phone || !this.isValid
      let email = !this.email || this.email === ''
      if(fullName || email || phone) {
        return true
      } else {
        return false
      }
    },
    signInUrl(){
      return `${window.location.origin}/users/sign_in`
    },
  }
}
</script>
<template>
  <div>
    <section v-if="step=='email'">
      <div class="vld-parent">
        <loading :active.sync="isLoading"
          :can-cancel="false"
          :is-full-page="true"
          color="#FF8141"
        ></loading>
      </div>
      <div class="soulartists pt-3">
        <div>
          <img :src="whiteLogo" class="white-log" />
        </div>
        <h3>Sign up for unlimited usage, it's free!</h3>
      </div>
      <div class="forms inputs">
        <div class="forms__item forms__item-100">
          <label class="label">Enter your name</label>
          <div class="forms__item-100">
            <input
              v-model="full_name"
              type="text"
              :required="true"
              class="input"
              v-bind:class="{ 'error': error}"
              placeholder="John Smith"
              ref="name">
            <span v-if="error" class="error-message">{{ error? error.toString() : null }}</span>
          </div>
          <div class="forms__item-wp">
            <label class="label">Email</label>
            <div>
              <input
                v-model="email"
                type="email"
                :required="true"
                class="input"
                v-bind:class="{ 'error': emailError}"
                placeholder="example@email.com"
              >
              <span v-if="emailError" class="error-message">{{ emailError? emailError.toString() : null }}</span>
            </div>
          </div>
          <div class="forms__item forms__item-100">
            <label class="label">Phone number</label>
            <div class="forms__prefix">
              <VuePhoneNumberInput
                v-model="phone"
                :required="true"
                class="forms__item-100"
                v-bind:class="{ 'error': phoneError}"
                default-country-code="AE"
                color="#FE9554"
                :no-validator-state="true"
                error-color="#EF5151"
                @update="validatePhone"
              />
              <span v-if="phoneError" class="error-message">{{ phoneError? phoneError.toString() : null }}</span>
            </div>
          </div>
        </div>
        <div class="user-kind">
          <section class="user-kind-option" :class="{'active': kind === 'user'}"
            @click="kind = 'user'"
          >
            <slot name='header'>
              <div class="user-kind-option-header">
                <img class="user-kind-option-header__logo" :src="bookTalentImage" />
                <div class="user-kind-option-header__title">
                  I want to book talent
                </div>
                <div class="user-kind-option-header__description">
                  Select this option if you are looking to hire artists and performers.
                </div>
              </div>
            </slot>
          </section>
      
          <section class="user-kind-option" :class="{'active': kind === 'artist'}"
            @click="kind = 'artist'"
          >
            <slot name='header'>
              <div class="user-kind-option-header">
                <img class="user-kind-option-header__logo" :src="artistImage" />
                <div class="user-kind-option-header__title">
                  I'm an artist
                </div>
                <div class="user-kind-option-header__description">
                  Select this option if you are an artist looking to get gigs and perform at events.
                </div>
              </div>
            </slot>
          </section>
        </div>

        <button
          class="settings-forms__btn btn primary fluid mt-3"
          :disabled="disableNext()"
          @click="nextStep()">
          Next
        </button>
      </div>

      <divider/>

      <div class="auth-form-content">
        Already member? <a :href="signInUrl()">Log in</a>
      </div>
    </section>
    <password
      v-if="step=='password'"
      :kind="kind"
      :email="email"
      :full_name="full_name"
      :phone="formattedNumber"
    />
  </div>
</template>
<style scoped>
  .password-watch-icon{
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 38px;
  }
</style>

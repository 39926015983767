.back-next{
  display: flex;

  button:first-child {
    margin-right: 1.5em;
  }

  .settings-forms {
    &__btn {
      box-shadow: 1px 5px 12px 3px rgba(0, 0, 0, 0.15) !important;
    }
  }
}

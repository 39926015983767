.container .ainfo {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  .vue-affix {width: 300px;}
  &-header {
    margin-bottom: 20px;
    position: relative;

    &__input-date {
      margin: 0;
      display: block;
      border: 1px solid #dadfea;
      width: 100%;
      padding: 8px 10px 8px 35px;
      font-size: 16px;
      line-height: 24px;
      outline: none;
      background: url('../images/calendar-orange.svg') 8px 50% no-repeat;
      background-size: auto 15px;
    }

    #book_artist {
      position: absolute;
      right: 0;
      top: 28px;
      height: 42px;
      border: 1px solid $primary;
      border-radius: 2px;

      &:disabled {
        border: 1px solid #ECEFF5;
      }
    }

    &__calendar {
      visibility: hidden;
      position: absolute;
      top: 72px;
      max-width: 350px;
      z-index: 11;
      background-color: white;
      padding: 5px;
      box-shadow: 0px 10px 30px #9393931A;


      .fc {
        color: black;
        border: none;

        .fc {
          &-toolbar-chunk {
            &:first-child {
              width: 150px;
            }

            .fc-toolbar-title {
              font-size: 18px;
            }
          }

          &-toolbar-title {
            color: $primary;
          }

          &-scrollgrid {
            border: none;

            td {
              border: none;
            }
          }

          &-button-primary {
            background-color: $primary;
            border-color: $primary;

            &:disabled {
              background-color: $weak;
              border-color: $weak;
              color: $primary;
            }
            &:hover {
              background-color: $dark;
              border-color: $dark;
              color: white;
            }
          }

          &-day-future {
            cursor: pointer;
          }

          &-daygrid-day-events {
            min-height: 0 !important;
            height: 0;
          }

          &-day {
            border: none;
            a {
              color: #04031c;
            }
          }

          &-highlight {
            background-color: $weak;
          }

          &-day-other {
            .fc-daygrid-day-top {
              opacity: 1 !important;
              .fc-daygrid-day-number {
                opacity: 0.3;
              }
            }
          }
        }
      }
    }
  }
  &-main {
    margin-left: auto;
    margin-right: auto;
  }

  &-sidebar-wrap {
    width: 23.4%;
    position: fixed;
  }
  &-sidebar-sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 3.7em;
    background: white;
  }
  &-sidebar {
    flex: 0 0 23.4%;
    h3 {
      display: inline-block;
      margin-bottom: 8px;
    }
    &__avatar-container {
      width: 155px;
      height: 155px;
      box-shadow: 0 4px 16px 0 rgba(2, 0, 12, 0.16);
      margin-left: 50px; // edit
      position: relative;
      top: -85px;

      &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
      }
    }
    &__avatar {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 4px;
      box-shadow: 0 3px 3px hsl(0deg 0% 58% / 10%);
      webkit-box-shadow: 0 3px 3px hsl(0deg 0% 58% / 10%);
    }
    &__actions {
      margin-bottom: 24px;
      a.secondary {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-top: 0;
        margin-bottom: 16px;
        img {
          margin-right: 16px;
        }
      }
    }
    &__info {
      margin-bottom: 32px;
      &-item {
        display: flex;
        align-items: flex-start;
        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }
      &-name {
        display: flex;
        font-weight: bold;
        flex: 0 0 95px;
        margin-right: 16px;
        color: black;
      }
      &-value {
        flex-grow: 1;
        ul {
          display: flex;
          flex-wrap: wrap;
          > li {
            padding-right: 4px;
          }
          > li::after {
            content: ",";
          }
          :last-child::after {
            content: "";
          }
        }
        span {
          position: relative;
          &:not(:last-child) {
            margin-right: 8px;
            &:after {
              content: ",";
              position: absolute;
              bottom: 0;
              right: -3px;
              color: inherit;
              font-size: inherit;
            }
          }
        }
      }
    }
    &__share {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 24px;
      & > * {
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
    &__book.primary {
      width: 100%;
      box-shadow: 0px 3px 6px #0000001a;
    }
  }

  &-anchors {
    display: flex;
    margin-bottom: 30px;

    &__content {
      margin-left: auto;
      margin-right: auto;
    }

    &__item {
      color: #252c3f;
      padding-bottom: 12px;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 20px;
      }

      &.active {
        color: #ff8141;
        border-bottom: 1px solid #ff8141;
      }
    }
    &__fluid {
      justify-content: space-between;
    }

    &__content::before {
      display: block;
      content: " ";
      margin-top: -100px;
      height: 100px;
      visibility: hidden;
      pointer-events: none;
    }
  }

  .VueCarousel-navigation-button {
    top: 63px !important;

    &:focus {
      outline: none !important;
    }
  }

  .VueCarousel-slide {
    img {
      max-width: 87%;
    }
  }

  .sm-gallery-desctop {
    .small-carousel {
      width: 100%;

      .VueCarousel {
        width: 100%;
      }

      .VueCarousel-inner {
        flex-basis: auto !important;
        .VueCarousel-slide {
          width: 25%;
          min-width: 116px;

          .sm-gallery__item-video {
            &::before {
              left: 44%;
            }
          }
        }
      }
    }
  }

  .profile-song__list {
    li {
      word-break: break-all;
    }
  }
}

@media screen and (min-width: 768px) {
  .ainfo-anchors__item {
    &:not(:last-child) {
      margin-right: auto !important;
    }
  }

  .container .ainfo {
    &-anchors {
      &__content {
        scroll-margin-top: 120px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .ainfo {
    display: block;
    &-sidebar {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      position: relative;
      margin-bottom: 64px;
      & > p {
        flex: 0 0 100%;
      }
      & > p {
        margin-bottom: 24px;
      }
      &__avatar {
        width: 100%;
        margin-top: 0;
      }
      &__user,
      &__data {
        flex: 0 0 48.27%;
      }
      &__actions {
        margin-bottom: 24px;
      }
      &__share {
        width: 48.27%;
        position: absolute;
        bottom: 71px;
        right: 0;
      }
    }
    &-main {
      &-featured {
        &__item {
          img {
            height: 138px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 820px) {
  .container .ainfo {
    &-sidebar {
      &__avatar-container {
        width: 100px;
        height: 85px;
        margin-left: 0;
        top: -50px;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .ainfo {
    &-sidebar {
      &__user,
      &__data {
        flex: 0 0 100%;
      }
      &__book.primary {
        width: 100%;
        position: static;
      }
      &__share {
        width: 100%;
        position: static;
      }
    }
    &-sidebar-sticky {
      position: static !important;
    }
    &-main {
      &__requirements {
        &-item {
          flex: 0 0 100%;
          &:nth-child(odd) {
            margin-right: 0;
          }
        }
      }
    }
    &-anchors {
      display: none;
    }
  }
  .packages {
    display: block;
    padding-bottom: 0;
    border-top: 4px solid $primary;
    border-left: 1px solid $blight;
    border-bottom: none;
    &-info {
      &__list {
        display: block;
      }
    }
    &-price {
      margin-top: 16px;
      padding-top: 16px;
      padding-bottom: 0;
      text-align: center;
      border-top: 1px solid $blight;
      &__book.primary {
        width: calc(100% + 50px);
        margin-left: -25px;
      }
    }
  }
  .VueCarousel-navigation-button {
    top: 58px !important;
  }
}

.ainfo-main {
  .tabs {
    &-header {
      display: flex;
      height: 55px;
      border-bottom: 1px solid #dadfea;
      &__item {
        cursor: pointer;
        margin-right: 48px;
        color: #2d3549;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        transition: 0.4s;
      }
      &__item.active {
        color: #ff8141;
      }
    }
    &-main {
      &__item {
        display: none;
        opacity: 0;
        transform: translateX(200px);
      }
      &__item.active {
        display: block;
        opacity: 1;
        transform: translateX(0px);
      }
    }
  }
}

.bookins {
  &__item {
    display: flex;
    width: 100%;
    align-items: center;
    &-content {
      padding-left: 24px;
    }
    &-poster {
      height: 168px;
      width: 20%;
    }
    &-content {
      width: 80%;
    }
    &-header {
      display: flex;
      justify-content: space-between;
      &__title {
        color: #252c3e;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
      }
      &__actions {
        color: #949eb7;
        font-size: 12px;
        line-height: 16px;
      }
    }
    &-body {
      display: flex;
      width: 70%;
      flex-wrap: wrap;
      &__option {
        width: 50%;
        &-name {
          color: #9ba4bc;
          font-size: 12px;
          line-height: 16px;
        }
        &-value {
          color: #252c3e;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) and (min-width: 640px) {
  .ainfo-sidebar__user, .ainfo-sidebar__data {
    flex: 0 0 85%;
  }
  .container .ainfo-sidebar__share {
    position: unset;
    margin-top: 5px;
  }
}
@media only screen and (max-width: 640px) {
  .container .ainfo-sidebar__share {
    margin-top: 5px;
  }
  .container .ainfo-header {
    margin-bottom: 0;
  }
  .container .ainfo-anchors__fluid {
    padding: 0 12px;
  }
}

.artist-profile__item-video {
  cursor: pointer;
  position: relative;

  .play-button-icon {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background: url(../images/play.svg) 50% 50% no-repeat;
    z-index: 1;
  }
}
@media only screen and (max-width: 390px) {
  .container .ainfo {
    &-sidebar {
      &__avatar-container {
        width: 100px;
        height: 80px;
        margin-left: 0;
      }
    }
  }  
}

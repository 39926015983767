.order-body {
  .requests {
    &-info {
      border-top: 1px solid $blight;
    }
  }
}
.requests {
  &-main {
    width: 90%;
    max-width: 744px;
    margin-left: auto;
    margin-right: auto;
    .case {
      margin-bottom: 20px;
    }
  }
  &-progress {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #eef0f4;
    span {
      padding: 7px 0;
      color: $gray;
    }
    &.paid {
      background: $success;
      span {
        color: $white;
      }
    }
    &.cancel {
      background: $error;
      span {
        color: $white;
      }
    }
    &.paid &,
    &.cancel & {
      &__item {
        flex: 0 0 100%;
      }
    }
    &__item {
      flex: 0 0 33.333%;
      position: relative;
      text-align: center;
      &.active {
        background: $primary;
        span {
          color: $white;
        }
        &:after {
          content: '';
          width: 22px;
          height: 22px;
          position: absolute;
          top: 4px;
          right: -11px;
          background: $primary;
          transform: rotate(45deg);
        }
      }
    }
  }
  &-notification {
    width: 100%;
    display: flex;
    align-items: stretch;
    position: relative;
    background: rgba(0, 183, 200, 0.2);
    &__icon {
      flex: 0 0 38px;
      background: #00b7c8 url(../images/Information.svg) 50% 50% no-repeat;
    }
    &__text {
      flex-grow: 1;
      padding: 16px;
      p {
        text-align: center;
      }
    }
  }
  &-info {
    padding: 24px 16px;
    border: 1px solid $blight;
    border-top: none;
    &__border-top {
      border-top: 1px solid $blight;
      & + .forms {
        width: 100%;
        max-width: none !important;
        padding: 24px 16px;
        border: 1px solid $blight;
        border-top: none;
        .settings-forms__btn.primary {
          width: 100%;
        }
      }
      h3.mt0 {
        margin-top: 0;
      }
    }
    h3 {
      margin-top: 32px;
      margin-bottom: 16px;
    }
    &__list + h3 {
      margin-top: 8px;
    }
    &__list {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      &-item {
        flex: 0 0 35%;
        display: flex;
        align-items: center;
        margin-right: 16px;
        margin-bottom: 24px;
        p.cr-silver {
          margin-right: 16px;
        }
      }
    }
    &__location {
      padding: 8px 16px 8px 16px;
      background: #eef0f4 url(../images/arrow-right.svg) right 16px center
        no-repeat;
      border-radius: 1px;
      a {
        display: inline;
        width: 77%;
        padding-left: 22px;
        background: url(../images/location-pin.svg) 0 50% no-repeat;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: $gray;
        font-size: 16px;
        line-height: 24px;
      }
    }
    &__warning-wrapper {
      padding: 24px 16px 0 16px;
      border-right: 1px solid $blight;
      border-left: 1px solid $blight;
    }
    &__links {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px 16px;
      border: 1px solid $blight;
      border-top: none;
      &-50 {
        flex: 0 0 49%;
      }
      &-100 {
        flex: 0 0 100%;
      }
    }
    &__user {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      img {
        width: 72px;
        flex: 0 0 72px;
        height: 72px;
        margin-right: 16px;
      }
    }
  }
}

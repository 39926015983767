.rating, .artist-rating {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .artist-rating {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (max-width: 390px) {
  .artist-rating {
    margin-top: 10px;
    .mobile-counting {
      font-size: 10px;
    }
    .rating {
      img {
        width: 10px;
      }
    }
  }
}
.settings{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  &-menu{
    flex: 0 0 23.4%;
    margin-right: 10.63%;
    padding: 16px;
    border: 1px solid $blight;
    &__link{
      width: 100%;
      padding: 10px 16px;
      text-align: left;
      color: $gray;
      font-weight: normal;
      &:hover{
        color: $primary;
      }
      &.active{
        background: $weak;
        color: $primary;
        font-weight: 600;
      }
    }
    &__link{
      &:not(:last-child){
        margin-bottom: 8px;
      }
    }
  }
  &-main{
    flex: 0 0 48.93%;
  }
  &-top{
    display: flex;
    align-items: flex-start;
    margin-top: 24px;
    margin-bottom: 40px;
    &__avatar{
      width: 168px;
      flex: 0 0 168px;
      height: 168px;
      margin-right: 24px;
      box-shadow: 0 4px 16px 0 rgba(2,0,12,0.16);
    }
    &__upd.secondary{
      width: 100%;
      margin-top: 24px;
    }
    & > div{
      flex-grow: 1;
    }
  }
}

@media screen and (max-width: 1024px){
  .settings{
    display: block;
    &-menu{
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      max-width: 264px;
      margin-right: 0;
      margin-bottom: 40px;
      &__link{
        width: auto;
        flex: 0 0 auto;
        margin-right: 16px;
      }
    }
    &-top{
      & > div{
        max-width: 380px;
      }
    }
  }  
}

@media screen and (max-width: 640px){
  .settings{
    display: block;
    &-top{
      display: block;
      &__avatar{
        margin-right: 0;
        margin-bottom: 24px;
      }
      & > div{
        max-width: none;
      }
    }
  }  
}
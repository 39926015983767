button.btn,
a.btn{
  // width: 112px;
  height: 40px;
  border: none;
  padding: 0 16px;
  margin: 0;
  background: none;
  font-family: "Nunito Sans";
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  text-align: center;
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 5px;
  &.fluid{
    width:100% !important;
  }
  &.no-fixed{
    width: auto !important;
    padding-right: 12px;
    padding-left: 12px;
  }
  &.primary{
    color: $white;
    background: $primary;
    &:hover,
    &.hover{
      background: $light;
      box-shadow: 0 4px 10px 0 rgba(254,149,84,0.5);
    }
    &:active,
    &.active{
      background: $dark;
      box-shadow: 0 4px 10px 0 rgba(254,149,84,0.5);
    }
    &:disabled,
    &.disabled{
      color: $silver;
      background: $lighter;
      cursor: default;
      box-shadow: none;
    }
    &:focus,
    &.focus{
      outline: none;
      border-color: $light;
    }
  }
  &.secondary{
    color: $dark;
    background: $weak;
    &:hover,
    &.hover{
      background: rgba(254, 149, 84, 0.3);
    }
    &:active,
    &.active{
      background: rgba(254, 149, 84, 0.5);
    }
    &:disabled,
    &.disabled{
      color: $silver;
      background: $lighter;
      cursor: default;
      box-shadow: none;
    }
    &:focus,
    &.focus{
      outline: none;
      border-color: $light;
    }
  }
  &.delete{
    width: 40px;
    color: $dark;
    background: #FFDFDF;
    &:hover,
    &.hover{
      background: rgba(239, 81, 81, 0.3);
    }
    &:active,
    &.active{
      background: rgba(239, 81, 81, 0.5);
    }
    &:disabled,
    &.disabled{
      color: $silver;
      background: $lighter;
      cursor: default;
      box-shadow: none;
    }
    &:focus,
    &.focus{
      outline: none;
      border-color: $light;
    }
  }
  &.remove{
    width: 40px;
    color: $white;
    background: #EF5151;
    &:hover,
    &.hover{
      background: #F15C5C;
      box-shadow: 0 4px 10px 0 rgba(239, 81, 81, 0.5);
    }
    &:active,
    &.active{
      background: #E64444;
    }
    &:disabled,
    &.disabled{
      color: $silver;
      background: $lighter;
      cursor: default;
      box-shadow: none;
    }
    &:focus,
    &.focus{
      outline: none;
      border-color: $light;
    }
  }

  &.light {
    color: $primary;
    background: #fff0e9;
    &:hover,
    &.hover{
      background: darken($color: #fff0e9, $amount: 2%);
      box-shadow: 0 4px 10px 0 rgba(254,149,84,0.5);
    }
    &:active,
    &.active{
      background: darken($color: #fff0e9, $amount: 2%);
    }
    &:disabled,
    &.disabled{
      color: $silver;
      background: $lighter;
      cursor: default;
      box-shadow: none;
    }
    &:focus,
    &.focus{
      outline: none;
      border-color: $light;
    }
  }
  &.share {
    display: flex;
    justify-content: center;

    img {
      padding-bottom: 14px;
      width: 30px;
    }
  }
}

a.btn{
  padding-top: 8px;
}

.pagination{
  display: flex;
  margin: 16px 0;
  &-item{
    &__counter{
      display: none;
    }
    &:not(:last-child){
      margin-right: 8px;
    }
  }
  &-link{
    &.secondary{
      width: 40px;
    }
    &__prev.secondary,
    &__next.secondary{
      width: 72px;
    }
  }
}

@media screen and (max-width: 640px){
  .pagination{
    align-items: center;
    justify-content: space-between;
    &-item{
      &__counter{
        flex-grow: 1;
        display: block;
        text-align: center;
      }
      &:not(:last-child){
        margin-right: 0;
      }
    }
    &-link{
      &.secondary{
        width: 0;
      }
      display: none;
      &__prev.secondary,
      &__next.secondary{
        display: block;
        width: 90px;
      }
    }
  }
}

.auth-form {
  width: 570px;
  margin: auto;
  padding: 56px 73px;
  box-shadow: 0 3px 10px 0 rgba(147, 147, 147, 0.2);
  border-radius: 5px;
  &__sign-in {
    .forgot-password-link {
      margin-bottom: 26px;
    }
  }
  &-content {
    text-align: center;
    color: $silver;
    margin-top: 11px;
  }
  &-header {
    margin-bottom: 52px;
    text-align: center;

    &__logo {
      height: 70px;
      width: 108px;
      margin: auto;
      margin-bottom: 32px;
    }
    &__title {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      color: #04031c;
      margin-bottom: 8px;
    }
    &__description {
      width: 313px;
      margin: auto;
      color: #9ba4bc;
      font-size: 16px;
      line-height: 24px;
    }
  }
  &-button {
    margin-bottom: 17px !important;
    width: 100% !important;
    border: none;
    height: auto !important;
    padding: 12px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    font-family: inherit;
    outline: none;
    transition: 0.3s;
  }
  &-actions {
    display: flex;
    justify-content: space-between;
    .auth-form-button {
      margin-right: 25px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
}

.user-kind-option,
.all-signed-up-option {
  cursor: pointer;
  text-align: center;
  border: solid 2px transparent;
  position: relative;

  &:focus,
  &:hover,
  &.active {
    border: solid 2px #ff8141;
  }

  &:first-child {
    margin-right: 35px;
  }

  width: 320px;
  padding: 25px 25px;
  box-shadow: 0 3px 10px 0 rgba(147, 147, 147, 0.2);
  border-radius: 5px;
  &__sign-in {
    .forgot-password-link {
      margin-bottom: 26px;
    }
  }
  &-content {
    text-align: center;
    color: $silver;
    margin-top: 11px;
  }
  &-header {
    margin-bottom: 35px;
    text-align: center;

    &__logo {
      height: 36px;
      margin: auto;
      margin-bottom: 15px;
    }
    &__title {
      font-size: 20px;
      font-weight: 700;
      line-height: 32px;
      color: #04031c;
      margin-bottom: 15px;
    }
    &__description {
      margin: auto;
      color: #9ba4bc;
      font-size: 14px;
      line-height: 24px;
    }
  }
  &-button {
    margin-bottom: 17px !important;
    width: 100% !important;
    border: none;
    height: auto !important;
    padding: 12px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    font-family: inherit;
    outline: none;
    transition: 0.3s;
  }
  &-actions {
    display: flex;
    justify-content: space-between;
    .auth-form-button {
      margin-right: 25px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }

  &-recommended {
    position: absolute;
    top: -1px;
    left: -1px;
    background-color: #ff8141;
    color: white;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 5px;
    font-size: 12px;
    padding: 2px 8px;
  }

  button {
    border-radius: 5px;
  }
}

.complete-signup {
  width: 570px;
  margin: auto;
  padding: 56px 73px;
  &.wide {
    width: initial;
  }
  &__sign-in {
    .forgot-password-link {
      margin-bottom: 26px;
    }
  }
  &-content {
    text-align: center;
    color: $silver;
    margin-top: 11px;
  }
  &-header {
    margin-bottom: 52px;
    text-align: center;

    &__logo {
      height: 70px;
      width: 108px;
      margin: auto;
      margin-bottom: 32px;
    }
    &__title {
      p {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        color: #04031c;
        margin-bottom: 0;
      }

      font-size: 20px;
      font-weight: 700;
      line-height: 25px;
      color: #04031c;
      margin-bottom: 8px;
    }
    &__primary_title {
      color: #ff8141;
    }
    &__description {
      width: 313px;
      margin: auto;
      color: #9ba4bc;
      font-size: 16px;
      line-height: 24px;
    }
  }
  &-button {
    margin-bottom: 17px !important;
    width: 100% !important;
    border: none;
    height: auto !important;
    padding: 12px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    font-family: inherit;
    outline: none;
    transition: 0.3s;
  }
  &-actions {
    display: flex;
    justify-content: space-between;
    .auth-form-button {
      margin-right: 25px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
}

.terms-conditions {
  width: auto;
  max-width: 60%;
  & p {
    margin: 0 0 16px 0;
  }
  & h1,
  h2,
  h3 {
    margin: 0 0 16px 0;
  }
}
.socials {
  display: flex;
  a:first-child {
    margin-right: 1.5em;
  }

  &-button {
    cursor: pointer;
    margin-bottom: 17px;
    width: 100%;
    height: 40px;
    border-radius: 7px;
    margin-top: -4px;
    border: none;
    padding: 12px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    font-family: inherit;
    outline: none;
    text-align: center;
    transition: 0.3s;
    box-shadow: 1px 1px 8px 3px rgba(32, 60, 207, 0.15);

    &.google {
      background-color: #ea4335;
      &:hover {
        opacity: 0.9;
      }
    }
    &.facebook {
      background-color: #3b5998;
      &:hover {
        opacity: 0.9;
      }
    }
    &__label {
      margin-top: -4px;
      color: white;
    }
  }
}

.divider-duable {
  display: flex;
  justify-content: center;
  align-items: center;
  //flex-basis:auto;
  &-label {
    color: $silver;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media screen and (max-width: 640px) {
  .terms-conditions {
    max-width: 100%;
    width: 100%;
  }

  .auth-form,
  .complete-signup {
    max-width: 100%;
    padding: 50px 20px;

    &-header {
      &__description {
        width: auto;
      }
    }
  }
}

.profile-tabs-stretch {
  width: 100vw;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}
.ainfo_anchors {
  border-top: 1px solid #ebf2f7;
  border-bottom: 1px solid #ebf2f7;
  overflow-x: auto;
  z-index: 10;
  position: -webkit-sticky;
  box-shadow: 0 3px 3px rgb(148 148 148 / 10%);
  position: sticky;
  background: $white;
  top: 175px;

  .ainfo-anchors {
    margin-bottom: 0 !important;
    margin-top: 10px;
  }
}

.top-background {
  background-size: cover;
  height: 385px;
  margin-bottom: 80px;
  position: relative;
  width: 100vw;
  margin-left: -50vw;
  left: 50%;

  .top-info {
    bottom: -120px;
    position: absolute;
    justify-content: space-between;
    width: 100%;
    height: 115px;
    display: flex;
    background: rgb(252 252 252);
    padding-bottom: 40px;

    .rating_vacc_avail {
      margin-left: 10px;

      .pro-member, .vaccinated-tag {
        width: 110px;
      }
      .pro-vaccinated-tag {
        display: none;
      }
    }

    .artist-info {
      display: flex;
      margin-left: 20px;
      margin-top: 85px;
      align-self: end;
      .art_type {
        font-size: 25px;
        font-weight: 700;
        color: #222222;
        text-transform: capitalize;
      }
      .mobile-view-artist-country {
        display: none;
      }
      .mobile-pro-vaccinated-tag {
        display: flex;

        .review-link {
          display: none;
        }
      }
      .pro-member, .vaccinated-tag {
        width: 100px;
      }
    }

    .artist-availability {
      display: flex;
      .message-availability {
        display: flex;
        width: 340px;
        align-self: end;
        margin-left: auto;
        .flatpickr-calendar {
          right: 0;
        }
      }
      .next-btn{
        display: none;
      }
      .next_btn {
        display: block;
        position: absolute;
        bottom: -338px;
        right: 5px;
        padding: 0px 137px;
      }
    }
  }
  .sticky-top-info {
    display: flex;
    justify-content: space-between;
    position: fixed;
    padding-bottom: 1rem;
    right: 0;
    left: 0;
    top: 59px;
    z-index: 15;
    background: rgb(252 252 252);
    .rating_vacc_avail {
      margin-left: 10px;
      .pro-member, .vaccinated-tag{
        width: 100px;
      }

      .pro-vaccinated-tag{
        display: flex;
      }
    }
    .ainfo-sidebar__avatar-container{
      width: 90px;
      height: 90px;
      margin-top: 10px;
      margin-left: auto;
      position: unset;
    }
    .artist-info {
      display: flex;
      align-items: center;
      margin-left: 25px;
      margin-top: 10px;

      .art_type{
        font-size: 20px;
        font-weight: 700;
        color: #222222;
        text-transform: capitalize;
      }

      .mobile-view-artist-country {
        display: none;
      }

      .mobile-pro-vaccinated-tag {
        display: none;
      }
    }

    .message-availability{
      margin-top: 30px;
      .flatpickr-calendar {
        right: 0;
      }
    }

    .next-btn{
      display: none;
    }

    .next_btn {
      display: block;
      position: absolute;
      bottom: -338px;
      right: -1px;
      padding: 0px 137px;
    }

    .pro_member_vaccinated{
      display: none;
    }
  }
}


.video-sorting {
  display: flex;
  justify-content: center;
  .preview {
    border-radius: 10px;
    height: 180px;
    width: 100%;
  }
  p {
    font-size: 20px;
    color: white;
    position: absolute;
    bottom: 0px;
    text-align: center;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1));
    height: 30px;
    border-radius: 0 0 10px 10px;
    padding-top: 7px;
    backdrop-filter: blur(2px);
    width: 100%;
  }
  .artist-profile__item-video {
    display: flex;
    justify-content: center;
  }
}



.image-container {
  position: relative;
  box-sizing: border-box;
}
.photo_sorting {
  .item1 {
    flex: 0 0 50%; /* Takes 50% of the width */
    height: fit-content;
  }
  .padding1 {
    padding-right: 3px;
    flex: 0 0 50%;
  }
  .padding2 {
    padding-left: 3px;
    flex: 0 0 50%;
  }
  .item2,
  .item3 {
    flex: 0 0 50%; /* Each takes 25% of the width */
  }
  
  .item6 {
    top: -309px;
    left: 50%;
    width: 50%;
  }
  
  .item5,
  .item4 {
    flex: 0 0 50%; /* Each takes 50% of the width */
    height: fit-content;
  }

  .photo_grid_wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 6px;
    img{
      border-radius: 10px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
      -webkit-box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
      width: 100%;
    }
  }
   .photo_grid_wrapper div:nth-child(1) {
    grid-row: 1/4;
    grid-column: 1/3;
    img{
      height: 400px;
    }
  }
  .photo_grid_wrapper div:nth-child(2) {
    img {
      height: 200px;
    }
   }
  .photo_grid_wrapper div:nth-child(3) {
    img {
      height: 200px;
    }
   }
  .photo_grid_wrapper div:nth-child(4) {
    grid-column: 3/5;
    grid-row: 2/3;
    img {
      height: 195px;
      width: 453px;
    }
  }
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}





.sticky-check-availability {
  display: none;
}

.case {
  &:not(:last-child) {
    margin-bottom: 40px;
  }
  &-title {
    margin-bottom: 15px;
    &__back {
      justify-content: flex-start;
    }
    .text_transform {
      text-transform: uppercase;
    }
  }
  &-top-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    letter-spacing: .5px;
    color: #9ba4bc;
  }
  &-back {
    width: 19px;
    height: 16px;
    margin-right: 16px;
    flex-shrink: 0;
    cursor: pointer;
  }
  &-top-more {
    margin-left: auto;
    background-color: #fff3ec;
    padding: 4px 8px;
    margin-right: 8px;
    line-height: 1.5;
    border-radius: 2px;
    -webkit-transition: all .3s;
    transition: all .3s;
  }
  &-top-more:hover {
    background-color: #ffe9dd;
  }
  &-item {
    &__top-name {
      margin: 0 0 8px 0;
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      color: inherit;
    }
    &__top-work {
      color: #9ba4bc;
      font-size: 12px;
      line-height: 16px;
    }
    &__biography{
      margin-top: 0.5rem;
      .biography_collapsible {
        line-height: 1.5; 
        overflow: hidden; 
        white-space: pre-line;
      }
      .show_more_button {
        display: inline-block;
        color: #FF8141;
        cursor: pointer; 
      }
      .show_less_button {
        display: none;
      }
      .toggle_show_more {
        display: none;
      }
      .toggle_show_less {
        display: inline-block;
        color: #FF8141;
        cursor: pointer; 
      }
      p {
        &:not(:last-child) {
          margin-bottom: 32px;
        }
      }
    }
    &__video{
      .grid_wrapper {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 10px;
        img{
          border-radius: 10px;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
          -webkit-box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
          width: 100%;
        }
      }
       .grid_wrapper div:nth-child(1) {
        grid-row: 1/4;
        grid-column: 1/3;
        img{
          height: 400px;
        }
      }
      .grid_wrapper div:nth-child(2) {
        img {
          height: 200px;
        }
       }
      .grid_wrapper div:nth-child(3) {
        img {
          height: 200px;
        }
       }
      .grid_wrapper div:nth-child(4) {
        grid-column: 3/5;
        grid-row: 2/3;
        img {
          height: 185px;
          width: 453px;
        }
      }
    }
    &__audio {
      iframe:not(:last-child) {
        margin-bottom: 24px;
      }
    }
    &__talents{
      padding: 10px 24px 10px;
      border: none;
      border-left: 4px solid $primary;
      border-radius: 5px;
      box-shadow: 0px 10px 30px #9393931a;
      .talent-item {
        display: flex;
        padding: 10px;
        &:not(:last-child) {
          box-shadow: 0px 1.8px 0px #f4a1a11a;;
        }
        .talent_content {
          overflow:hidden;
          transition: max-height 0.5s ease;
          width: 80%;
          height: auto;
          max-height: 24px;
        }
        .to_hide_arrow {
          overflow: auto; 
          max-height: 100px;
        }
        .active{
          overflow: auto;
          transition: max-height 0.8s ease;
          height: auto;
          max-height: 350px;
        }
        .collapsible {
          cursor: pointer;
          border: none;
        }
      }
    }
    &__awards {
      &-item {
        position: relative;
        padding-left: 30px;

        &-star {
          position: relative;
          padding-left: 30px;
          margin-bottom: 10px;

          &:before {
            content: "";
            width: 16px;
            height: 16px;
            position: absolute;
            top: 3px;
            left: 8px;
            background: url(../images/award-star.svg) 50% 50% no-repeat;
          }
        }
        &:nth-child(odd) {
          margin-right: 24px;
        }
        &:before {
          content: "";
          width: 6px;
          height: 6px;
          position: absolute;
          top: 8px;
          left: 8px;
          border-radius: 50%;
          background: $primary;
        }
      }
    }
    &__featureds {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      &-item {
        flex: 0 0 22.58%;
        img {
          width: 100%;
          margin-bottom: 16px;
          & + p {
            margin-bottom: 8px;
          }
        }
      }
    }
    &__map {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      & > * {
        flex: 0 0 100%;
      }
      &-img {
        width: 100%;
        height: 280px;
      }
      &-address {
        padding: 16px;
        border: 1px solid $lighter;
        border-top: none;
      }
    }
    &__members {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      &-item {
        flex: 0 0 25%;
        position: relative;
        padding-left: 30px;

        &-star {
          flex: 0 0 25%;
          position: relative;
          padding-left: 30px;
          margin-bottom: 10px;
          &:before {
            content: "";
            width: 17px;
            height: 17px;
            position: absolute;
            top: 3px;
            left: 5px;
            background: url(../images/profile-orange.svg);
            background-size: 17px;
          }
        }
        &:nth-child(odd) {
          margin-right: 24px;
        }
        &:before {
          content: "";
          width: 6px;
          height: 6px;
          position: absolute;
          top: 8px;
          left: 8px;
          border-radius: 50%;
          background: $primary;
        }
      }
    }

    &__requirements {
      box-shadow: 0px 10px 30px #9393931a;
      border-radius: 5px;
      max-height: 350px;
      overflow-y: auto;

      &-item {
        border-bottom: 1px solid #fff3ec;
        padding: 10px 15px;
      }
    }

    &__experiences {
      &-item-book {
        padding-left: 30px;
        position: relative;
        &:before {
          content: "";
          width: 17px;
          height: 17px;
          position: absolute;
          top: 3px;
          left: 5px;
          background: url(../images/book.svg);
          background-size: 17px;
        }
      }
    }

    &__songs {
      box-shadow: 0px 10px 30px #9393931a;
      padding: 10px 15px;

      &-list {
        width: 100%;
        max-height: 330px;
        overflow-y: auto;
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px #fff0e9;
          background-color: #fff0e9;
        }

        &::-webkit-scrollbar {
          width: 5px;
          background-color: #fff0e9;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #ff8141;
        }
      }

      input {
        float: right;
        width: 240px;
        border-radius: 5px;
        margin-right: 10px;
        font-size: 14px;
        padding: 5px 10px 5px 35px;
        margin-bottom: 5px;
      }

      li {
        padding: 8px;
        border-bottom: 1px solid #ebf2f7;

        &.title {
          font-weight: 700;
        }
      }
    }
  }
  .more-media-icon {
      height: 16px;
  }
}

.previews {
  display: flex;
  flex-wrap: wrap;
  &-item {
    flex: 0 0 24.19%;
    margin-bottom: 16px;
    &:not(:nth-child(4n + 4)) {
      margin-right: 1.08%;
    }
    &__video {
      position: relative;
      &:before {
        content: "";
        width: 34px;
        height: 34px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        z-index: 1;
        background: url(../images/play.svg) 50% 50% no-repeat;
        cursor: pointer;
      }
    }
  }
  &-image {
    width: 100%;
    cursor: pointer;
    height: 116px;
  }
}

.packages {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 24px 24px 16px;
  border: none;
  border-left: 4px solid $primary;
  border-radius: 5px;
  box-shadow: 0px 10px 30px #9393931a;

  & + .packages {
    margin-top: 24px;
  }
  &-info {
    flex: 0 0 74.19%;
    h3 {
      margin-bottom: 24px;
    }
    &__list {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    &__item {
      flex: 0 0 49%;
      margin-bottom: 8px;
      word-break: break-word;
      p {
        font-size: 14px;
      }
      &-value {
        flex-basis: 2;
        max-width: 250px;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
      }
    }
  }
  &-price {
    flex: 0 0 24.58%;
    align-self: flex-end;
    text-align: right;
    padding-bottom: 8px;
    h3 {
      margin-bottom: 16px;

      span {
        font-size: 14px;
      }
    }
    h4 {
      margin-bottom: 16px;

      span {
        font-size: 14px;
      }
    }
    &__edit.secondary {
      width: 72px;
      margin-left: 8px;
    }
    &__btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &__edit,
    &__edit img {
      width: 40px;
      height: 40px;
    }

    &__delete {
      outline: none;
      border: none;
      padding: 0;
      margin-left: 5px;
      width: 40px;
      height: 40px;
      background-color: #fff;

      img {
        width: 40px;
        height: 40px;
      }
    }
  }
}

.reviews {
  &-item {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
    &__header {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
    }
    &__avatar {
      flex: 0 0 48px;
      width: 48px;
      height: 48px;
      margin-right: 16px;
    }
    &__info {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
    }
  }
}

.list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 25px;
  border-bottom: 1px solid $blight;
  p.strong {
    margin-bottom: 8px;
  }
  &:not(:last-child) {
    margin-bottom: 24px;
  }
  &__remove {
    height: 24px;
    cursor: pointer;
  }
  &__dot {
    padding-left: 48px;
    position: relative;
    &:before {
      content: "";
      width: 6px;
      height: 6px;
      position: absolute;
      top: 8px;
      left: 16px;
      border-radius: 50%;
      background: $primary;
    }
  }
  &__star {
    padding-left: 48px;
    position: relative;
    &:before {
      content: "";
      width: 16px;
      height: 16px;
      position: absolute;
      top: 3px;
      left: 16px;
      background: url(../images/award-star.svg) 50% 50% no-repeat;
    }
  }

  &__book {
    padding-left: 48px;
    position: relative;
    &:before {
      content: "";
      width: 17px;
      height: 17px;
      position: absolute;
      top: 3px;
      left: 16px;
      background: url(../images/book.svg);
      background-size: 17px;
    }
  }

  &__user {
    padding-left: 48px;
    position: relative;
    &:before {
      content: "";
      width: 25px;
      height: 100%;
      position: absolute;
      left: 10px;
      background: url(../images/profile-orange.svg) 50% 50% no-repeat;
      background-size: 25px 25px;
    }
  }

  &__question {
    position: relative;

    &:before {
      content: "";
      width: 16px;
      height: 16px;
      position: absolute;
      top: 5px;
      left: 3px;
      background: url(../images/question-mark-orange.svg) 50% 50% no-repeat;
      background-size: 16px 16px;
    }
    p {
      &:first-child {
        padding-left: 25px;
      }

      &:last-child {
        width: 100%;
        padding: 10px 10px 10px 25px;
        border-radius: 5px;
        box-shadow: 0 10px 30px 0 rgba(147, 147, 147, 0.1);
      }
    }
  }
}

.forms-social {
  h3 {
    margin-bottom: 24px;
  }
  .social-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:not(:last-child) {
      margin-bottom: 32px;
    }
    & > div {
      display: flex;
      align-items: center;
      img {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        margin-right: 16px;
      }
    }
  }
}

.tooltip {
  position: relative;
  margin-top: -8px;
  margin-bottom: 24px;
  padding: 16px;
  border-radius: 1px;
  background-color: #FFF3EC;
  text-align: center;

  &:before {
    content: "";
    z-index: -1;
    width: 25px;
    height: 25px;
    background-color: #FFF3EC;
    position: absolute;
    top: -5px;
    left: 5%;
    transform: translateX(-50%) rotate(45deg);
  }
  p {
    color: #000000;
    display: inline-block;
    text-align: center;
    &:last-child {
      margin-top: 8px;
    }

    b {
      color: #000000;
    }
  }
  &-event-price {
    text-align: left;
    margin-top: inherit;

    &__icon {
      vertical-align: middle;
    }

    &__fee-title {
      padding-left: 10px;
      vertical-align: middle;
      font-weight: 900;
    }

    &__fee-detail {
      padding-left: 30px;
    }
    &__fee-total {
      padding-left: 30px;
      font-weight: 900;
      margin-top: 10px;
    }
    &__vat-detail {
      padding-left: 30px;
      font-size: 14px;
      color: #252c3f !important;
      margin-top: 0px !important;
    }
  }
}

@media screen and (max-width: 992px) {
  .top-background{
    .sticky-top-info {
      top: 60px;
      .message-availability{
        margin-left: 0;
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .ainfo_anchors {
    top: 55px;
  }
  .classes { 
    &-info {
      p {
        font-size: 14px;
      }
    }
  }
  .profile-tabs-stretch {
    margin-top: -35px !important;
  }
  
  .top-background {
    height: 270px;
    margin-bottom: 25px;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);

    .top-info {
      bottom: -35px;
      height: 80px;

      .rating_vacc_avail {

        .desktop-rating-div {
          display: none;
        }
        .pro-member, .vaccinated-tag {
          width: 90px;
        }
        .pro-vaccinated-tag {
          display: none;
        }
      }

      .artist-info {
        justify-content: space-between;
        width: 100%;
        margin-top: -50px;
        margin-left: 10px;
        align-self: auto;
        display: flex;
        align-items: start;

        .mobile-rating-div {
          display: block;
        }
  
        .art_type {
          color: $white;
        }
        .mobile-view-artist-country {
          display: block;
          color: $white;
          font-size: 0.8rem;
          font-weight: 500;
        }
        
        .mobile-pro-vaccinated-tag {
          display: block;
          margin-top: -2.0rem;
          margin-left: auto;

          .review-link {
            display: flex;
            justify-content: end;
            align-items: center;
            color: #9BA4BC;
          }
        }
        
        .pro-member, .vaccinated-tag {
          width: 65px;
        }

        .name-rating {
          width: 100%;
        }
      }
      .top-info-sub{
        width: 100%;
      }

      .artist-availability {
        display: none;
      }
    }
  }
  .case{
    &-item{
      &__video{
        .grid_wrapper {
          grid-template-columns: repeat(3, 1fr);
        }
         .grid_wrapper div:nth-child(1) {
          grid-row: 2/2;
          grid-column: 1/3;
          img{
            height: 150px;
            width: 100%
          }
        }
        .grid_wrapper div:nth-child(2) {
          img {
            height: 150px;
            width: 100%
          }
         }
        .grid_wrapper div:nth-child(3) {
          img {
            height: 150px;
            width: 100%
          }
         }
        .grid_wrapper div:nth-child(4) {
          grid-row: 3/1;
          img {
            height: 315px;
            width: 100%;
          }
        }
      }
    }
  }
}
  
@media screen and (max-width: 820px) {
  .case {
    &-item {
      &__talents{
        .item1 {
          grid-area: myArea1;
        }
        .item2 {
          grid-area: myArea2;
        }
        .item3 {
          grid-area: myArea3;
        }        
        .talent-item {
          display: grid;
          grid-template-areas: "myArea1 . myArea3"
                                "myArea2 . .";
        }
        .collapsible {
          position: absolute;
          margin-left: 80%;
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .photo_sorting {
     .photo_grid_wrapper div:nth-child(1) {
      img{
        height: 300px;
      }
    }
    .photo_grid_wrapper div:nth-child(2) {
      img {
        height: 145px;
      }
     }
    .photo_grid_wrapper div:nth-child(3) {
      img {
        height: 145px;
      }
     }
    .photo_grid_wrapper div:nth-child(4) {
      img {
        height: 150px;
      }
    }
  }
  .case {
    &-item {
      &__requirements {
        &-item {
          flex: 0 0 100%;
          &:nth-child(odd) {
            margin-right: 0;
          }
        }
      }

      &__awards {
        &-item-star {
          flex: 0 0 100%;

          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }

      &__members {
        &-item-star {
          flex: 0 0 50%;
        }
      }
    }

    &-more {
      width: 75px;
    }
  }
  .packages {
    display: block;
    padding-bottom: 0;
    border-top: 4px solid $primary;
    border-left: 1px solid $blight;
    border-bottom: none;
    &-info {
      &__list {
        display: block;
      }
    }
    &-price {
      margin-top: 16px;
      padding-top: 16px;
      padding-bottom: 0;
      text-align: center;
      border-top: 1px solid $blight;
      &__book.primary {
        width: calc(100% + 50px);
        margin-left: -25px;
      }
    }
  }
  .tooltip {
    &-event-price {
      &__fee-detail {
        font-size: 13px;
      }
      &__fee-total {
        font-size:13px;
      }
      &__vat-detail {
        font-size:12px;
        text-align: left !important;
      }
    }
  }
}

.ainfo_anchors {
  overflow-x: auto;
  z-index: 10;
  position: -webkit-sticky;
  position: sticky;
  background: $white;
  left: 0;
  margin-top: 95px;
  .ainfo-anchors {
    margin-bottom: 0 !important;
    margin-top: 10px;
  }
}

.text-clip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.top-link-item {
  color: inherit;
}

@media screen and (max-width: 540px) {
  .photo_sorting {
    .photo_grid_wrapper div:nth-child(1) {
     img{
       height: 280px;
     }
   }
   .photo_grid_wrapper div:nth-child(2) {
     img {
       height: 135px;
     }
    }
   .photo_grid_wrapper div:nth-child(3) {
     img {
       height: 135px;
     }
    }
   .photo_grid_wrapper div:nth-child(4) {
     img {
       height: 140px;
     }
   }
 }
  .top-background {
    .top-info {
      .artist-info {
        justify-content: unset;
        .art_type {
          font-size: 18px;
        }
        .pro-member, .vaccinated-tag {
          width: 55px;
        }
      } 
    }
  }
}

@media screen and (max-width: 280px) {
  .top-background {
    .top-info {
      .artist-info {
        .art_type {
          font-size: 0.7rem;
        }
        .mobile-view-artist-country {
          font-size: 0.6rem;
        }
        .pro-member, .vaccinated-tag {
          width: 45px;
        }
      } 
    }
  }
}

@media screen and (max-width: 550px) {
  .case {
    &-item {
      &__talents{
        .collapsible {
          margin-left: 74%;
        }
      }
    }
  }
}

@media screen and (max-width: 390px) {
  .photo_sorting {
    .photo_grid_wrapper div:nth-child(1) {
     img{
       height: 230px;
     }
   }
   .photo_grid_wrapper div:nth-child(2) {
     img {
       height: 105px;
     }
    }
   .photo_grid_wrapper div:nth-child(3) {
     img {
       height: 105px;
     }
    }
   .photo_grid_wrapper div:nth-child(4) {
     img {
       height: 110px;
     }
   }
 }
  .case {
    &-item {
      &__talents{
        .collapsible {
          margin-left: 65%;
        }
      }
    }
  }

  .mobile-pro-vaccinated-tag {
    .review-link {
      font-size: 10px;
    }
  }  
}
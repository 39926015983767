.logos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin: 80px 0;
  .icon-link {
    height: 45px;
    transition: all 0.3s;
    position: relative;
    &:before,
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';
      display: block;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;
      transition: opacity 1s;
    }
  }
  img {
    margin: 16px;
  }
}

@media screen and (max-width: 1024px) {
  .logos {
    justify-content: space-around;
  }
}


.menu{
  ul{
    margin:0px;
    padding: 0px 12px 0px 12px;
  }
  li{
    list-style: none;
    border-bottom: 1px solid rgba(255, 129, 65, 0.1);
    &:last-child{
      border-bottom: 0px;
    }
    a{
      padding: 16px 12px 16px 12px;
      display: block;
      color: $black;
    	font-size: 14px;
    	font-weight: 600;
    	line-height: 16px;

    }
  }
}
